<template>
    <form class="information-form" @submit="sendFormData" :key="key">
        <z-caption tag="h2" size="m" class="information-form__title u-bottom-margin--l-important"
            >Жалоба на качество</z-caption
        >

        <z-input
            class="u-bottom-margin--s-important"
            @input="onChange"
            maxlength="1500"
            name="description"
            type="text"
            placeholder="Описание"
            required
        ></z-input>

        <z-select
            class="u-bottom-margin--s-important"
            @change="selectProductType"
            :data="productOptions"
            name="product_type"
            placeholder="Выберите тип продукта"
            required
        ></z-select>

        <z-select
            v-if="brandOptions.length > 0"
            class="u-bottom-margin--s-important"
            @change="selectBrand"
            :data="brandOptions"
            name="brand"
            placeholder="Выберите бренд"
            required
        ></z-select>

        <z-select
            v-if="tasteOptions.length"
            class="u-bottom-margin--s-important"
            @change="onChangeSelect"
            :data="tasteOptions"
            name="taste"
            placeholder="Выберите вкус"
            required
        ></z-select>

        <z-select
            v-if="liquidOptions.length"
            class="u-bottom-margin--s-important"
            @change="onChangeSelect"
            :data="liquidOptions"
            name="liquid"
            placeholder="Выберите литраж"
            required
        ></z-select>

        <z-select
            v-if="manufacturerOptions.length"
            class="u-bottom-margin--s-important"
            @change="onChangeSelect"
            :data="manufacturerOptions"
            name="manufacturer_code"
            placeholder="Выберите букву с кодом изготовителя"
            required
        ></z-select>

        <z-date-picker
            class="u-bottom-margin--m-important information-form__date-picker"
            name="date_of_bottling"
            :lang="lang"
            v-model="formData.date_of_bottling"
            :value-type="formatDate"
            placeholder="Выберите дату розлива"
            required
        ></z-date-picker>

        <z-input-file
            class="u-bottom-margin--m-important"
            @change="addFile"
            name="file"
            :multiple="true"
            :accept="acceptTypes"
        ></z-input-file>

        <z-input
            class="u-bottom-margin--m-important"
            @input="onChange"
            name="fullName"
            type="text"
            placeholder="ФИО"
            required
        ></z-input>

        <z-input
            class="u-bottom-margin--m-important"
            @input="onChange"
            name="email"
            type="email"
            placeholder="Email"
            required
        ></z-input>

        <z-input
            class="u-bottom-margin--m-important"
            @input="onChange"
            name="phone"
            type="text"
            placeholder="Телефон"
            required
        ></z-input>

        <z-checkbox
            class="u-bottom-margin--m-important"
            @change="isChecked = !isChecked"
            size="s"
            name="checkbox"
            required
        >
            <p class="information-form__checkbox-caption">
                Я согласен с&nbsp;обработкой моих персональных данных в&nbsp;соответствии с&nbsp;<z-link :href="policyLink" external>Политикой конфиденциальности персональных данных</z-link>
            </p>
        </z-checkbox>

        <z-button
            class="information-form__button"
            :class="{ 'information-form__button--disabled': !isValid }"
            type="primary"
            >Отправить</z-button
        >

        <z-modal id="slotModal" @closeSuccessModal="closeModal">
            <z-caption class="information-form__modal-title" tag="h3"
                >Сообщение получено!</z-caption
            >
            <z-caption class="information-form__modal-text" tag="p">
                После его регистрации на&nbsp;ваш e-mail будет отправлено уведомление с&nbsp;номером обращения.
            </z-caption>
        </z-modal>
    </form>
</template>

<script>
import { saveQualityComplaint, getQualityValues } from '../../api/quality-complaint-form'
import { showNotyfications } from '@/utils/notifications'

export default {
    name: 'quality-complaint-form',
    data () {
        return {
            key: 1,
            lang: this.$root.lang,
            fileList: [],
            isChecked: false,
            isLoading: false,
            formatDate: 'DD.MM.YYYY',
            formData: {
                title: 'Жалоба на качество',
                description: '',
                fullName: '',
                phone: '',
                email: '',
                date_of_bottling: '',
                product_type: '',
                brand: '',
                taste: '',
                liquid: '',
                manufacturer_code: ''
            },
            productOptions: [],
            brandOptions: [],
            tasteOptions: [],
            liquidOptions: [],
            manufacturerOptions: [],
            acceptTypes:
                'image/png, image/jpg, image/jpeg, image/gif, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .txt'
        }
    },

    computed: {
        isValid () {
            return (
                this.formData.fullName &&
                this.formData.description &&
                this.formData.email &&
                this.isChecked &&
                this.formData.phone &&
                this.formData.product_type &&
                this.formData.brand &&
                this.formData.liquid &&
                this.formData.manufacturer_code &&
                this.formData.date_of_bottling
            )
        },

        policyLink () {
            return this.$root.lang === 'ru'
                ? '/investors/cookie-policy/'
                : '/en/investors/cookie-policy/'
        }
    },

    async mounted () {
        this.$root.$on('close-modal', () => this.closeModal())
        this.productOptions = this.$root.app.components['quality-complaint-form'].items.map(
            (x) => ({
                id: x,
                selected: false,
                disabled: false,
                text: x
            })
        )

        this.liquidOptions = await this.getProductInfo('displacement', 'displacement', '')
        this.manufacturerOptions = await this.getProductInfo('code', 'code', '')
    },

    beforeDestroy () {
        this.$root.$off('close-modal', () => this.closeModal())
    },

    methods: {
        async getProductInfo (needleKey, key, value) {
            const params = {
                'needle-key': needleKey,
                key,
                value
            }

            const response = await getQualityValues(params)

            return response.items.map((x) => ({
                id: x,
                selected: false,
                disabled: false,
                text: x
            }))
        },

        onChange (data) {
            this.formData[data.name] = data.value
        },

        onChangeSelect (obj) {
            this.formData[obj.name] = obj.value
        },

        async selectProductType (obj) {
            this.formData.product_type = obj.value

            this.brandOptions = []
            this.tasteOptions = []

            this.brandOptions = await this.getProductInfo(
                'brand',
                'default',
                this.formData.product_type
            )
        },

        async selectBrand (obj) {
            this.formData.brand = obj.value

            this.tasteOptions = []
            this.tasteOptions = await this.getProductInfo('test', 'brand', this.formData.brand)
        },

        addFile (event) {
            if (!event.value[0]) return
            const acceptTypesArray = this.acceptTypes.split(',').map((t) => t.trim())
            const splittedName = event.value[0].name.split('.')
            const type = event.value[0].type || splittedName[splittedName.length - 1]

            if (acceptTypesArray.includes(type)) {
                this.fileList = event.value
            }
        },

        closeModal () {
            window.location.href = '/contacts/feedback/'
        },

        async sendFormData () {
            if (this.isLoading) {
                return
            }

            if (this.isValid) {
                const formData = {
                    ...this.formData
                }
                const data = new FormData()

                for (const key in formData) {
                    data.append(key, formData[key])
                }

                for (let file of this.fileList) {
                    data.append('file[]', file)
                }

                try {
                    this.isLoading = true
                    const response = await saveQualityComplaint(data)

                    if (response.public.status === 'error') {
                        showNotyfications(response.public.message, { type: 'error' })
                    } else {
                        this.formData = {
                            title: 'Жалоба на качество',
                            description: '',
                            fullName: '',
                            phone: '',
                            email: '',
                            date_of_bottling: '',
                            product_type: '',
                            brand: '',
                            taste: '',
                            liquid: '',
                            manufacturer_code: ''
                        }
                        this.$root.$bus.$emit('clear', 'description')
                        this.$root.$bus.$emit('clear', 'fullName')
                        this.$root.$bus.$emit('clear', 'phone')
                        this.$root.$bus.$emit('clear', 'email')
                        this.$root.$bus.$emit('clear', 'product_type')
                        this.$root.$bus.$emit('clear', 'brand')
                        this.$root.$bus.$emit('clear', 'taste')
                        this.$root.$bus.$emit('clear', 'liquid')
                        this.$root.$bus.$emit('clear', 'manufacturer_code')
                        this.$root.$bus.$emit('clear', 'checkbox')
                        this.$root.$bus.$emit('clear', 'file')
                        this.isChecked = false
                        this.fileList = []
                        showNotyfications('Отправка успешна', { type: 'success' })
                        this.$root.$emit('open-modal', { id: 'slotModal', index: 0 })
                    }
                } catch (error) {
                    console.log(error)
                } finally {
                    this.isLoading = false
                }
            } else {
                showNotyfications('Заполните все обязательные поля', { type: 'error' })
            }
        }
    }
}
</script>

<style lang="scss">
.information-form {
    margin: 0 auto;

    .z-modal__window {
        top: 10%;
        max-height: 250px;
        max-width: 500px;
        font-size: 18px;
    }

    &__title {
        text-align: center;
    }

    &__date-picker {
        .mx-input {
            background-color: transparent !important;
        }
    }

    &__checkbox-caption {
        font-size: 14px;

        .z-link {
            font-family: "Fact";
            font-size: 'inherit';
        }
    }

    &__button {
        background-color: $token-colors-primary-blue !important;
        margin: 0 auto !important;
        display: block !important;

        &--disabled {
            background-color: gray !important;
            cursor: default;

            &:hover {
                box-shadow: none !important;
            }

            &:active {
                background-color: gray !important;
            }
        }
    }

    &__modal {
        &-title {
            margin-bottom: 28px;

            @media screen and (min-width: 1240px) {
                font-size: 34px;
            }

            @media screen and (max-width: 767px) {
                font-size: 20px;
            }
        }

        &-text {
            line-height: 1.5;
        }
    }

    .z-date-picker .mx-input {
        font-family: 'Fact';
        font-size: 16px;
    }
}
</style>
