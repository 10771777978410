/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.5 10.25a7.25 7.25 0 1112.456 5.045.76.76 0 00-.161.162A7.25 7.25 0 014.5 10.25zm12.884 6.695a8.75 8.75 0 111.06-1.06l3.836 3.835a.75.75 0 01-1.06 1.06l-3.836-3.835z" _fill="#1D1D1B"/>'
  }
})
