<template>
    <nav
        v-if="list"
        :class="[
            'nav-mobile',
            { 'nav-mobile--opened' : navOpened }
        ]"
    >
        <a
            href="#"
            class="nav-mobile__toggle"
            @click.prevent="toggleNav"
        >
            <z-icon
                width="24"
                height="24"
                name="burger-menu"
                v-show="!navOpened"
                color="#fff"
            ></z-icon>
            <z-icon
                width="24"
                height="24"
                name="close"
                v-show="navOpened"
                color="#fff"
            ></z-icon>
        </a>

        <div class="nav-mobile__container" v-show="navOpened">
            <header class="nav-mobile__header">
                <slot name="header"></slot>
            </header>
            <div class="nav-mobile__content">
                <ul
                    v-show="navOpened"
                    ref="menuHeightStyle"
                    :class="[
                        'nav-mobile__list nav-mobile__list--1',
                        { 'nav-mobile__list--sub-opened' : navIndexOpened >= 0 }
                    ]"
                >
                    <li
                        :class="[
                            'nav-mobile__item nav-mobile__item--1',
                            {
                                'is-opened' : indexA == navIndexOpened,
                                'is-active' : itemA.active
                            }
                        ]"
                        v-for="(itemA, indexA) in list"
                        :key="indexA"
                    >
                        <a
                            :href="itemA.link"
                            @click="showChilds($event, indexA, itemA)"
                            :blank="itemA.blank ? '_blank' : false"
                            :class="[
                                'nav-mobile__link nav-mobile__link--1',
                                { 'is-active' : itemA.active }
                            ]"
                        >
                            <span v-html="itemA.name"></span>
                            <z-icon
                                width="24"
                                height="24"
                                name="arrow"
                                v-if="itemA.items && itemA.items.length"
                                dir="down"
                                color="#99BBDE"
                            ></z-icon>
                        </a>

                        <div class="nav-mobile__childs" v-if="itemA.items">
                            <div class="nav-mobile__inner" ref="menuHeight">
                                <div class="nav-mobile__childs-header">
                                    <button class="nav-mobile__close" @click.prevent="hideChilds(indexA)">
                                        <z-icon
                                            name="long-arrow"
                                            width="43"
                                            height="13"
                                            color="#99BBDE"
                                        />
                                        <span>{{ closeText }}</span>
                                    </button>
                                    <a
                                        :href="itemA.link"
                                        :blank="itemA.blank ? '_blank' : false"
                                        :class="[
                                            'nav-mobile__link nav-mobile__link--overview',
                                            { 'is-active' : checkOverviewActive(itemA.link) }
                                        ]"
                                    >
                                        <span v-html="itemA.name"></span>
                                    </a>
                                </div>

                                <ul class="nav-mobile__list nav-mobile__list--2">
                                    <li
                                        :class="[
                                            'nav-mobile__item nav-mobile__item--2',
                                            {
                                                'is-active' : itemB.active,
                                                'is-opened' : indexB == navChildsIndexOpened
                                            }
                                        ]"
                                        v-for="(itemB, indexB) in clearHide(itemA.items)"
                                        :key="indexB"
                                    >
                                        <a
                                            :href="itemB.link"
                                            :class="[
                                                'nav-mobile__link nav-mobile__link--2',
                                                { 'is-active' : itemB.active }
                                            ]"
                                            :blank="itemB.blank ? '_blank' : false"
                                            @click="showSubChilds($event, indexB, itemB)"
                                        >
                                            <span v-html="itemB.name"></span>
                                            <z-icon
                                                width="12"
                                                height="12"
                                                name="arrow"
                                                v-if="itemB.items && itemB.items.length"
                                                color="#99BBDE"
                                            />
                                        </a>
                                        <div class="nav-mobile__subchilds" v-if="itemB.items">
                                            <ul class="nav-mobile__list nav-mobile__list--3">
                                                <li
                                                    :class="[
                                                        'nav-mobile__item nav-mobile__item--3',
                                                        { 'is-active' : itemC.active }
                                                    ]"
                                                    v-for="(itemC, indexC) in clearHide(itemB.items)"
                                                    :key="indexC"
                                                >
                                                    <a
                                                        :href="itemC.link"
                                                        :class="[
                                                            'nav-mobile__link nav-mobile__link--3',
                                                            { 'is-active' : itemC.active }
                                                        ]"
                                                        :blank="itemC.blank ? '_blank' : false"
                                                        v-html="itemC.name"
                                                    ></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <footer class="nav-mobile__footer">
                <slot name="footer"></slot>
            </footer>
        </div>
    </nav>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'navigation-mobile',
    props: {
        list: [Array, Object]
    },
    data () {
        return {
            navOpened: false,
            navIndexOpened: -1,
            navChildsIndexOpened: -1,
            heightStyles: {},
            closeText: localize({
                ru: 'Главное меню',
                en: 'Main menu'
            })
        }
    },
    updated () {
        this.matchHeight()
    },
    methods: {
        matchHeight () {
            if (this.navIndexOpened !== -1) {
                let heightString = this.$refs.menuHeight[this.navIndexOpened].clientHeight + 'px'
                this.$refs.menuHeightStyle.setAttribute('style', `min-height: ${heightString}`)
            } else {
                this.$refs.menuHeightStyle.setAttribute('style', ``)
            }
        },
        buildTarget (href) {
            return /^http|^\/\//.test(href) ? '_blank' : '_self'
        },
        isActive (path) {
            return window.location.pathname === path
        },
        checkOverviewActive (link) {
            return location.pathname === link
        },
        showChilds (event, i, item) {
            if (!item.hasOwnProperty('items')) {
                this.hideChilds()
            } else if (item.hasOwnProperty('items') && item.items.length && this.navIndexOpened !== i) {
                event.preventDefault()
                this.navIndexOpened = i
                this.navChildsIndexOpened = -1
            }
        },
        showSubChilds (event, i, item) {
            if (item.hasOwnProperty('items') && item.items.length && this.navChildsIndexOpened !== i) {
                event.preventDefault()
                this.navChildsIndexOpened = i
            }
        },
        hideChilds () {
            this.navIndexOpened = -1
            this.navChildsIndexOpened = -1
        },
        toggleNav () {
            if (!this.navOpened) {
                this.navOpened = true
                document.body.style.overflow = 'hidden'
                this.$root.$bus.$emit('navigation-open')
                document.querySelector('body').classList.add('nav-opened')
            } else {
                this.navOpened = false
                document.body.style.overflow = ''
                this.$root.$bus.$emit('navigation-close')
                document.querySelector('body').classList.remove('nav-opened')
            }
        },
        clearHide (data) {
            return data.filter(item => !item.hide)
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
