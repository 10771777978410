/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-1': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 3.75a3.25 3.25 0 100 6.5 3.25 3.25 0 000-6.5zM7.25 7a4.75 4.75 0 119.5 0 4.75 4.75 0 01-9.5 0zm-4 12A4.75 4.75 0 018 14.25h8A4.75 4.75 0 0120.75 19v2a.75.75 0 01-1.5 0v-2A3.25 3.25 0 0016 15.75H8A3.25 3.25 0 004.75 19v2a.75.75 0 01-1.5 0v-2z" _fill="#1D1D1B"/>'
  }
})
