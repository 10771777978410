/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/jpg': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.5.75a.75.75 0 00-.75.75v21.184c0 .414.336.75.75.75h16.579a.75.75 0 00.75-.75v-15.3a.75.75 0 00-.221-.531L14.687.968a.75.75 0 00-.53-.218H3.5zm.75 21.184V2.25h7.974v3.958c0 1.718 1.4 3.103 3.118 3.103h3.987v12.623H4.25zm9.474-15.726V2.25h.125l5.48 5.447v.114h-3.987a1.611 1.611 0 01-1.618-1.603zm-4.71 9h-.862v2.912c0 .465-.125.697-.375.697-.248 0-.372-.2-.372-.603H6.54c0 .414.108.737.325.97.217.232.52.348.911.348.363 0 .66-.125.89-.375.231-.25.346-.582.346-.996v-2.953zm1.837 4.266v-1.5h.575c.44 0 .783-.121 1.03-.364.249-.242.373-.572.373-.99 0-.418-.126-.758-.378-1.02-.25-.261-.586-.392-1.008-.392H9.99v4.266h.861zm.592-2.218h-.592v-1.33h.601a.44.44 0 01.37.193c.09.125.137.294.137.507 0 .205-.045.361-.135.469a.469.469 0 01-.38.16zm4.551 2.142c.242-.092.447-.228.613-.408v-1.76h-1.468v.65h.603v.782l-.076.053a.747.747 0 01-.413.102c-.266 0-.457-.087-.574-.263-.116-.178-.173-.461-.173-.85v-.773c.004-.371.059-.642.164-.812.105-.17.274-.255.507-.255.191 0 .332.05.422.15.09.097.146.282.17.553h.838c-.038-.488-.174-.846-.41-1.075-.235-.228-.584-.343-1.047-.343-.492 0-.867.16-1.125.478-.255.316-.383.769-.383 1.356v.768c.007.58.147 1.022.419 1.327.271.303.652.454 1.142.454.287 0 .55-.045.791-.134z" _fill="#1D1D1B"/>'
  }
})
