<template>
    <div
        class="z-radio-wrapper"
        :class="{
            [`z-radio--size-${this.size}`]: size,
            [`z-radio--theme-${colorTheme}`]: colorTheme
        }">
        <div
            :key="index"
            class="z-radio"
            :class="{
                'is-disabled' : item.disabled
            }"
            v-for="(item, index) in options"
        >
            <input
                type="radio"
                :name="name"
                v-model="val"
                :value="item.id"
                class="z-radio__input"
                :id="`${name}_${index}`"
                :disabled="item.disabled"
                @change="onChange($event.target.value)"
                @input="onInput($event.target.value)"
            >
            <label
                class="z-radio__label"
                :for="`${name}_${index}`"
            >
                <span
                    v-html="item.text"
                    class="z-radio__text"
                ></span>
            </label>
        </div>
        <span
            class="z-radio__error"
            v-show="required && error"
        >
            <span v-show="!val">{{ text.error }}</span>
        </span>
    </div>
</template>

<script>
export default {
    name: 'z-radio',
    props: {
        options: {
            type: Array,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        required: Boolean,
        size: {
            type: String,
            validator: prop => ['s', 'm'].includes(prop),
            default: 'm'
        },
        colorTheme: {
            type: String,
            default: 'main',
            validator: prop => ['main', 'investors'].includes(prop)
        }
    },
    data () {
        return {
            error: false,
            focus: false,
            text: {
                error: this.$root.lang === 'ru' ? 'Поле обязательно для заполнения' : 'Required field'
            }
        }
    },
    computed: {
        val: {
            get () {
                return this.$attrs.value
            },
            set (value) {
                let optionsVal = this.options.map(item => item.id)

                if (optionsVal.includes(value)) {
                    this.$emit('input', value)
                } else {
                    console.error(`error value: ${value}`)
                }
            }
        }
    },
    methods: {
        onChange (value) {
            this.validate(value)
            this.$emit('input', value)
        },
        onInput (value) {
            this.validate(value)
            this.$emit('input', value)
        },
        validate () {
            if (this.required && !this.val) {
                this.error = true
            } else {
                this.error = false
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
