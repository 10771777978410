/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'File': {
    width: 36,
    height: 13,
    viewBox: '0 0 36 13',
    data: '<path pid="0" d="M6.217 7V5.77H1.466V1.866h5.543V.627H0v11.415h1.466V7h4.75zm3.52-3.44V4.82h2.885v5.966H9.737v1.255h7.103v-1.255h-2.768V3.56H9.737zm2.697-2.227c0 .235.07.43.212.588.146.157.365.235.658.235.287 0 .504-.078.65-.235a.829.829 0 00.22-.588c0-.13-.02-.251-.063-.36a.678.678 0 00-.188-.275.673.673 0 00-.258-.157.965.965 0 00-.361-.063c-.136 0-.259.021-.369.063a.745.745 0 00-.447.439c-.036.11-.054.227-.054.353zM19.38 0v1.262h2.885v9.525H19.38v1.255h7.103v-1.255h-2.768V0H19.38zm13.163 12.199c.825 0 1.518-.165 2.077-.494.565-.335.988-.716 1.27-1.145l-.886-.69a2.993 2.993 0 01-1.003.831 2.95 2.95 0 01-1.38.314 2.58 2.58 0 01-1.074-.22 2.468 2.468 0 01-.831-.603 2.76 2.76 0 01-.533-.831 3.847 3.847 0 01-.243-1.09v-.055H36v-.65c0-.591-.076-1.14-.227-1.647a3.735 3.735 0 00-.675-1.325 3.119 3.119 0 00-1.144-.87c-.455-.214-.988-.322-1.6-.322-.485 0-.961.1-1.426.298a3.81 3.81 0 00-1.231.855 4.217 4.217 0 00-.878 1.38c-.22.538-.33 1.15-.33 1.834v.33c0 .59.1 1.136.299 1.638.198.502.475.935.83 1.301.356.366.782.651 1.278.855.502.204 1.05.306 1.647.306zm-.188-7.605c.37 0 .69.068.956.204.272.136.496.314.674.533.178.22.316.48.416.784.099.298.149.578.149.839v.07h-4.563c.057-.392.157-.737.298-1.034.146-.304.324-.56.533-.769.209-.204.444-.358.705-.462.262-.11.539-.165.832-.165z" _fill="#757678"/>'
  }
})
