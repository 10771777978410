var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"welcome-form"},[_c('div',{staticClass:"welcome-form__container"},[_c('z-caption',{staticClass:"welcome-form__title",attrs:{"tag":"h1"}},[_vm._v("Поделитесь отзывами и предложениями")]),_vm._v(" "),_c('div',{staticClass:"welcome-form__description-container"},[_c('z-caption',{staticClass:"welcome-form__description",attrs:{"tag":"p"}},[_vm._v("Пожалуйста, заполните форму обратной связи. Подробная информация поможет нашим\n                специалистам лучше обработать запрос.\n            ")]),_vm._v(" "),_c('z-caption',{staticClass:"welcome-form__description",attrs:{"tag":"p"}},[_vm._v("\n                Постарайтесь сохранить товар и его упаковку.\n            ")]),_vm._v(" "),_c('z-caption',{staticClass:"welcome-form__description",attrs:{"tag":"p"}},[_vm._v("\n                Не забудьте указать контактные данные, чтобы мы могли с вами связаться.\n            ")])],1),_vm._v(" "),_c('z-caption',{staticClass:"welcome-form__require",attrs:{"tag":"p"}},[_vm._v("\n            * Поля, обязательные для заполнения\n        ")]),_vm._v(" "),_c('z-radio',{staticClass:"welcome-form__radio",attrs:{"name":"info","options":[
                {
                    id: 'info',
                    text: 'Запрос информации, вопрос, предложение',
                    disabled: false
                },
                {
                    id: 'complaint',
                    text: 'Жалоба на качество',
                    disabled: false
                }
            ]},model:{value:(_vm.selectedRadio),callback:function ($$v) {_vm.selectedRadio=$$v},expression:"selectedRadio"}}),_vm._v(" "),_c('z-button',{staticClass:"welcome-form__button",class:{ 'welcome-form__button--disabled': !_vm.selectedRadio },attrs:{"type":"primary"},on:{"click":_vm.openForm}},[_vm._v("\n            Далее\n        ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }