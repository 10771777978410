/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'webinar': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.69 4.19a1.5 1.5 0 011.06-.44h16.5a1.5 1.5 0 011.5 1.5v13.5a1.5 1.5 0 01-1.5 1.5h-5.755a.75.75 0 01-.678-.428 4.502 4.502 0 00-8.134 0 .75.75 0 01-.678.428H3.75a1.502 1.502 0 01-1.5-1.5V5.25c0-.398.158-.78.44-1.06zM13.5 13.5a3.74 3.74 0 01-1.25 2.795 6 6 0 012.697 2.455h5.303V5.25H3.75v13.5h.803a6.002 6.002 0 012.697-2.455A3.75 3.75 0 1113.5 13.5zm-3.75-2.25a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM6 6.75a.75.75 0 00-.75.75V9a.75.75 0 001.5 0v-.75h10.5v7.5h-.75a.75.75 0 000 1.5H18a.75.75 0 00.75-.75v-9a.75.75 0 00-.75-.75H6z" _fill="#1D1D1B"/>'
  }
})
