/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/7z': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.5.75a.75.75 0 00-.75.75v21.184c0 .414.336.75.75.75h16.579a.75.75 0 00.75-.75v-15.3a.75.75 0 00-.221-.531L14.687.968a.75.75 0 00-.53-.218H3.5zm.75 21.184V2.25h7.974v3.958c0 1.718 1.4 3.103 3.118 3.103h3.987v12.623H4.25zm9.474-15.726V2.25h.125l5.48 5.447v.114h-3.987a1.611 1.611 0 01-1.618-1.603zm-2.331 9.469v-.469H8.815v.683h1.716l-1.353 3.583h.867l1.348-3.797zm3.03 3.797v-.683H13.16l1.244-1.99v-.497h-2.186v.682h1.202l-1.234 1.972v.516h2.238z" _fill="#1D1D1B"/>'
  }
})
