/* eslint-disable */
require('./alarm')
require('./arrow')
require('./attachment')
require('./audio-duplicate-name')
require('./audio')
require('./burger-menu')
require('./calendar-check')
require('./calendar-minus')
require('./calendar-plus')
require('./calendar')
require('./camera')
require('./cast')
require('./check-small')
require('./check')
require('./close-small')
require('./close')
require('./Contecst')
require('./download')
require('./email-2')
require('./email')
require('./external-small')
require('./external')
require('./facebook')
require('./fax')
require('./File')
require('./file')
require('./Header')
require('./image-1')
require('./instagram')
require('./load')
require('./long-arrow')
require('./minus-small')
require('./minus')
require('./Outline')
require('./photocamera')
require('./placeholder')
require('./play')
require('./plus-small')
require('./plus')
require('./press-release')
require('./print')
require('./pulse')
require('./quote')
require('./search-small')
require('./search')
require('./share')
require('./Social')
require('./social')
require('./telegram')
require('./tooltip-small')
require('./tooltip')
require('./user-1')
require('./user-2')
require('./webinar')
require('./zoom-in')
require('./zoom-out')
