/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search-small': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.75 7.333a4.583 4.583 0 117.888 3.176.771.771 0 00-.129.129 4.583 4.583 0 01-7.76-3.305zm8.322 4.8a6.083 6.083 0 111.06-1.06l2.398 2.397a.75.75 0 01-1.06 1.06l-2.398-2.397z" _fill="#1D1D1B"/>'
  }
})
