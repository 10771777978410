/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'facebook': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.071 3H4.93A1.929 1.929 0 003 4.929V19.07C3 20.156 3.844 21 4.929 21h5.504v-6.107H7.902V12h2.531V9.83c0-2.49 1.487-3.897 3.737-3.897 1.125 0 2.25.2 2.25.2v2.452h-1.246c-1.245 0-1.647.763-1.647 1.567V12h2.772l-.442 2.893h-2.33V21h5.544A1.929 1.929 0 0021 19.071V4.93C21 3.884 20.116 3 19.071 3z" _fill="#99BBDE"/>'
  }
})
