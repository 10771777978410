/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.823 4.177a.75.75 0 010 1.06l-6.47 6.47 6.47 6.47a.75.75 0 11-1.06 1.06l-7-7-.53-.53.53-.53 7-7a.75.75 0 011.06 0z" _fill="#1D1D1B"/>'
  }
})
