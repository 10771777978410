/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tooltip': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.98 2C6.468 2 2 6.468 2 11.98c0 5.512 4.468 9.98 9.98 9.98 5.512 0 9.98-4.468 9.98-9.98 0-5.512-4.468-9.98-9.98-9.98zM1 11.98C1 5.916 5.916 1 11.98 1c6.065 0 10.98 4.916 10.98 10.98 0 6.065-4.915 10.98-10.98 10.98C5.916 22.96 1 18.046 1 11.98z" _fill="#C4B078"/><path pid="1" d="M12.173 10.463h.734v6.564l.223.23.853.06v.682H10v-.683l.823-.06.215-.229v-5.243h-1v-.609l2.135-.712zM12.01 7c.331 0 .603.106.816.319.212.208.319.472.319.793 0 .337-.107.614-.32.831-.207.213-.474.319-.8.319a1.086 1.086 0 01-1.112-1.12c0-.326.103-.598.31-.816.213-.217.475-.326.787-.326z" _fill="#000"/>'
  }
})
