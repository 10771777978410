/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.386 4.357A.75.75 0 005.25 5v14.4a.75.75 0 001.136.643l12-7.2a.75.75 0 000-1.286l-12-7.2zM16.542 12.2L6.75 18.075V6.325l9.792 5.875z" _fill="#1D1D1B"/>'
  }
})
