<template>
    <div
        class="z-select"
        :class="[
            {[`z-select--theme-${colorTheme}`]: colorTheme},
            {'is-opened': opened}
        ]"
    >
        <p v-if="required" class="z-select__required">*</p>
        <div class="z-select__container" @click.self="opened = !opened">
            <span
                class="z-select__placeholder"
                v-show="placeholder && !selected.text.length"
                v-html="placeholder"
            ></span>
            <span
                class="z-select__selected"
                v-if="selected.text.length"
                v-html="selected.text"
            ></span>
            <span
                class="z-select__clear"
                v-if="selected.text.length && allowClear"
                @click.self="removeSelected"
            >
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.99999 1L0.999969 7.00002M0.999989 1L7.00001 7.00002" stroke="#1D1D1B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </span>
            <span class="z-select__arrow">
                <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.607731 0.274418C0.900625 -0.0184748 1.3755 -0.0184747 1.66839 0.274419L5.80473 4.41075L9.94106 0.274419C10.234 -0.0184743 10.7088 -0.0184743 11.0017 0.274419C11.2946 0.567312 11.2946 1.04219 11.0017 1.33508L6.33506 6.00175L5.80473 6.53208L5.2744 6.00174L0.607731 1.33508C0.314838 1.04219 0.314838 0.567312 0.607731 0.274418Z" fill="#1D1D1B"/>
                </svg>
            </span>
        </div>
        <div class="z-select__dropdown" v-if="opened">
            <ul class="z-select__options">
                <li
                    class="z-select__option"
                    v-for="option in options"
                    :class="buildClass(option)"
                    @click="changeSelected(option)"
                    v-html="option.text"
                    :key="option.id"
                ></li>
            </ul>
        </div>

        <span
            class="z-select__error"
            v-if="required && error"
        >
            <span v-if="!this.selected.id">{{ text.error }}</span>
        </span>
    </div>
</template>

<script>
export default {
    name: 'z-select',
    props: {
        data: {
            type: Array,
            default: () => []
        },
        name: String,
        placeholder: String,
        required: Boolean,
        allowClear: {
            type: Boolean,
            default: false
        },
        initialOpened: { //раскрыт ли дропдаун
            type: Boolean,
            default: false
        },
        colorTheme: {
            type: String,
            default: 'main',
            validator: prop => ['main', 'investors'].includes(prop)
        }
    },
    data () {
        return {
            selected: {
                id: '',
                text: ''
            },
            opened: this.initialOpened,
            duration: 300,
            options: this.data,
            error: false,
            text: {
                error: this.$root.lang === 'ru' ? 'Поле обязательно для заполнения' : 'Required field'
            }
        }
    },
    mounted () {
        this.onLoad()

        this.$root.$bus.$on('clear', name => {
            if (this.name === name) {
                this.removeSelected()
                this.error = false
            }
        })

        document.addEventListener('click', e => this.hideDropdown(e))
    },
    beforeDestroy () {
        this.$root.$bus.$off('clear', name => {
            if (this.name === name) this.removeSelected()
        })

        document.removeEventListener('click', e => this.hideDropdown(e))
    },
    watch: {
        data (array) {
            this.options = array
            this.onLoad()
        }
    },
    methods: {
        onLoad () {
            for (let i = 0; i < this.options.length; i++) {
                if (this.options[i].selected === true) this.initSelected(this.options[i])
            }
        },
        buildClass (option) {
            return {
                'is-selected': option.selected,
                'is-disabled': option.disabled
            }
        },
        hideDropdown (e) {
            let isOutside = this.$el !== e.target && !this.$el.contains(e.target)
            if (isOutside && !this.initialOpened) this.opened = false
        },
        changeSelected (option) {
            option.selected && this.allowClear ? this.removeSelected() : this.addSelected(option)
            if (!this.initialOpened) this.opened = false
        },
        initSelected (option) {
            this.selected.id = option.id
            this.selected.text = option.text

            for (let i = 0; i < this.options.length; i++) {
                this.options[i].selected = this.options[i].id === option.id
            }
        },
        addSelected (option) {
            this.selected.id = option.id
            this.selected.text = option.text

            for (let i = 0; i < this.options.length; i++) {
                this.options[i].selected = this.options[i].id === option.id
            }

            this.validate()
            this.send(this.selected.id)
        },
        removeSelected () {
            this.opened = false
            this.selected.id = ''
            this.selected.text = ''

            for (let i = 0; i < this.options.length; i++) this.options[i].selected = false

            this.validate()
            this.send('')
        },
        send (value) {
            this.$nextTick(() => this.$emit('change', { value: value, name: this.name }))
        },
        validate () {
            if (this.required && !this.selected.id) {
                this.error = true
            } else {
                this.error = false
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
