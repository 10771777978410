/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'camera': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.25 5.611c0-1.028.833-1.861 1.861-1.861h12.222c1.028 0 1.861.833 1.861 1.861v1.67l4.527-1.81a.75.75 0 011.029.696v11.11a.75.75 0 01-1.029.697l-4.527-1.81v1.67a1.861 1.861 0 01-1.86 1.86H3.11a1.861 1.861 0 01-1.861-1.86V5.61zm14.444 9.427v2.795c0 .2-.161.361-.36.361H3.11a.361.361 0 01-.361-.36V5.61c0-.2.162-.361.361-.361h12.222c.2 0 .361.162.361.361v9.427zm1.5-.49l4.056 1.622V7.274l-4.056 1.623v5.65z" _fill="#1D1D1B"/>'
  }
})
