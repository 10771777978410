<template>
    <div class="remind">
        <tippy-tooltip
            @show="onShow"
            trigger="click"
            :placement="placement"
            :interactive="true"
        >
            <template v-slot:trigger>
                <slot></slot>
            </template>
            <z-link
                v-for="(item, index) in data"
                :href="item.link"
                :key="index"
                :target="item.type === 'google' ? '_blank' : false"
                :download="item.type !== 'google' ? item.link : ''"
            >
                <z-icon
                    :name="item.type"
                    width="28"
                    height="28"
                />
                {{ item.type }}
            </z-link>
        </tippy-tooltip>
    </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'remind',
    mixins: [mixinDevice],
    props: {
        data: [Array, Object],
        placement: {
            type: String,
            default: 'left'
        }
    },
    methods: {
        onShow (tippy) {
            tippy.popper.classList.add('remind-popup')
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
