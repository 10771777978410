<template>
    <section class="ip-strategy">
        <div class="container">
            <z-caption tag="h2" border-decor weight="300" family="fact" color="white" uppercase>
                <span v-html="text.title"></span>
            </z-caption>
            <div class="row">
                <div class="col-default-8 col-tablet-8 col-v-tablet-12">
                    <div class="ip-strategy__caption" v-html="text.caption"></div>
                </div>
                <div class="col-default-4 col-tablet-4 col-v-tablet-6 col-mobile-12">
                    <slot name="strategy-presentaion"/>
                </div>
                <div class="col-default-6 col-v-tablet-12">
                    <z-button
                        class="ip-strategy__btn js-analytics"
                        tag="a"
                        :href="getLink('/investors/events-calendar/capital-markets-day/')"
                        :data-analytics-label="`ClickStrategyDayInvestors${capitalizeLang}`"
                        data-analytics-action="click"
                        data-analytics-category="content"
                    >
                        <span v-html="text.link"></span>
                    </z-button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { getLink } from '@/utils/get-link'
import { localize } from '@/utils/i18n'

export default {
    name: 'ip-strategy',
    data () {
        return {
            text: {
                title: localize({
                    'ru': 'Стратегия 2024',
                    'en': 'Strategy 2024'
                }),
                caption: localize({
                    'ru': `<p>Цель нашей Стратегии&nbsp;&mdash; удвоение чистой выручки c&nbsp;2021 по&nbsp;2024&nbsp;год.</p><p>В&nbsp;рамках Дня Стратегии мы&nbsp;информируем аналитиков и&nbsp;инвесторов о&nbsp;реализации нашей стратегии, представляем новые стратегические инициативы и&nbsp;даем обновленную информацию по&nbsp;ключевым направлениям бизнеса.</p>`,
                    'en': `<p>Goal of&nbsp;our Strategy is&nbsp;to&nbsp;double our net revenue from year 2021 to&nbsp;2024.</p><p>At&nbsp;our Capital Markets Day, we&nbsp;inform analysts and investors about execution of&nbsp;our strategy, present new strategic initiatives and provide updates on&nbsp;key business areas.</p>`
                }),
                link: localize({
                    'ru': 'Capital Markets Day',
                    'en': 'Capital Markets Day'
                })
            }
        }
    },
    computed: {
        capitalizeLang: function () {
            return this.$options.filters.capitalizeString(this.$root.lang)
        }
    },
    methods: {
        getLink: getLink
    }
}
</script>

<style lang="scss">

.ip-strategy {
    background: $token-colors-blue-dark;
    background: linear-gradient(0deg, #114167, #114167), #175789;

    .container {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            display: block;
            left: 65%;
            width: 2px;
            height: (2/3)*100%;
            background-color: $token-colors-gold;
            top: -$token-spacers-l;

            @include breakpoint (v-tablet) {
                display: none;
            }
        }
    }

    &__caption {
        color: $token-colors-crisp-white;
        font-size: 34px;
        padding-right: 5%;
        line-height: 1.1;
        font-weight: bold;

        @include breakpoint (v-tablet) {
            font-size: 24px;
        }

        @include breakpoint (mobile) {
            font-size: 16px;
        }
    }

    &__presentation {
        margin-right: auto;
        margin-left: auto;
        max-width: 224px;

        @include breakpoint (v-tablet) {
            margin-right: 0;
            margin-left: 0;
        }

        &-picture {
            margin-bottom: 1em;
        }
    }

    & &__btn {
        @include breakpoint (v-tablet) {
            margin-right: auto;
        }
        @include breakpoint (mobile) {
            display: block;
            margin-left: auto;
        }
    }
}
</style>
