<template>
    <blockquote class="z-blockquote">
        <div class="z-blockquote__quote" v-if="showIcon">
            <z-icon name="quote" width="40" height="40"></z-icon>
        </div>
        <div class="z-blockquote__body">
            <div class="z-blockquote__detail" v-if="!!this.$slots['detail']">
                <slot name="detail"></slot>
            </div>
            <div class="z-blockquote__detail" v-else-if="detail" v-html="detail"></div>
        </div>
        <div class="z-blockquote__footer">
            <div class="z-blockquote__image" v-if="image">
                <img :src="image" />
            </div>
            <div class="z-blockquote__caption">
                <span
                    class="z-blockquote__author"
                    v-html="author"
                    v-if="author"
                ></span>
                <span
                    class="z-blockquote__post"
                    v-html="post"
                    v-if="post"
                ></span>
                <span
                    class="z-blockquote__divider"
                    v-if="post && date"
                >|</span>
                <span
                    class="z-blockquote__date"
                    v-if="date"
                >{{ date | momentFilter('DD MMMM YYYY') }}</span>
            </div>
        </div>
    </blockquote>
</template>

<script>
export default {
    name: 'z-blockquote',
    props: {
        date: {
            type: [String, Number],
            default: ''
        },
        detail: {
            type: String,
            default: ''
        },
        post: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: ''
        },
        author: {
            type: String,
            default: ''
        },
        showIcon: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        mobile () {
            return this.$root.isMobile()
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
