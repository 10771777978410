/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/pdf': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.5.75a.75.75 0 00-.75.75v21.184c0 .414.336.75.75.75h16.579a.75.75 0 00.75-.75v-15.3a.75.75 0 00-.221-.531L14.687.968a.75.75 0 00-.53-.218H3.5zm.75 21.184V2.25h7.974v3.958c0 1.718 1.4 3.103 3.118 3.103h3.987v12.623H4.25zm9.474-15.726V2.25h.125l5.48 5.447v.114h-3.987a1.611 1.611 0 01-1.618-1.603zM7.713 19.474v-1.5h.574c.44 0 .783-.121 1.031-.364.248-.242.372-.572.372-.99 0-.418-.126-.758-.378-1.02-.25-.261-.586-.392-1.008-.392H6.851v4.266h.862zm.591-2.218h-.591v-1.33h.6a.44.44 0 01.37.193c.091.125.137.294.137.507 0 .205-.045.361-.135.469a.469.469 0 01-.38.16zm2.263-2.048v4.266h1.105c.52 0 .926-.16 1.221-.478.297-.32.446-.76.446-1.321v-.692c-.006-.55-.158-.984-.454-1.3-.295-.317-.692-.475-1.19-.475h-1.128zm.861 3.55v-2.832h.314c.268.008.457.09.568.249.112.156.167.433.167.832v.741c-.005.371-.067.633-.184.785-.117.15-.32.226-.607.226h-.258zm3.693-1.025h1.339v-.714h-1.339v-1.093h1.5v-.718H14.26v4.266h.861v-1.74z" _fill="#1D1D1B"/>'
  }
})
