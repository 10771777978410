/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'attachment': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.05 3.75a2.86 2.86 0 00-1.96.762l-8.038 7.58c-.84.792-1.302 1.857-1.302 2.956 0 1.1.463 2.164 1.302 2.956A4.665 4.665 0 008.25 19.25a4.665 4.665 0 003.198-1.246l8.037-7.58a.75.75 0 011.03 1.091l-8.038 7.58A6.165 6.165 0 018.25 20.75a6.165 6.165 0 01-4.227-1.655c-1.13-1.065-1.773-2.52-1.773-4.047 0-1.527.644-2.981 1.773-4.047l8.037-7.58a4.36 4.36 0 012.99-1.171 4.36 4.36 0 012.99 1.171 3.959 3.959 0 011.26 2.88c0 1.09-.459 2.124-1.26 2.88l-8.046 7.58a2.555 2.555 0 01-1.752.688c-.65 0-1.28-.243-1.753-.688a2.355 2.355 0 01-.748-1.713c0-.647.272-1.258.74-1.704l7.573-7.573a.75.75 0 011.06 1.06l-7.58 7.58a.855.855 0 00-.293.637c0 .225.094.45.278.622.184.174.444.279.723.279.278 0 .538-.105.722-.28l8.046-7.58c.512-.482.79-1.126.79-1.788 0-.662-.278-1.306-.79-1.789a2.86 2.86 0 00-1.96-.762z" _fill="#1D1D1B"/>'
  }
})
