<template>
    <div class="investors-news">
        <div class="investors-news__filter">
            <z-caption
                tag="h2"
                class="u-collapse--bottom"
                v-html="text.filterTitle"
            ></z-caption>
            <z-select
                :data="yearsOption"
                name="year"
                @change="filterChange"
                :placeholder="text.year"
                allowClear
                class="investors-news__filter-select"
            ></z-select>
        </div>

        <div v-if="data.items && data.items.length" class="investors-news__results">
            <div class="row">
                <div
                    v-for="(item, index) in data.items"
                    :key="index"
                    class="col-default-3 col-tablet-4 col-v-tablet-6 col-mobile-12"
                >
                    <z-card
                        :link="getDetailLink(item.detailPageUrl)"
                        full-height
                        class="investors-news__card"
                    >
                        <template v-slot:content>
                            <date-block
                                :date="item.activeFrom"
                                :lang="$root.lang"
                                class="u-bottom-margin--s"
                            ></date-block>
                            <span class="u-bold" v-html="item.name"></span>
                        </template>
                    </z-card>
                </div>
            </div>
        </div>

        <div class="investors-news__paggination u-top-margin--xl">
            <z-pagination
                :data="navData"
                showNav
                showPrev
                showNext
                @change-page="pageChange"
            ></z-pagination>
        </div>
    </div>
</template>

<script>
import { debounce } from 'throttle-debounce'
import { localize } from '@/utils/i18n'
import axios from 'axios'
import { queryString } from '@/utils/queryString'

export default {
    name: 'investors-news',
    data () {
        return {
            data: {},
            text: {
                filterTitle: localize({
                    ru: 'Фильтр:',
                    en: 'Filter by:'
                }),
                year: localize({
                    ru: 'Выберите год',
                    en: 'Year'
                })
            },
            requestPrefix: this.$root.lang === 'en' ? '/en' : '',
            params: {
                year: '',
                page: 1
            }
        }
    },
    mounted () {
        this.params = { ...this.params, ...this.getGetParams() }
        this.onChangeParam(this.params.page)
    },
    computed: {
        yearsOption () {
            if (this.data.filter) {
                return this.data.filter.year || []
            } else return []
        },
        navData () {
            if (this.data.nav) {
                const isExtreme = this.data.nav.current === 1 || this.data.nav.current === this.data.nav.total

                return {
                    currentPage: this.data.nav.current,
                    totalPages: this.data.nav.total,
                    pageRange: isExtreme ? 3 : 2
                }
            } else return {}
        }
    },
    methods: {
        getDetailLink (url) {
            return url + document.location.search
        },
        filterChange (data) {
            this.params[data.name] = data.value
            this.params.page = 1
            this.onChangeParam()
        },
        pageChange (data) {
            this.params.page = data.page
            this.onChangeParam()
        },
        onChangeParam () {
            queryString(this.clearEmptyParams(this.params))
            this.addPageQuery(this.params.page)
            this.send(this.clearEmptyParams(this.params))
        },
        addPageQuery (page) {
            if (!page) return

            const queryParams = document.location.search
            let pageParam = `page=${page}`
            pageParam = queryParams ? `&${pageParam}` : `?${pageParam}`
            history.pushState({}, '', queryParams + pageParam)
        },
        send: debounce(0, function (params) {
            try {
                axios({
                    url: `${this.requestPrefix}/api/news/?investors=Y`,
                    method: 'get',
                    params: params
                }).then(res => {
                    this.data = res.data
                })
            } catch (error) {
                console.error(error)
            }
        }),
        clearEmptyParams (obj) {
            let params = Object.assign({}, obj)

            Object.keys(params).forEach(function (key, index) {
                if (Array.isArray(params[key])) {
                    if (!(params[key].filter(val => { return val !== null && val !== undefined && val !== '' && val !== false }).length)) delete params[key]
                } else if (!params[key]) {
                    delete params[key]
                }
            })

            return params
        },
        sanitizeValue (text) {
            return text
                // htmlspecialchars
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"/g, '&quot;')
                .replace(/'/g, '&#039;')
                // addslashes
                .replace(/\\/g, '\\\\')
                // eslint-disable-next-line
                .replace(/\u0008/g, '\\b')
                .replace(/\t/g, '\\t')
                .replace(/\n/g, '\\n')
                .replace(/\f/g, '\\f')
                .replace(/\r/g, '\\r')
                .replace(/'/g, '\\\'')
                .replace(/"/g, '\\"')
        },
        getGetParams (url = window.location) {
            let params = {}

            new URL(url).searchParams.forEach((val, key) => {
                if (key.includes('[]')) {
                    if (!params[key.replace('[]', '')]) params[key.replace('[]', '')] = []
                    if (!params[key.replace('[]', '')].includes(val)) params[key.replace('[]', '')].push(this.sanitizeValue(val))
                } else {
                    params[key] = this.sanitizeValue(val)
                }
            })

            return params
        }
    }
}
</script>

<style lang="scss">
.investors-news {
    display: flex;
    flex-direction: column;

    &__filter {
        display: flex;
        align-items: flex-end;
        gap: 2rem;
        margin-bottom: 1rem;
    }

    &__filter-select {
        max-width: 250px;
        margin-bottom: 6px !important;

        @include breakpoint(mobile) {
            margin-bottom: 0px !important;
        }

        .z-select__container {
            padding-bottom: 4px;

            @include breakpoint(mobile) {
                padding-bottom: 2px;
            }
        }
    }

    &__card {
        .z-card__content {
            @include breakpoint(tablet) {
                padding: 16px !important;
            }

            @include breakpoint(mobile) {
                padding: 15px !important;
            }
        }
    }

    &__paggination {
        margin-bottom: 20px;

        @include breakpoint(v-tablet) {
            margin-bottom: 16px;
        }
    }
}
</style>
