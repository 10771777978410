/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'email-2': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75v1.286a3.32 3.32 0 01-3.321 3.321 3.32 3.32 0 01-2.881-1.667 4.607 4.607 0 111.06-2.94v1.286a1.821 1.821 0 103.642 0V12a8.25 8.25 0 10-3.536 6.772.75.75 0 11.858 1.23A9.708 9.708 0 0112 21.75c-5.385 0-9.75-4.365-9.75-9.75zm12.857 0a3.107 3.107 0 10-6.214 0 3.107 3.107 0 006.214 0z" _fill="#1D1D1B"/>'
  }
})
