<template>
    <div
        class="z-select-multi"
        :class="[
            {[`z-select-multi--theme-${colorTheme}`]: colorTheme},
            {'is-opened': opened}
        ]"
    >
        <div class="z-select-multi__container" @click.self="opened = !opened">
            <span
                class="z-select-multi__placeholder"
                v-show="placeholder && !selected.length"
                v-html="placeholder"
            ></span>
            <div class="z-select-multi__wrap">
                <span
                    class="z-select-multi__selected"
                    v-for="(item, index) in selected"
                    v-if="selected.length"
                    :key="index"
                >
                    <span v-html="item.text"></span>
                    <span
                        class="z-select-multi__delete"
                        @click.prevent="changeSelected(item)"
                    >
                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.99999 1L0.999969 7.00002M0.999989 1L7.00001 7.00002" stroke="#1D1D1B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span>
                </span>
            </div>
            <span
                class="z-select-multi__clear"
                v-if="selected.length"
                @click.self="clearSelected"
            >
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.99999 1L0.999969 7.00002M0.999989 1L7.00001 7.00002" stroke="#1D1D1B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </span>
            <span class="z-select-multi__arrow">
                <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.607731 0.274418C0.900625 -0.0184748 1.3755 -0.0184747 1.66839 0.274419L5.80473 4.41075L9.94106 0.274419C10.234 -0.0184743 10.7088 -0.0184743 11.0017 0.274419C11.2946 0.567312 11.2946 1.04219 11.0017 1.33508L6.33506 6.00175L5.80473 6.53208L5.2744 6.00174L0.607731 1.33508C0.314838 1.04219 0.314838 0.567312 0.607731 0.274418Z" fill="#1D1D1B"/>
                </svg>
            </span>
        </div>
        <div class="z-select-multi__dropdown" v-if="opened">
            <ul class="z-select-multi__options">
                <li
                    class="z-select-multi__option"
                    v-for="option in options"
                    :class="buildClass(option)"
                    @click="changeSelected(option)"
                    v-html="option.text"
                    :key="option.id"
                ></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'z-select-multi',
    props: {
        data: {
            type: Array,
            default: () => []
        },
        name: String,
        placeholder: String,
        initialOpened: { //раскрыт ли дропдаун
            type: Boolean,
            default: false
        },
        colorTheme: {
            type: String,
            default: 'main',
            validator: prop => ['main', 'investors'].includes(prop)
        }
    },
    data () {
        return {
            selected: [],
            opened: this.initialOpened,
            duration: 300,
            options: this.data
        }
    },
    mounted () {
        this.onLoad()

        this.$root.$bus.$on('clear', name => {
            if (this.name === name) this.clearSelected()
        })

        document.addEventListener('click', e => this.hideDropdown(e))
    },
    beforeDestroy () {
        this.$root.$bus.$off('clear', name => {
            if (this.name === name) this.clearSelected()
        })

        document.removeEventListener('click', e => this.hideDropdown(e))
    },
    watch: {
        data (array) {
            this.options = array
        }
    },
    methods: {
        onLoad () {
            for (let i = 0; i < this.options.length; i++) {
                if (this.options[i].selected === true) this.initSelected(this.options[i])
            }
        },
        buildClass (option) {
            return {
                'is-selected': option.selected,
                'is-disabled': option.disabled
            }
        },
        hideDropdown (e) {
            let isOutside = this.$el !== e.target && !this.$el.contains(e.target)
            if (isOutside && !this.initialOpened) this.opened = false
        },
        changeSelected (option) {
            option.selected ? this.removeSelected(option) : this.addSelected(option)
        },
        initSelected (option) {
            this.selected.push(option)
            this.toggleSelected(option, true)
        },
        addSelected (option) {
            this.initSelected(option)
            this.send()
        },
        removeSelected (option) {
            let index = this.selected.indexOf(option)
            if (index > -1) this.selected.splice(index, 1)
            this.toggleSelected(option, false)
            this.send()
        },
        toggleSelected (option, value) {
            for (let i = 0; i < this.options.length; i++) {
                if (this.options[i].id === option.id) this.options[i].selected = value
            }
        },
        clearSelected () {
            this.selected = []
            this.opened = false
            for (let i = 0; i < this.options.length; i++) this.options[i].selected = false
            this.send(null)
        },
        send (value) {
            let data = {
                value: [],
                name: this.name
            }

            if (value !== null) {
                for (let i = 0; i < this.selected.length; i++) data.value.push(this.selected[i].id)
            }

            this.$nextTick(() => this.$emit('change', data))
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
