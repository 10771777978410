/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tooltip-small': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.066 2a6.066 6.066 0 100 12.132A6.066 6.066 0 008.066 2zM1 8.066a7.066 7.066 0 1114.132 0A7.066 7.066 0 011 8.066z" _fill="#C4B078"/><path pid="1" d="M8.637 6.52h.553v4.773l.168.168.642.043V12H7v-.496l.62-.043.162-.168V7.48h-.754v-.442l1.609-.518zM8.514 4c.25 0 .454.077.614.232.16.151.24.343.24.577 0 .245-.08.446-.24.604a.822.822 0 01-.603.232.827.827 0 01-.598-.232.776.776 0 01-.24-.582.81.81 0 01.235-.594A.81.81 0 018.514 4z" _fill="#000"/>'
  }
})
