/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'load': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21 1.25a.75.75 0 01.75.75v6a.75.75 0 01-.75.75h-6a.75.75 0 010-1.5h4.041L17.86 6.189a8.251 8.251 0 101.924 8.553.75.75 0 111.414.498 9.75 9.75 0 11-2.322-10.154l1.375 1.233V2a.75.75 0 01.75-.75z" _fill="#1D1D1B"/>'
  }
})
