<template>
    <article class="welcome-form">
        <div class="welcome-form__container">
            <z-caption tag="h1" class="welcome-form__title"
                >Поделитесь отзывами и предложениями</z-caption
            >

            <div class="welcome-form__description-container">
                <z-caption tag="p" class="welcome-form__description"
                    >Пожалуйста, заполните форму обратной связи. Подробная информация поможет нашим
                    специалистам лучше обработать запрос.
                </z-caption>

                <z-caption tag="p" class="welcome-form__description">
                    Постарайтесь сохранить товар и его упаковку.
                </z-caption>

                <z-caption tag="p" class="welcome-form__description">
                    Не забудьте указать контактные данные, чтобы мы могли с вами связаться.
                </z-caption>
            </div>

            <z-caption tag="p" class="welcome-form__require">
                * Поля, обязательные для заполнения
            </z-caption>

            <z-radio
                name="info"
                class="welcome-form__radio"
                v-model="selectedRadio"
                :options="[
                    {
                        id: 'info',
                        text: 'Запрос информации, вопрос, предложение',
                        disabled: false
                    },
                    {
                        id: 'complaint',
                        text: 'Жалоба на качество',
                        disabled: false
                    }
                ]"
            ></z-radio>

            <z-button
                class="welcome-form__button"
                :class="{ 'welcome-form__button--disabled': !selectedRadio }"
                type="primary"
                @click="openForm"
            >
                Далее
            </z-button>
        </div>
    </article>
</template>

<script>
export default {
    name: 'welcome-form',
    data() {
        return {
            selectedRadio: null
        }
    },

    methods: {
        openForm() {
            if (this.selectedRadio === 'info') {
                window.location.href = '/contacts/feedback/feedback-request/'
            } else if (this.selectedRadio === 'complaint') {
                window.location.href = '/contacts/feedback/complaint-request/'
            }
        }
    }
}
</script>

<style lang="scss">
.welcome-form {
    margin: 0 auto;

    &__title {
        font-size: $token-typo-h-3-tablet-size;
        margin-bottom: $token-spacers-l;
        padding-top: 0;
    }

    &__description-container {
        margin-bottom: $token-spacers-m;
    }

    &__description {
        margin-bottom: 8px;
        font-size: $token-typo-content-desktop-size;
    }

    &__require {
        color: #ff9573 !important;
        font-size: $token-typo-content-mobile-size !important;
        margin-bottom: 36px;
    }

    &__radio {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px;
    }

    &__button {
        background-color: #ff9573 !important;
        margin: 0 auto !important;
        display: block !important;

        &--disabled {
            background-color: gray !important;
            cursor: default;

            &:hover {
                box-shadow: none !important;
            }

            &:active {
                background-color: gray !important;
            }
        }
    }
}
</style>
