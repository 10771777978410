/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'alarm': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.357 3.28a.75.75 0 10-1.06-1.06L2.22 5.297a.75.75 0 001.06 1.06L6.357 3.28zm12.308-1.06a.75.75 0 10-1.06 1.06l3.076 3.077a.75.75 0 001.06-1.06L18.666 2.22zm-14.14 9.76a7.455 7.455 0 1114.91 0 7.455 7.455 0 01-14.91 0zm7.456-8.954A8.955 8.955 0 006.574 19.12l-.837 1.133a.75.75 0 001.206.892l.901-1.22a8.92 8.92 0 004.137 1.01 8.92 8.92 0 004.136-1.01l.902 1.22a.75.75 0 101.206-.892l-.837-1.133A8.955 8.955 0 0011.98 3.026zm4.604 6.948a.75.75 0 00-1.003-1.115l-4.6 4.14-1.548-1.549a.75.75 0 10-1.06 1.061l2.05 2.051a.75.75 0 001.033.027l5.128-4.615z" _fill="#1D1D1B"/>'
  }
})
