<template>
    <section class="investors-page__section ip-financial-results">
        <div class="container">
            <z-caption tag="h2" border-decor weight="300" family="fact" uppercase>
                <span v-html="text.title"></span>
            </z-caption>
            <slot name="reports"/>
            <div class="row">
                <div class="col-default-12">
                    <z-button
                        class="u-push-right js-analytics"
                        tag="a"
                        :href="getLink('/investors/reporting-center/results/')"
                        :data-analytics-label="`ClickLatestReportsInvestors${capitalizeLang}`"
                        data-analytics-action="click"
                        data-analytics-category="content"
                    >
                        <span v-html="text.link"></span>
                    </z-button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { getLink } from '@/utils/get-link'
import { localize } from '@/utils/i18n'

export default {
    name: 'ip-financial-results',
    components: {
    },
    data () {
        return {
            text: {
                title: localize({
                    ru: 'Последние отчеты',
                    en: 'Latest reports'
                }),
                link: localize({
                    ru: 'Результаты',
                    en: 'Results'
                })
            }
        }
    },
    computed: {
        capitalizeLang: function () {
            return this.$options.filters.capitalizeString(this.$root.lang)
        }
    },
    methods: {
        getLink: getLink
    }
}
</script>

<style lang="scss">
.ip-financial-results {
    background: $token-colors-grey-light;

    &__col {
        @include breakpoint (v-tablet) {
            flex-basis: 300px;
            max-width: 300px;
        }

        @include breakpoint (mobile) {
            flex-basis: 270px;
            max-width: 270px;
        }
    }

    @include breakpoint (v-tablet) {
        &__row {
            overflow: auto;
            flex-wrap: nowrap;
        }
    }

    .z-card__content {
        font-family: 'Future';
    }
}
</style>
