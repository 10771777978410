/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/txt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.5.75a.75.75 0 00-.75.75v21.184c0 .414.336.75.75.75h16.579a.75.75 0 00.75-.75v-15.3a.75.75 0 00-.221-.531L14.687.968a.75.75 0 00-.53-.218H3.5zm.75 21.184V2.25h7.974v3.958c0 1.718 1.4 3.103 3.118 3.103h3.987v12.623H4.25zm9.474-15.726V2.25h.125l5.48 5.447v.114h-3.987a1.611 1.611 0 01-1.618-1.603zm-5.27 9.718h1.054v-.718H6.552v.718H7.59v3.548h.865v-3.548zm3.76-.718l-.575 1.465-.574-1.465h-.987l1.005 2.115-1.026 2.15h1l.582-1.49.586 1.49h1l-1.026-2.15 1.002-2.115h-.987zm3.455.718h1.055v-.718h-2.956v.718h1.037v3.548h.864v-3.548z" _fill="#1D1D1B"/>'
  }
})
