<template>
    <div class="z-input-file" :class="`z-input-file--theme-${colorTheme}`">
        <label
            :for="name"
            class="z-input-file__label"
            :class="{
                'is-disabled': disabled
            }"
        >
            <input
                :id="name"
                type="file"
                :name="name"
                ref="fileUploader"
                :disabled="disabled"
                @change="change($event.target)"
                :multiple="multiple"
                :accept="accept"
            />
            <z-icon name="attachment" width="24" height="24" />
            <span>
                <slot>{{ inputText }}</slot>
            </span>
        </label>
        <div class="z-input-file__filelist" v-if="files.length">
            <div class="z-input-file__file" v-for="(file, index) in files" :key="index">
                <span>{{ file }}</span>
                <z-icon name="close" width="24" height="24" @click="remove(file)" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'z-input-file',
    props: {
        name: {
            type: String,
            required: true
        },
        disabled: Boolean,
        multiple: Boolean,
        accept: {
            type: String,
            default: '*'
        },
        colorTheme: {
            type: String,
            default: 'main',
            validator: prop => ['main', 'investors'].includes(prop)
        }
    },
    data () {
        return {
            files: [],
            uploaded: []
        }
    },
    computed: {
        inputText () {
            let text = ''
            if (this.$root.lang === 'ru' && this.multiple) {
                text = 'Прикрепить файлы'
            } else if (this.$root.lang === 'ru' && !this.multiple) {
                text = 'Прикрепить файл'
            } else {
                text = 'Attach files'
            }

            return text
        }
    },
    mounted () {
        this.$root.$bus.$on('clear', (name) => {
            if (this.name === name) this.clear()
        })
    },
    beforeDestroy () {
        this.$root.$bus.$off('clear', (name) => {
            if (this.name === name) this.clear()
        })
    },
    methods: {
        change (data) {
            this.buildList(data)
            console.log(this.uploaded, 'this.uploaded')

            this.$nextTick(() => this.$emit('change', { value: this.uploaded, name: this.name }))
        },
        clear () {
            this.files = []
            this.uploaded = []
            this.$nextTick(() => this.$emit('change', { value: this.uploaded, name: this.name }))
        },
        buildList (data) {
            this.files = []
            this.uploaded = data.files

            for (let i = 0; i < data.files.length; i++) {
                this.files.push(data.files[i].name)
            }
        },
        remove (file) {
            let index = this.files.indexOf(file)
            if (index > -1) this.files.splice(index, 1)

            this.$refs.fileUploader.value = ''
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
