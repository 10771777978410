<template>
    <div
        :class="[
            'z-date-picker',
            { [`z-date-picker--theme-${colorTheme}`]: colorTheme },
            {
                'is-disabled': this.disabled,
                'is-filled': isFilled,
                'is-errored': isValid === false,
                'is-valid': isValid
            }
        ]"
    >
        <p v-if="required" class="z-date-picker__required">*</p>
        <date-picker
            v-bind="$attrs"
            :key="lang"
            :type="type"
            :lang="currentLang"
            v-model="date"
            :range="range"
            :format="format"
            @change="change"
            :title-format="format"
            :clearable="clearable"
            :value-type="valueType"
            :placeholder="placeholder"
            :disabled-date="disabledDate"
        >
        </date-picker>
        <span
            :class="['z-date-picker__error', errorClass]"
            v-html="error"
            v-if="error && !isValid"
        ></span>
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import ru from 'vue2-datepicker/locale/ru'
import en from 'vue2-datepicker/locale/en'

export default {
    name: 'z-date-picker',
    components: {
        DatePicker
    },
    props: {
        range: Boolean,
        disabled: Boolean,
        clearable: {
            type: Boolean,
            default: true
        },
        required: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'date',
            validator: (prop) =>
                ['date', 'datetime', 'year', 'month', 'time', 'week'].includes(prop)
        },
        valueType: {
            type: String,
            default: 'X'
        },
        placeholder: {
            type: String,
            default: 'Выберите дату'
        },
        format: {
            type: String,
            default: 'DD.MM.YYYY'
        },
        lang: {
            type: String,
            default: 'en'
        },
        value: [Array, String],
        disabledDate: {
            type: Function,
            default: () => {}
        },
        errorClass: {
            type: String,
            default: ''
        },
        colorTheme: {
            type: String,
            default: 'main',
            validator: prop => ['main', 'investors'].includes(prop)
        }
    },
    data () {
        return {
            isValid: null,
            date: this.value,
            error: '',
            text: {
                error: {
                    ru: 'Поле обязательно для заполнения',
                    en: 'Required field',
                    cn: '填项目'
                }
            }
        }
    },
    created () {
        import(`vue2-datepicker/locale/${this.lang}`)
    },
    watch: {
        value (value) {
            this.date = value
        }
    },
    computed: {
        currentLang () {
            return this.lang === 'ru' ? ru : en
        },
        isFilled () {
            if (Array.isArray(this.date)) {
                return Boolean(this.date[0] && this.date[1])
            }

            return Boolean(this.date)
        }
    },
    methods: {
        change (data) {
            let result = data

            if (Array.isArray(data) && !(data[0] && data[1])) {
                result = undefined
            }

            this.$emit('input', result)
            this.$emit('change', result)
            this.validate()
        },
        validate () {
            if (this.required && !this.isFilled) {
                this.isValid = false
                this.error = this.$root.localize(this.text.error)
                return
            }

            this.isValid = true
            this.error = ''
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
