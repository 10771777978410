const app = {
    state: {
        device: 'desktop',
        siteSelectorLink: ''
    },
    mutations: {
        SetSiteSelectorLink: (state, siteSelectorLink) => {
            state.siteSelectorLink = siteSelectorLink
        },
        ToggleDevice: (state, device) => {
            state.device = device
        }
    },
    actions: {
        SetSiteSelectorLink ({ commit }, link) {
            commit('SetSiteSelectorLink', link)
        },
        ToggleDevice ({ commit }, device) {
            commit('ToggleDevice', device)
        }
    }
}

export default app
