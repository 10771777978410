<template>
    <div class="regulatory-disclosure-sections">
        <z-accordion initial="first">
            <z-accordion-item
                :id="'item-' + item.year"
                :key="key"
                v-for="(item, key) in data"
            >
                <template v-slot:header>
                    <span>{{ item.year }}</span>
                </template>
                <template v-slot:body>
                    <div class="row">
                        <div
                            class="col-default-6 col-mobile-12"
                            :key="index"
                            v-for="(message, index) in item.items">
                            <z-filelist>
                                <z-filelist-item :icon="'file/' + message.type" :info="message.size + ' ' + message.type">
                                    <z-link external :href="message.link" theme="black">{{ message.name }}
                                    </z-link>
                                </z-filelist-item>
                            </z-filelist>
                        </div>
                    </div>
                </template>
            </z-accordion-item>
        </z-accordion>
    </div>
</template>

<script>
export default {
    name: 'regulatory-disclosure-sections',
    props: {
        data: {
            type: [Object, Array],
            default: () => {
                return null
            }
        }
    }
}
</script>

<style scoped>

</style>
