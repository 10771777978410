/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fax': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21.935 10.953l-2.997-2.665V4.19L15.498.75h-9.31v6H2.813a1.126 1.126 0 00-1.126 1.125v14.25a1.126 1.126 0 001.125 1.125h19.5V11.793a1.128 1.128 0 00-.377-.84zM6.188 21.75h-3V8.25h3v13.5zm8.25-19.5h.439l2.56 2.56v.44h-3v-3zm-6.75 0h5.25v4.5h4.5v3.375h-9.75V2.25zm13.125 19.5H7.688V11.625h11.25v-1.33l1.875 1.667v9.788zM9.938 14h7.5v1.5h-7.5V14zm7.5 3h-7.5v1.5h7.5V17z" _fill="#1D1D1B"/>'
  }
})
