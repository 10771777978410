<template>
    <a
        :href="href"
        class="z-link"
        :class="classObject"
        @click="$emit('click')"
        :download="downloadable ? download : false"
        :target="blank && !downloadable ? '_blank' : false"
    >
        <span>
            <slot />
        </span><z-icon
            v-if="external"
            name="external"
            dir="top"
            width="16"
            height="16"
        />
    </a>
</template>

<script>
export default {
    name: 'z-link',
    props: {
        href: {
            type: String,
            required: true,
            default: ''
        },
        size: {
            type: String,
            validator: prop => ['xs', 's', 'm', 'l', 'xl'].includes(prop),
            default: 'm'
        },
        weight: {
            type: String,
            default: ''
        },
        theme: {
            type: String,
            default: 'blue'
        },
        download: {
            type: String,
            default: ''
        },
        underline: {
            type: Boolean,
            default: true
        },
        external: Boolean
    },
    computed: {
        classObject () {
            return [
                {
                    'z-link--external': this.external,
                    'z-link--underlined': this.underline,
                    [`z-link--size-${this.size}`]: this.size,
                    [`z-link--theme-${this.theme}`]: this.theme,
                    [`z-link--weight-${this.weight}`]: this.weight
                }
            ]
        },
        downloadable () {
            if (!this.download.length) return false

            return Modernizr.adownload
        },
        blank () {
            return this.http || this.external
        },
        http () {
            let http = this.href.match(/^http/g)

            if (http !== null) {
                return http.length
            } else {
                return false
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
