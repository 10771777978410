/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'photocamera': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.222 4.75c-.2 0-.36.162-.36.361A1.861 1.861 0 017 6.972H4.222c-.813 0-1.472.66-1.472 1.472v8.89c0 .812.66 1.472 1.472 1.472h15.556c.813 0 1.472-.66 1.472-1.473V8.444c0-.813-.66-1.472-1.472-1.472H17a1.861 1.861 0 01-1.861-1.86c0-.2-.162-.362-.361-.362H9.222zm-1.86.361c0-1.028.832-1.861 1.86-1.861h5.556c1.028 0 1.86.833 1.86 1.861 0 .2.163.361.362.361h2.778a2.972 2.972 0 012.972 2.972v8.89a2.972 2.972 0 01-2.972 2.972H4.222a2.972 2.972 0 01-2.972-2.973V8.444a2.972 2.972 0 012.972-2.972H7c.2 0 .361-.161.361-.36zM12 10.306a2.583 2.583 0 100 5.166 2.583 2.583 0 000-5.166zm-4.083 2.583a4.083 4.083 0 118.166 0 4.083 4.083 0 01-8.166 0z" _fill="#1D1D1B"/>'
  }
})
