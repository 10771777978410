/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/tif': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.5.75a.75.75 0 00-.75.75v21.184c0 .414.336.75.75.75h16.579a.75.75 0 00.75-.75v-15.3a.75.75 0 00-.221-.531L14.687.968a.75.75 0 00-.53-.218H3.5zm.75 21.184V2.25h7.974v3.958c0 1.718 1.4 3.103 3.118 3.103h3.987v12.623H4.25zm9.474-15.726V2.25h.125l5.48 5.447v.114h-3.987a1.611 1.611 0 01-1.618-1.603zm-4.2 9.718h1.054v-.718H7.622v.718h1.037v3.548h.864v-3.548zm1.887 3.548h.861v-4.266h-.861v4.266zm2.746-1.74h1.34v-.715h-1.34v-1.093h1.5v-.718h-2.361v4.266h.861v-1.74z" _fill="#1D1D1B"/>'
  }
})
