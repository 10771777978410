/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check-small': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M13 4l-7 7-3-3" _stroke="#1D1D1B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
