/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/xls': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.5.75a.75.75 0 00-.75.75v21.184c0 .414.336.75.75.75h16.579a.75.75 0 00.75-.75v-15.3a.75.75 0 00-.221-.531L14.687.968a.75.75 0 00-.53-.218H3.5zm.75 21.184V2.25h7.974v3.958c0 1.718 1.4 3.103 3.118 3.103h3.987v12.623H4.25zm9.474-15.726V2.25h.125l5.48 5.447v.114h-3.987a1.611 1.611 0 01-1.618-1.603zm-4.866 9l-.574 1.465-.574-1.465h-.988l1.005 2.115-1.025 2.15H7.7l.583-1.49.586 1.49h.999l-1.026-2.15 1.002-2.115h-.987zm4.12 3.55h-1.511v-3.55h-.862v4.266h2.373v-.715zm2.465-.796c.09.088.135.219.135.393 0 .318-.15.477-.451.477-.217 0-.373-.056-.469-.167-.095-.111-.143-.29-.143-.536h-.865c0 .287.064.536.19.747.13.211.308.373.534.486.227.114.478.17.753.17.406 0 .727-.104.964-.313.236-.21.354-.5.354-.87 0-.338-.094-.616-.284-.835-.187-.219-.49-.404-.905-.554-.238-.102-.404-.2-.498-.293a.447.447 0 01-.14-.32c0-.15.041-.269.125-.357a.458.458 0 01.352-.135c.16 0 .282.053.366.159.086.103.129.255.129.454h.858c0-.256-.055-.485-.167-.686a1.13 1.13 0 00-.471-.466 1.443 1.443 0 00-.7-.167c-.393 0-.717.11-.973.329-.254.216-.381.505-.381.864 0 .297.094.555.281.773.188.217.495.407.923.569.234.095.395.188.483.278z" _fill="#1D1D1B"/>'
  }
})
