<template>
    <div class="events-calendar events" v-if="data">
        <z-tabs @openedItemID="getCurrentTab">
            <template v-slot:label>
                <z-tabs-label id="upcoming">{{text.titleTab.upcoming}}</z-tabs-label>
                <z-tabs-label id="past">{{text.titleTab.past}}</z-tabs-label>
            </template>
            <template v-slot:content>
                <z-tabs-content id="upcoming">
                    <div class="events__filter">
                        <z-caption tag="h2"><z-icon name="calendar-solid" width="32" height="35"></z-icon> {{text.titleTab.upcoming}}</z-caption>
                        <div class="row">
                            <div class="col-default-4 col-v-tablet-6 col-mobile-12">
                                <z-select
                                    :data="filterUpcomingData.category"
                                    :allow-clear="false"
                                    ref="filterUpcomingCategory"
                                    name="category"
                                    @change="onChangeFilter"
                                    placeholder="Select category"
                                    color-theme="investors"
                                />
                            </div>
                            <div class="col-default-4 col-v-tablet-6 col-mobile-12" v-show="filterUpcomingData.year.length > 0">
                                <z-select
                                    :data="filterUpcomingData.year"
                                    :allow-clear="false"
                                    ref="filterUpcomingYear"
                                    name="year"
                                    @change="onChangeFilter"
                                    placeholder="Select year"
                                    color-theme="investors"
                                />
                            </div>
                        </div>
                    </div>
                    <z-not-found
                        v-if="isNotFound"
                        :title="text.notFound.title"
                        :text="text.notFound.text"
                    ></z-not-found>
                    <ul v-else class="events-list events-list--upcoming">
                        <li
                            v-for="(item, i) in buildFilterData()"
                            :key="i"
                            class="events-list__item"
                        >
                            <span class="events-list__category" v-if="item.category">{{ text.category[item.category] }}</span>
                            <div class="events-list__body">
                                <span class="events-list__name">{{ item.name }}</span>
                                <z-button
                                    v-if="item['date-calendar']"
                                    class="events-list__add-calendar"
                                    kind="secondary"
                                    size="xs"
                                    @click="saveIcalFile(item)"
                                >{{text.addToCalendar}}</z-button>
                                <div class="events-list__details">
                                    <span class="events-list__date" v-if="item.date">{{ item.date }}</span>
                                    <span class="events-list__location" v-if="item.location">
                                        <vue-raw :raw="buildLocation(item.location)"/>
                                    </span>
                                </div>
                                <div class="events-list__links">
                                    <z-filelist v-if="item.files" horizontal>
                                        <z-filelist-item
                                            v-if="Array.isArray(item.files)"
                                            v-for="file in item.files"
                                            :key="file.ID"
                                            :icon="file.CONTENT_TYPE === 'application/pdf' ? 'file/pdf' : ''"
                                        >
                                            <z-link :href="file.SRC" theme="black">{{file.DESCRIPTION}}</z-link>
                                        </z-filelist-item>
                                        <z-filelist-item
                                            v-else
                                            :icon="item.files.CONTENT_TYPE === 'application/pdf' ? 'file/pdf' : ''"
                                        >
                                            <z-link :href="item.files.SRC" theme="black">{{item.files.DESCRIPTION}}</z-link>
                                        </z-filelist-item>
                                    </z-filelist>
                                    <z-filelist v-if="item['links-value']" horizontal>
                                        <z-filelist-item
                                            v-for="(link, i) in item['links-value']"
                                            :key="i"
                                            :icon="item['links-description'][i]"
                                        >
                                            <z-link :href="link" theme="black">{{link}}</z-link>
                                        </z-filelist-item>
                                    </z-filelist>
                                </div>
                            </div>
                        </li>
                    </ul>
                </z-tabs-content>
                <z-tabs-content id="past">
                    <div class="events__filter">
                        <z-caption tag="h2"><z-icon name="calendar-solid" width="32" height="35"></z-icon> {{text.titleTab.past}}</z-caption>
                        <div class="row">
                            <div class="col-default-4 col-v-tablet-6 col-mobile-12">
                                <z-select
                                    :data="filterPastData.category"
                                    :allow-clear="false"
                                    ref="filterPastCategory"
                                    name="category"
                                    @change="onChangeFilter"
                                    placeholder="Select category"
                                    color-theme="investors"
                                />
                            </div>
                            <div class="col-default-4 col-v-tablet-6 col-mobile-12" v-show="filterPastData.year.length > 0">
                                <z-select
                                    :data="filterPastData.year"
                                    ref="filterPastYear"
                                    :allow-clear="false"
                                    name="year"
                                    @change="onChangeFilter"
                                    placeholder="Select year"
                                    color-theme="investors"
                                />
                            </div>
                        </div>
                    </div>
                    <z-not-found
                        v-if="isNotFound"
                        :title="text.notFound.title"
                        :text="text.notFound.text"
                    ></z-not-found>
                    <ul v-else class="events-list">
                        <li
                            v-for="(item, i) in buildFilterData()"
                            :key="i"
                            class="events-list__item"
                        >
                            <span class="events-list__category" v-if="item.category">{{ text.category[item.category] }}</span>
                            <div class="events-list__body">
                                <span class="events-list__name">{{ item.name }}</span>
                                <div class="events-list__details">
                                    <span class="events-list__date" v-if="item.date">{{ item.date }}</span>
                                    <span class="events-list__location" v-if="item.location">
                                        <vue-raw :raw="buildLocation(item.location)"/>
                                    </span>
                                </div>
                                <div class="events-list__links">
                                    <z-filelist v-if="item.files" horizontal>
                                        <z-filelist-item
                                            v-if="Array.isArray(item.files)"
                                            v-for="file in item.files"
                                            :key="file.ID"
                                            :icon="file.CONTENT_TYPE === 'application/pdf' ? 'file/pdf' : ''"
                                        >
                                            <z-link :href="file.SRC" theme="black">{{file.DESCRIPTION}}</z-link>
                                        </z-filelist-item>
                                        <z-filelist-item
                                            v-else
                                            :icon="item.files.CONTENT_TYPE === 'application/pdf' ? 'file/pdf' : ''"
                                        >
                                            <z-link :href="item.files.SRC" theme="black">{{item.files.DESCRIPTION}}</z-link>
                                        </z-filelist-item>
                                    </z-filelist>
                                    <z-filelist v-if="item['links-value']" horizontal>
                                        <z-filelist-item
                                            v-for="(link, i) in item['links-value']"
                                            :key="i"
                                            :icon="item['links-description'][i]"
                                        >
                                            <z-link :href="link" theme="black">{{link}}</z-link>
                                        </z-filelist-item>
                                    </z-filelist>
                                </div>
                            </div>
                        </li>
                    </ul>
                </z-tabs-content>
            </template>
        </z-tabs>

    </div>
</template>

<script>
import ical from 'ical-generator'
import { saveAs } from 'file-saver'
import { localize } from '@/utils/i18n'
import moment from 'moment'

export default {
    name: 'events-calendar',
    props: {
        data: {
            type: [Object, Array],
            default: () => {
                return null
            }
        }
    },
    data () {
        return {
            filter: {
                category: 'all',
                year: ''
            },
            arPast: [],
            arUpcoming: [],
            currentTab: 'tabs-upcoming',
            isNotFound: false,
            text: {
                category: {
                    all: localize({
                        'ru': 'Все',
                        'en': 'All'
                    }),
                    results: localize({
                        'ru': 'Результаты',
                        'en': 'Results'
                    }),
                    conferences: localize({
                        'ru': 'Конференции',
                        'en': 'Conferences'
                    }),
                    other: localize({
                        'ru': 'Прочее',
                        'en': 'Other'
                    })
                },
                titleTab: {
                    upcoming: localize({
                        'ru': 'Предстоящие cобытия',
                        'en': 'Upcoming events'
                    }),
                    past: localize({
                        'ru': 'Прошедшие cобытия',
                        'en': 'Past events'
                    })
                },
                addToCalendar: localize({
                    'ru': 'Добавить в календарь',
                    'en': 'Add to calendar'
                }),
                notFound: {
                    title: localize({
                        'ru': 'Событий не найдено',
                        'en': 'No events found'
                    }),
                    text: localize({
                        'ru': 'Попробуйте изменить фильтр',
                        'en': 'Try to change the filter'
                    })
                }
            },
            filterPastData: {
                category: [],
                year: []
            },
            filterUpcomingData: {
                category: [],
                year: []
            }
        }
    },
    watch: {
        currentTab (val) {
            this.$root.$bus.$emit('clear', 'category')
            this.$root.$bus.$emit('clear', 'year')
            this.filter.category = 'all'

            try {
                if (val === 'tabs-upcoming') {
                    this.filter.year = this.filterUpcomingData.year[0].id
                    const categoryOpt = this.filterUpcomingData.category.find(obj => obj.id === this.filter.category)
                    const yearOpt = this.filterUpcomingData.year.find(obj => obj.id === this.filter.year)
                    this.$refs.filterUpcomingCategory.changeSelected(categoryOpt)
                    this.$refs.filterUpcomingYear.changeSelected(yearOpt)
                } else {
                    this.filter.year = this.filterPastData.year[0].id
                    let categoryOpt = this.filterPastData.category.find(obj => obj.id === this.filter.category)
                    let yearOpt = this.filterPastData.year.find(obj => obj.id === this.filter.year)
                    this.$refs.filterPastCategory.changeSelected(categoryOpt)
                    this.$refs.filterPastYear.changeSelected(yearOpt)
                }

                this.buildFilterData()
            } catch (error) {
                console.log(error)
            }
        }
    },
    methods: {
        isUrl (string) {
            try {
                return Boolean(new URL(string))
            } catch (e) {
                return false
            }
        },
        buildLocation (location) {
            let res = `<span>${location}</span>`

            if (this.isUrl(location)) {
                res = `<z-link href="${location}" size="s">Online</z-link>`
            }

            return res
        },
        getSortedItems (arr) {
            return arr.sort((a, b) => a.sort > b.sort)
        },
        getCurrentTab (val) {
            this.currentTab = val
        },
        buildData () {
            this.data.forEach(el => {
                let today = moment().unix()
                let date1 = el['date-calendar'][0]
                let date2 = el['date-calendar'].length > 1 ? el['date-calendar'][1] : null

                if (date2) {
                    if (today > date2) {
                        let categoryOpt = this.filterPastData.category.find(obj => obj.id === el.category)
                        if (categoryOpt) {
                            categoryOpt.disabled = false
                        }
                        return this.arPast.push(el)
                    }
                } else {
                    if (today > date1) {
                        let categoryOpt = this.filterPastData.category.find(obj => obj.id === el.category)
                        if (categoryOpt) {
                            categoryOpt.disabled = false
                        }
                        return this.arPast.push(el)
                    }
                }

                let categoryOpt = this.filterUpcomingData.category.find(obj => obj.id === el.category)
                if (categoryOpt) {
                    categoryOpt.disabled = false
                }
                this.arUpcoming.push(el)
            })

            this.arPast = this.groupByYear(this.arPast)
            this.arUpcoming = this.groupByYear(this.arUpcoming)

            this.filterUpcomingData.year = Object.keys(this.arUpcoming)
                .sort((a, b) => b - a)
                .map(year => {
                    return {
                        text: year,
                        id: year
                    }
                })
            this.filterPastData.year = Object.keys(this.arPast)
                .sort((a, b) => b - a)
                .map(year => {
                    return {
                        text: year,
                        id: year
                    }
                })

            this.filter.year = this.filterUpcomingData.year[0].id
            let yearOpt = this.filterUpcomingData.year.find(obj => obj.id === this.filter.year)
            yearOpt.selected = true
        },
        onChangeFilter (obj) {
            this.filter[obj.name] = obj.value
            this.buildFilterData()
        },
        buildFilterData () {
            let arResult = []

            if (this.filter.year.length > 0 && this.filter.category.length > 0) {
                if (this.currentTab === 'tabs-upcoming') {
                    arResult = this.arUpcoming[this.filter.year].filter(obj => {
                        if (this.filter.category === 'all') return true
                        return obj.category === this.filter.category
                    })
                } else {
                    arResult = this.arPast[this.filter.year].filter(obj => {
                        if (this.filter.category === 'all') return true
                        return obj.category === this.filter.category
                    })
                }
            }

            if (arResult.length > 0) {
                this.isNotFound = false
            } else {
                this.isNotFound = true
            }

            return this.getSortedItems(arResult)
        },
        groupByYear (arr) {
            return arr.reduce(function (r, a) {
                r[a.year] = r[a.year] || []
                r[a.year].push(a)
                return r
            }, Object.create(null))
        },
        saveIcalFile (data) {
            const blob = ical({
                domain: 'belugagroup.ru',
                prodId: `//Beluga//Events//${this.$root.lang.toUpperCase()}`,
                events: [
                    {
                        start: moment.unix(data['date-calendar'][0]),
                        end: data['date-calendar'].length > 1 ? moment.unix(data['date-calendar'][1]) : moment.unix(data['date-calendar'][0]).add(1, 'hour'),
                        summary: data.name,
                        description: data.date,
                        location: data.location
                    }
                ]
            }).toBlob()

            saveAs(blob, `${data.name.replaceAll(' ', '_')}.ics`)
        }
    },
    created () {
        this.filterUpcomingData.category = [
            {
                id: 'all',
                text: this.text.category.all,
                selected: true,
                disabled: false
            },
            {
                id: 'results',
                text: this.text.category.results,
                disabled: true
            },
            {
                id: 'conferences',
                text: this.text.category.conferences,
                disabled: true
            },
            {
                id: 'other',
                text: this.text.category.other,
                disabled: true
            }
        ]

        this.filterPastData.category = [
            {
                id: 'all',
                text: this.text.category.all,
                selected: true,
                disabled: false
            },
            {
                id: 'results',
                text: this.text.category.results,
                disabled: true
            },
            {
                id: 'conferences',
                text: this.text.category.conferences,
                disabled: true
            },
            {
                id: 'other',
                text: this.text.category.other,
                disabled: true
            }
        ]

        if (this.data) {
            try {
                this.buildData()
            } catch (error) {
                console.log(error)
            }
        } else {
            this.isNotFound = true
        }
    }
}
</script>

<style lang="scss">
    .events {
        &__filter {
            margin-top: 16px;
            margin-bottom: 60px;
            background-color: #F4F4F4;
            padding: 32px 40px;

            .z-caption {
                margin-bottom: 1.2em;

                &__text {
                    display: flex;
                    align-items: center;
                }

                .z-icon {
                    margin-right: 20px;
                    margin-top: -6px;
                }
            }
        }
    }

    .events-list {
        &__item {
            + {
                .events-list__item {
                    margin-top: 10px;
                }
            }

            padding-bottom: 40px;
            margin-bottom: 40px;
            border-bottom: 1px solid #F4F4F4;
            font-weight: 300;
            font-size: 18px;
            line-height: 1.35;
            font-family: 'Fact';
        }

        &__category {
            font-size: 15px;
            line-height: 1.2;
            display: inline-block;
            padding: 1px 5px;
            background-color: #175789;
            border-radius: 5px;
            color: #fff;
            font-weight: 400;
            margin-bottom: 14px;
        }

        &__add-calendar {
            position: absolute;
            right: 0;
            top: -0.4em;
        }

        &__body {
            position: relative;
        }

        &__details {
            margin-top: 6px;
            display: flex;
            font-size: 15px;
            line-height: 1.2;
        }

        &__date {
            font-size: 15px;
            line-height: 1.2;
        }

        &__location {
            margin-left: 20px;
            padding-left: 20px;
            border-left: 1px solid #CDCAC8;
        }

        &__name {
            font-size: 26px;
            font-family: 'Future';
            line-height: 1.15;
            font-weight: bold;
            display: block;
        }
    }

    .events-list--upcoming {
        .events-list__name {
            padding-right: 220px;
        }
    }

    @include breakpoint(mobile) {
        .events__filter {
            margin-top: 0;
            margin-bottom: 30px;
            padding: 16px;
        }

        .events__filter .z-caption {
            font-size: 16px;
        }

        .events-list--upcoming .events-list__name {
            padding-right: 0;
        }

        .events-list__add-calendar {
            position: relative;
            top: 0;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .events-list__category {
            font-size: 13px;
        }

        .events-list__name {
            font-size: 22px;
        }

        .events-list__item {
            padding-bottom: 24px;
            margin-bottom: 24px;
        }
    }
</style>
