<template>
    <div class="import-brands-portfolio">
        <div class="import-brands-portfolio__desktop">
            <div class="import-brands-portfolio__title">
                <a class="import-brands-portfolio__link" :href="data.cognac.link">{{data.cognac.title}}</a>
            </div>
            <div class="import-brands-portfolio__title">
                <a class="import-brands-portfolio__link" :href="data.whiskey.link">{{data.whiskey.title}}</a>
            </div>
            <div class="import-brands-portfolio__title">
                <a class="import-brands-portfolio__link" :href="data.rum.link">{{data.rum.title}}</a>
            </div>
            <div class="import-brands-portfolio__title">
                <a class="import-brands-portfolio__link" :href="data.wine.link">{{data.wine.title}}</a>
            </div>
            <div class="import-brands-portfolio__title">
                <a class="import-brands-portfolio__link" :href="data.other.link">
                    {{data.other.title}}
                </a>
                <p class="import-brands-portfolio__subtitle">{{ data.other.subtitle }}</p>
            </div>

            <div class="import-brands-portfolio__title--border"></div>

            <div class="import-brands-portfolio__category import-brands-portfolio__category--left import-brands-portfolio__category--super">
                <span>{{ category.superPremium }}</span>
            </div>
                <z-image
                    class="import-brands-portfolio__item"
                    :src="data.cognac.img.superPremium"
                    :alt="`${category.superPremium} ${data.cognac.title}`"
                />
                <z-image
                    class="import-brands-portfolio__item"
                    :src="data.whiskey.img.superPremium"
                    :alt="`${category.superPremium} ${data.whiskey.title}`"
                />
                <z-image
                    class="import-brands-portfolio__item"
                    :src="data.rum.img.superPremium"
                    :alt="`${category.superPremium} ${data.rum.title}`"
                />
                <z-image
                    class="import-brands-portfolio__item"
                    :src="data.wine.img.superPremium"
                    :alt="`${category.superPremium} ${data.wine.title}`"
                />
                <z-image
                    class="import-brands-portfolio__item"
                    :src="data.other.img.superPremium"
                    :alt="`${category.superPremium} ${data.other.title}`"
                />

            <div class="import-brands-portfolio__shelf">
                <z-image
                    class="import-brands-portfolio__shelf--img"
                    src="/images/investors/investment-case/shelf.png"
                    alt="shelf"
                />
            </div>

            <div class="import-brands-portfolio__category import-brands-portfolio__category--left import-brands-portfolio__category--premium">
                <span>{{ category.premium }}</span>
            </div>
            <z-image
                    class="import-brands-portfolio__item"
                    :src="data.cognac.img.premium"
                    :alt="`${category.premium} ${data.cognac.title}`"
                />
                <z-image
                    class="import-brands-portfolio__item"
                    :src="data.whiskey.img.premium"
                    :alt="`${category.premium} ${data.whiskey.title}`"
                />
                <z-image
                    class="import-brands-portfolio__item"
                    :src="data.rum.img.premium"
                    :alt="`${category.premium} ${data.rum.title}`"
                />
                <z-image
                    class="import-brands-portfolio__item"
                    :src="data.wine.img.premium"
                    :alt="`${category.premium} ${data.wine.title}`"
                />
                <z-image
                    class="import-brands-portfolio__item"
                    :src="data.other.img.premium"
                    :alt="`${category.premium} ${data.other.title}`"
                />

            <div class="import-brands-portfolio__shelf">
                <z-image
                    class="import-brands-portfolio__shelf--img"
                    src="/images/investors/investment-case/shelf.png"
                    alt="shelf"
                />
            </div>

            <div class="import-brands-portfolio__category import-brands-portfolio__category--left import-brands-portfolio__category--middle">
                <span>{{ category.middle }}</span>
            </div>
            <z-image
                    class="import-brands-portfolio__item"
                    :src="data.cognac.img.middle"
                    :alt="`${category.middle} ${data.cognac.title}`"
                />
                <z-image
                    class="import-brands-portfolio__item"
                    :src="data.whiskey.img.middle"
                    :alt="`${category.middle} ${data.whiskey.title}`"
                />
                <z-image
                    class="import-brands-portfolio__item"
                    :src="data.rum.img.middle"
                    :alt="`${category.middle} ${data.rum.title}`"
                />
                <z-image
                    class="import-brands-portfolio__item"
                    :src="data.wine.img.middle"
                    :alt="`${category.middle} ${data.wine.title}`"
                />
                <z-image
                    class="import-brands-portfolio__item"
                    :src="data.other.img.middle"
                    :alt="`${category.middle} ${data.other.title}`"
                />

            <div class="import-brands-portfolio__shelf">
                <z-image
                    class="import-brands-portfolio__shelf--img"
                    src="/images/investors/investment-case/shelf.png"
                    alt="shelf"
                />
            </div>

            <!-- <div class="import-brands-portfolio__category import-brands-portfolio__category--left import-brands-portfolio__category--econom">
                <span>{{ category.econom }}</span>
            </div>
            <z-image
                    class="import-brands-portfolio__item"
                    :src="data.cognac.img.econom"
                    :alt="`${category.econom} ${data.cognac.title}`"
                />
                <z-image
                    class="import-brands-portfolio__item"
                    :src="data.whiskey.img.econom"
                    :alt="`${category.econom} ${data.whiskey.title}`"
                />
                <z-image
                    class="import-brands-portfolio__item"
                    :src="data.rum.img.econom"
                    :alt="`${category.econom} ${data.rum.title}`"
                />
                <z-image
                    class="import-brands-portfolio__item"
                    :src="data.wine.img.econom"
                    :alt="`${category.econom} ${data.wine.title}`"
                />
                <z-image
                    class="import-brands-portfolio__item"
                    :src="data.other.img.econom"
                    :alt="`${category.econom} ${data.other.title}`"
                />

            <div class="import-brands-portfolio__shelf">
                <z-image
                    class="import-brands-portfolio__shelf--img"
                    src="/images/investors/investment-case/shelf.png"
                    alt="shelf"
                />
            </div> -->
        </div>

        <div class="import-brands-portfolio__mobile">
            <z-accordion initial="first" multiple>
                <z-accordion-item
                    class="import-brands-portfolio__accordion import-brands-portfolio__accordion--super"
                    id="import-super-premium"
                >
                    <template v-slot:header>
                        <span>{{ category.superPremium }}</span>
                    </template>

                    <template v-slot:body>
                        <ul class="import-brands-portfolio__accordion--list">
                            <li
                                v-for="(item, index) in data"
                                :key="index"
                                class="import-brands-portfolio__accordion--item"
                            >
                                <a
                                    v-if="item.img.superPremium"
                                    class="import-brands-portfolio__link"
                                    :href="item.link"
                                >
                                    {{item.title}}
                                </a>
                                <p v-if="item.subtitle" class="import-brands-portfolio__subtitle">
                                    {{ item.subtitle }}
                                </p>
                                <z-image
                                    v-if="item.img.superPremium"
                                    :src="item.img.superPremium"
                                    :alt="`${category.superPremium} ${item.title}`"
                                />
                            </li>
                        </ul>
                    </template>
                </z-accordion-item>

                <z-accordion-item
                    class="import-brands-portfolio__accordion import-brands-portfolio__accordion--premium"
                    id="import-premium"
                >
                    <template v-slot:header>
                        <span>{{ category.premium }}</span>
                    </template>

                    <template v-slot:body>
                        <ul class="import-brands-portfolio__accordion--list">
                            <li
                                v-for="(item, index) in data"
                                :key="index"
                                class="import-brands-portfolio__accordion--item"
                            >
                                <a
                                v-if="item.img.premium"
                                class="import-brands-portfolio__link"
                                :href="item.link"
                                >
                                    {{item.title}}
                                </a>
                                <p v-if="item.subtitle" class="import-brands-portfolio__subtitle">
                                    {{ item.subtitle }}
                                </p>
                                <z-image
                                    v-if="item.img.premium"
                                    :src="item.img.premium"
                                    :alt="`${category.premium} ${item.title}`"
                                />
                            </li>
                        </ul>
                    </template>
                </z-accordion-item>

                <z-accordion-item
                    class="import-brands-portfolio__accordion import-brands-portfolio__accordion--middle"
                    id="import-middle"
                >
                    <template v-slot:header>
                        <span>{{ category.middle }}</span>
                    </template>

                    <template v-slot:body>
                        <ul class="import-brands-portfolio__accordion--list">
                            <li
                                v-for="(item, index) in data"
                                :key="index"
                                class="import-brands-portfolio__accordion--item"
                            >
                                <a
                                v-if="item.img.middle"
                                class="import-brands-portfolio__link"
                                :href="item.link"
                                >
                                    {{item.title}}
                                </a>
                                <p v-if="item.subtitle" class="import-brands-portfolio__subtitle">
                                    {{ item.subtitle }}
                                </p>
                                <z-image
                                    v-if="item.img.middle"
                                    :src="item.img.middle"
                                    :alt="`${category.middle} ${item.title}`"
                                />
                            </li>
                        </ul>
                    </template>
                </z-accordion-item>

                <!-- <z-accordion-item
                    class="import-brands-portfolio__accordion import-brands-portfolio__accordion--econom"
                    id="import-econom"
                >
                    <template v-slot:header>
                        <span>{{ category.econom }}</span>
                    </template>

                    <template v-slot:body>
                        <ul class="import-brands-portfolio__accordion--list">
                            <li
                                v-for="(item, index) in data"
                                :key="index"
                                class="import-brands-portfolio__accordion--item"
                            >
                                <a
                                v-if="item.img.econom"
                                class="import-brands-portfolio__link"
                                :href="item.link"
                                >
                                    {{item.title}}
                                </a>
                                <p v-if="item.subtitle" class="import-brands-portfolio__subtitle">
                                    {{ item.subtitle }}
                                </p>
                                <z-image
                                    v-if="item.img.econom"
                                    :src="item.img.econom"
                                    :alt="`${category.econom} ${item.title}`"
                                />
                            </li>
                        </ul>
                    </template>
                </z-accordion-item> -->
            </z-accordion>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'import-brands-portfolio',
    data () {
        return {
            category: {
                superPremium: localize({
                    ru: 'Супер-премиум',
                    en: 'Super premium'
                }),
                premium: localize({
                    ru: 'Премиум',
                    en: 'Premium'
                }),
                middle: localize({
                    ru: 'Средний',
                    en: 'Middle'
                }),
                econom: localize({
                    ru: 'Эконом',
                    en: 'Mainstream'
                })
            },
            data: {
                cognac: {
                    title: localize({
                        ru: 'Коньяк / Бренди',
                        en: 'Cognac / Brandy'
                    }),
                    subtitle: '',
                    img: {
                        superPremium: '/images/investors/investment-case/import-super-premium-cognac.png',
                        premium: '/images/investors/investment-case/import-premium-cognac.png',
                        middle: '/images/investors/investment-case/import-middle-cognac.png',
                        econom: '/images/investors/investment-case/import-mainstream-cognac.png'
                    },
                    link: localize({
                        ru: '/brands/russian-brandy/',
                        en: '/en/brands/brandy/'
                    })
                },

                whiskey: {
                    title: localize({
                        ru: 'Виски',
                        en: 'Whiskey'
                    }),
                    subtitle: '',
                    img: {
                        superPremium: '/images/investors/investment-case/import-super-premium-whiskey.png',
                        premium: '/images/investors/investment-case/import-premium-whiskey.png',
                        middle: '/images/investors/investment-case/import-middle-whiskey.png',
                        econom: '/images/investors/investment-case/import-mainstream-whiskey.png'
                    },
                    link: localize({
                        ru: '/brands/whisky/',
                        en: '/en/brands/brandy/'
                    })
                },

                rum: {
                    title: localize({
                        ru: 'Ром',
                        en: 'Rum'
                    }),
                    subtitle: '',
                    img: {
                        superPremium: '/images/investors/investment-case/import-super-premium-rum.png',
                        premium: '/images/investors/investment-case/import-premium-rum.png',
                        middle: '/images/investors/investment-case/import-middle-rum.png',
                        econom: '/images/investors/investment-case/import-mainstream-rum.png'
                    },
                    link: localize({
                        ru: '/brands/rum/',
                        en: '/en/brands/rum/'
                    })
                },

                wine: {
                    title: localize({
                        ru: 'Вино',
                        en: 'Wines'
                    }),
                    subtitle: '',
                    img: {
                        superPremium: '/images/investors/investment-case/import-super-premium-wine.png',
                        premium: '/images/investors/investment-case/import-premium-wine.png',
                        middle: '/images/investors/investment-case/import-middle-wine.png',
                        econom: '/images/investors/investment-case/import-mainstream-wine.png'
                    },
                    link: localize({
                        ru: '/brands/wine/',
                        en: '/en/brands/wine/'
                    })

                },

                other: {
                    title: localize({
                        ru: 'Прочие',
                        en: 'Other spirits'
                    }),
                    subtitle: localize({
                        ru: '(текила, джин, и др.)',
                        en: '(tequila, gin, etc.) '
                    }),
                    img: {
                        superPremium: '/images/investors/investment-case/import-super-premium-other.png',
                        premium: '/images/investors/investment-case/import-premium-other.png',
                        middle: '/images/investors/investment-case/import-middle-other.png',
                        econom: '/images/investors/investment-case/import-mainstream-other.png'
                    },
                    link: localize({
                        ru: '/brands/',
                        en: '/en/brands/'
                    })
                }
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
