function targetYandexHandler (target) {
    if (typeof yaCounter87009216 === 'undefined') return
    // eslint-disable-next-line
    yaCounter87009216.reachGoal(target)

    return true
}

function targetGoogleHandler (category, action, label) {
    if (typeof dataLayer === 'undefined') return
    // eslint-disable-next-line
    dataLayer.push({
        event: 'event-to-ga',
        eventCategory: category,
        eventAction: action,
        eventLabel: label
    })
}

function targetAll (category, action, label) {
    targetYandexHandler(label)
    targetGoogleHandler(
        category,
        action,
        label
    )
}

let sendAllData = function (event) {
    if (event.target && event.target.classList.contains('js-analytics')) {
        var item = event.target
        var label = item.dataset.analyticsLabel
        var category = item.dataset.analyticsCategory
        var action = item.dataset.analyticsAction

        if (label && category && action) {
            targetAll(category, action, label)
        }
    }
}

let sendFormData = function (event) {
    event.preventDefault()
    if (event.target && event.target.localName === 'form' && event.target.classList.contains('js-analytics')) {
        var form = event.target
        var label = form.dataset.analyticsLabel
        var category = form.dataset.analyticsCategory
        var action = form.dataset.analyticsAction

        if (label && category && action) {
            targetAll(category, action, label, {
                hitCallback: function () {
                    form.submit()
                }
            })
        }
    }
}

function addAnalytics () {
    document.addEventListener('click', sendAllData, false)
    document.addEventListener('submit', sendFormData, false)
}

function removeAnalytics () {
    document.removeEventListener('click', sendAllData, false)
    document.removeEventListener('submit', sendFormData, false)
}

export { targetYandexHandler, targetGoogleHandler, targetAll, addAnalytics, removeAnalytics }
