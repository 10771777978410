<template>
    <transition name="fade">
        <div
            v-if="isAgePopupVisible"
            class="age-popup"
            tabindex="-1"
            id="age-popup">
            <div class="age-popup__modal">
                <div class="age-popup__modal-content">
                    <h3
                        class="age-popup__title"
                        v-html="text.title"></h3>
                    <p
                        class="age-popup__message"
                        v-html="text.message"></p>
                    <div class="age-popup__age">18+</div>
                    <div class="btn-set age-popup__actions">
                        <z-button
                            kind="primary"
                            type="submit"
                            @click="handleAccept">
                            {{ text.button }}
                        </z-button>
                    </div>
                </div>
            </div>
            <div class="age-popup__overlay"></div>
        </div>
    </transition>
</template>

<script>
import { localize } from '@/utils/i18n'
const cookie = require('cookie_js')

export default {
    name: 'age-popup',
    data () {
        return {
            text: {
                title: localize({
                    ru: 'Возрастное ограничение',
                    en: 'Age limit'
                }),
                message: localize({
                    ru: 'Информация на сайте предназначена для посетителей старше&nbsp;18&nbsp;лет. <br>Для доступа к ней подтвердите, пожалуйста, своё совершеннолетие.',
                    en: 'This information is for people above&nbsp;18. <br>Please confirm your majority.'
                }),
                button: localize({
                    ru: 'Подтвердить',
                    en: 'Confirm'
                })
            },
            isAgePopupVisible: false,
            isAgeConfirmed: false
        }
    },
    mounted () {
        this.checkCookies()
    },
    methods: {
        openPopup () {
            this.isAgePopupVisible = true
            this.disableScroll()
        },
        closePopup () {
            this.isAgePopupVisible = false
            this.enableScroll()
        },
        handleAccept (e) {
            this.saveToCookies()
            this.closePopup()
        },
        checkCookies () {
            this.isAgeConfirmed = cookie.get('check_18') === '18'
            if (this.isAgeConfirmed) {
                return
            }
            this.openPopup()
        },
        saveToCookies () {
            cookie.set(
                {
                    check_18: '18'
                },
                {
                    domain: location.hostname === 'localhost' ? '' : location.host,
                    path: '/',
                    expires: 31536000
                }
            )
        },
        disableScroll () {
            document.body.style.position = 'fixed'
            document.body.style.top = `-${window.scrollY}px`
        },
        enableScroll () {
            document.body.style.position = ''
            document.body.style.top = ''
            window.scroll(0, window.scrollY)
        }
    }
}
</script>

<style lang="scss">
.age-popup {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    position: fixed;
    outline: none !important;
    overflow: hidden auto;

    &__overlay {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: fixed;
        background: #000;
        opacity: 0.5;
    }

    &__modal {
        background-color: #97d8eb;
        padding: 20px 20px 40px;
        max-width: 720px;
        position: relative;
        z-index: 60;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);

        @media (max-width: 767px) {
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: none;
            max-width: 100%;
            min-height: 690px;
            height: 100vh;
            top: 0;
            transform: none;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            background-color: #97d8eb;
            background-image: url('/local/templates/main/css/../img/logo-deep-blue.png');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 85% auto;
            bottom: 20px;
            margin-left: 0;
            transform: translate(-50%, 0);
            width: 182px;
            height: 38px;
        }
    }

    &__modal-content {
        border: 1px solid #124066;
        position: relative;
        padding: 50px 50px 60px;

        @media (max-width: 767px) {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    &__title {
        font-family: 'Liberty';
        font-size: 36px;
        line-height: 1.2;
        text-transform: none;
        margin: 0 0 30px 0;
        text-align: center;
        color: #fff;
    }

    &__message {
        font-family: 'Fact';
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        color: #333;
    }
    &__age {
        font-family: 'Steelfish';
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 64px;
        color: #fff;
        text-align: center;
        height: 160px;

        @media (max-width: 767px) {
            height: 25vh;
            min-height: 130px;
        }
    }
    &__actions {
        display: flex;
        justify-content: center;

        .z-button {
            background-color: unset !important;
            font-family: 'Liberty';
            border: 1px solid #124066;
            color: #124066;
            transition: color 0.25s ease-in-out, border-color 0.25s ease-in-out,
                opacity 0.25s ease-in-out;
            text-transform: uppercase !important;
            box-shadow: none !important;

            &:hover,
            &:focus {
                color: #fff;
                border-color: #fff;
            }

            &:active {
                opacity: 0.8;
                color: #fff;
                border-color: #fff;
            }
        }
    }
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.25s ease-in-out;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
