/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'press-release': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.25 2A.75.75 0 016 1.25h15.75V20a.75.75 0 01-1.5 0V2.75H6A.75.75 0 015.25 2zm-3 2.009h16.5v19.504H2.25V4.009zm1.5 1.5v16.504h13.5V5.509H3.75zM6 9.25a.75.75 0 000 1.5h9a.75.75 0 000-1.5H6zm0 4a.75.75 0 000 1.5h9a.75.75 0 000-1.5H6zM5.25 18a.75.75 0 01.75-.75h5a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75z" _fill="#000"/>'
  }
})
