<template>
    <div class="contacts-form-wrapper">
        <z-button
            kind="secondary"
            inverted
            @click="openModal"
        >
            {{ text.writeToUs }}
        </z-button>
        <div class="contacts-form-modal" v-if="isModalOpened">
            <div class="contacts-form-modal__content">
                <button class="contacts-form-modal__close-btn" @click="closeModal">
                    <z-icon
                        name="close"
                        width="24"
                        height="24"
                    />
                </button>
                <form
                    id="contacts-form"
                    class="contacts-form"
                    @submit.prevent="submit"
                >
                    <z-caption
                        tag="h3"
                        class="contacts-form__caption"
                        uppercase
                    >
                        <span v-html="text.caption"></span>
                    </z-caption>
                    <div class="contacts-form__field">
                        <z-input
                            required
                            name="name"
                            @input="onChange"
                            :placeholder="text.placeholders.name"
                            color-theme="investors"
                        />
                    </div>
                    <div class="contacts-form__field">
                        <z-textarea
                            required
                            name="message"
                            @input="onChange"
                            :placeholder="text.placeholders.message"
                            class="contacts-form__textarea"
                            color-theme="investors"
                        />
                    </div>

                    <div class="contacts-form__field">
                        <vue-raw class="contacts-form__agreement" :raw="text.agreement" />
                    </div>

                    <z-button
                        type="submit"
                        :disabled="!isValid"
                    >{{ text.send }}</z-button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import axios from 'axios'
// import { gRecaptcha } from '@/utils/mixin'
import { debounce } from 'throttle-debounce'
import { showNotyfications } from '@/utils/notifications'

export default {
    name: 'contacts-form',
    // mixins: [gRecaptcha],
    props: {
        siteid: String,
        sessid: String
    },
    data () {
        return {
            isModalOpened: false,
            formData: {
                name: '',
                message: '',
                site_id: this.siteid,
                sessid: this.sessid,
                form_submit: 'Y',
                event_type: 'investors'
            },
            text: {
                send: localize({
                    'ru': 'Отправить',
                    'en': 'Send'
                }),
                writeToUs: localize({
                    'ru': 'Написать нам',
                    'en': 'Write to us'
                }),
                caption: localize({
                    'ru': 'Будем рады вашим отзывами предложениям',
                    'en': 'We will be pleased to hear your reviews and suggestions'
                }),
                placeholders: {
                    name: localize({
                        'ru': 'ФИО',
                        'en': 'Last and first names'
                    }),
                    message: localize({
                        'ru': 'Сообщение',
                        'en': 'Message'
                    })
                },
                agreement: localize({
                    ru: 'Нажимая на кнопку «Отправить», вы даёте согласие на <z-link href="/privacy-policy/" target="_blank">обработку ваших персональных данных</z-link>.',
                    en: 'By submitting this form you consent <z-link href="/en/privacy-policy/" target="_blank">the processing of your personal data</z-link>'
                }),
                success: localize({
                    'ru': 'Сообщение отправлено',
                    'en': 'Message was sent'
                })
            }
        }
    },
    computed: {
        isValid () {
            return this.formData.name.length &&
                this.formData.message.length
        }
    },
    methods: {
        closeModal () {
            this.isModalOpened = false
        },
        openModal () {
            this.isModalOpened = true
        },
        onChange (data) {
            this.formData[data.name] = data.value
        },
        submit () {
            this.send(this, this.formData)
        },
        send: debounce(500, function (_this, params) {
            // grecaptcha.ready(function () {
            // grecaptcha.execute(_this.gKey, {
            //     action: 'contactsMap'
            // }).then(function (token) {
            //     params.captcha = token

            console.log(params)

            let formData = _this.buildFormData(params)
            console.log(formData)

            try {
                axios({
                    method: 'POST',
                    url: '/local/components/beluga/feedback.form/templates/investors-new/ajax.php',
                    data: formData
                })
                    .then((res) => {
                        if (res.data.status === 'error') {
                            showNotyfications(res.data.errors[0], { type: 'error' })
                        } else {
                            _this.clear()
                            showNotyfications(_this.text.success, { type: 'success' })
                        }
                        _this.isModalOpened = false

                        return res.data
                    })
            } catch (error) {
                console.log(error)
                _this.isModalOpened = false
            }
            // }, function (reason) {
            //     showNotyfications(reason, { type: 'error' })
            // })
            // })
        }),
        buildFormData (params) {
            let data = new FormData()

            for (let key in params) {
                data.append(key, params[key])
            }

            return data
        },
        clear () {
            for (let key in this.formData) {
                if (typeof this.formData[key] === 'boolean') {
                    this.formData[key] = false
                } else {
                    this.formData[key] = ''
                }
            }
        }
    }
}
</script>

<style lang="scss">
.contacts-form-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(153, 187, 222, 0.8);
    z-index: 20;
    text-align: center;
    overflow: auto;
    padding: 24px 16px;

    &:before {
        content: '';
        width: 0;
        height: 100%;
        vertical-align: middle;
        display: inline-block;
    }

    &__content {
        vertical-align: middle;
        display: inline-block;
        background: $token-colors-crisp-white;
        padding: $token-spacers-s;
        position: relative;
        max-width: 590px;
    }

    &__close-btn {
        position: absolute;
        top: 40px;
        right: 40px;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        z-index: 20;

        .z-icon {
            path {
                fill: $token-colors-gold;
            }
        }
    }
}

.contacts-form {
    border: 1px solid $token-colors-gold;
    padding: $token-spacers-s;
    @include padding-level(bottom, XL);
    @include padding-level(top, XL);
    position: relative;

    &__caption {
        @include padding-level(bottom, L);
        margin: 0 !important;
        max-width: 430px;
    }

    &__field {
        max-width: 390px;
        margin: 0 auto;
        @include margin-level(bottom, M);
    }

    &__textarea {
        height: 90px;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
        width: 125px;
        height: 50px;
        background-image: url(/images/logo/novabev.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50% 50%;
        background-color: $token-colors-crisp-white;
        padding: 0 18px;
        pointer-events: none;
    }

    &__agreement {
        color: $token-colors-grey-dark;
        font-size: 15px;
        text-align: left;
        display: block;

        .z-link {
            font-size: inherit;
        }
    }
}
</style>
