<template>
<div class="tradingview-widget">
    <div class="tradingview-widget__container">
        <div id="tradingview_18ee5"></div>
        <div class="tradingview-widget-copyright">
            <vue-raw :raw="text.copyright" />
        </div>
    </div>
</div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'TradingViewWidget',
    data () {
        return {
            apiUrl: 'https://s3.tradingview.com/tv.js',
            options: {
                'symbols': [
                    [
                        'MOEX:BELU|12M'
                    ]
                ],
                'chartOnly': false,
                'width': '100%',
                'height': '100%',
                'locale': this.$root.lang,
                'colorTheme': 'dark',
                'gridLineColor': 'rgba(42, 46, 57, 0)',
                'fontColor': '#c4b177',
                'isTransparent': true,
                'autosize': false,
                'showVolume': true,
                'scalePosition': 'right',
                'scaleMode': 'Percentage',
                'fontFamily': 'Trebuchet MS, sans-serif',
                'noTimeScale': false,
                'valuesTracking': '2',
                'chartType': 'area',
                'lineColor': '#c4b177',
                'bottomColor': 'rgba(201, 218, 248, 0.01)',
                'topColor': '#c4b177',
                'customer': 'belugagroupru',
                'container_id': 'tradingview_18ee5'
            },
            text: {
                copyright: localize({
                    ru: `<z-link href="https://ru.tradingview.com/symbols/MOEX-BELU/" rel="noopener" target="_blank">BELU Котировки акций</z-link> от TradingView`,
                    en: 'TradingView <z-link href="https://ru.tradingview.com/symbols/MOEX-BELU/" rel="noopener" target="_blank">BELU Share Price</z-link>'
                })
            },
            widget: null
        }
    },
    methods: {
        async init () {
            await this.addApiScript()
            this.initWidget()
        },
        initWidget () {
            // eslint-disable-next-line no-undef
            this.widget = new TradingView.MediumWidget(this.options)
        },
        async addApiScript () {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script')
                script.async = true
                script.src = this.apiUrl
                document.head.appendChild(script)
                script.onload = () => {
                    this.isApiLoaded = true
                    resolve()
                }
            })
        }
    },
    mounted () {
        this.init()
    }
}
</script>

<style lang="scss">
.tradingview-widget {
    padding-top: 55%;
    position: relative;
    min-height: 420px;

    @include breakpoint (v-tablet) {
        padding-top: 60%;
    }

    @include breakpoint (mobile) {
        margin-left: -8px;
        margin-right: -8px;
    }

    &__container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}
</style>
