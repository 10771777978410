/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-2': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 3.75a8.25 8.25 0 00-6.463 13.378 4.454 4.454 0 014.165-2.878h4.596a4.454 4.454 0 014.165 2.878A8.25 8.25 0 0012 3.75zm5.233 14.629a2.953 2.953 0 00-2.935-2.629H9.702a2.953 2.953 0 00-2.934 2.629A8.216 8.216 0 0012 20.25a8.216 8.216 0 005.233-1.871zM2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 6.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM8.25 9a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0z" _fill="#1D1D1B"/>'
  }
})
