<template>
    <div class="investors-page">
        <investors :news="news" />

        <why-invest class="investors-page__section" />

        <numbers class="investors-page__numbers investors-page__section">
            <template v-slot:key-figures>
                <key-figures>
                    <template v-slot:databook>
                        <slot name="databook"/>
                    </template>
                </key-figures>
            </template>
            <template v-slot:stock-data>
                <div class="investors-page__stock-data">
                    <z-caption tag="h2" weight="300" family="fact" color="white" uppercase>
                        <span v-html="text.stockData"></span>
                    </z-caption>
                    <slot name="stock-data"/>

                    <div class="investors-page__ticker-btn">
                        <z-button
                            inverted
                            kind="secondary"
                            tag="a"
                            class="js-analytics"
                            :href="`${prefixUrl}/investors/shareholder-center/share-price/`"
                            :data-analytics-label="`ClickKeyFiguresDetailInvestors${capitalizeLang}`"
                            data-analytics-action="click"
                            data-analytics-category="content"
                        >
                            {{text.btnMore}}
                        </z-button>
                    </div>
                </div>
            </template>
        </numbers>

        <financial-results>
            <template v-slot:reports>
                <slot name="reports"/>
            </template>
        </financial-results>

        <!--        <strategy class="investors-page__section">-->
        <!--            <template v-slot:strategy-presentaion>-->
        <!--                <slot name="strategy-presentaion"/>-->
        <!--            </template>-->
        <!--        </strategy>-->

        <div class="investors-page__section grey-light-bg">
            <div class="container">
                <z-caption tag="h2" border-decor weight="300" family="fact" color="black" uppercase>
                    <span v-html="text.latestNews"></span>
                </z-caption>
                <slot name="news-and-events" />
            </div>
        </div>
        <contacts />
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import {
    Investors,
    WhyInvest,
    Numbers,
    KeyFigures,
    FinancialResults,
    Strategy,
    GeographyMap,
    Contacts
} from './components'

export default {
    name: 'investors-page',
    props: {
        products: {
            type: Array,
            default: () => []
        },
        news: {
            type: Object
        }
    },
    computed: {
        capitalizeLang: function () {
            return this.$options.filters.capitalizeString(this.$root.lang)
        }
    },
    components: {
        Investors,
        WhyInvest,
        Numbers,
        KeyFigures,
        FinancialResults,
        Strategy,
        GeographyMap,
        Contacts
    },
    data () {
        return {
            text: {
                latestNews: localize({
                    ru: 'Последние новости и&nbsp;события',
                    en: 'Latest news & events'
                }),
                stories: localize({
                    ru: 'Наши истории',
                    en: 'Insights and stories'
                }),
                products: localize({
                    ru: 'Продукция',
                    en: 'Our products'
                }),
                investors: localize({
                    ru: 'Инвесторам',
                    en: 'Investors'
                }),
                stockData: localize({
                    ru: 'Котировка',
                    en: 'Stock Data'
                }),
                btnMore: localize({
                    'ru': 'Подробнее',
                    'en': 'More Info'
                })
            },
            prefixUrl: this.$root.lang === 'en' ? '/en' : ''
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
