/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'placeholder': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.5 10.662c0-1.981.702-3.506 1.79-4.538C8.386 5.085 9.921 4.5 11.662 4.5c1.74 0 3.277.585 4.372 1.624 1.088 1.032 1.79 2.557 1.79 4.538 0 1.33-.79 2.964-1.937 4.596-1.126 1.601-2.502 3.071-3.485 4.045a1.037 1.037 0 01-1.48 0c-.983-.974-2.36-2.444-3.485-4.045C6.29 13.626 5.5 11.991 5.5 10.662zM11.662 3c-2.077 0-3.997.702-5.403 2.035C4.846 6.375 4 8.305 4 10.662c0 1.823 1.034 3.786 2.21 5.459 1.198 1.704 2.644 3.245 3.657 4.248a2.537 2.537 0 003.59 0c1.013-1.003 2.459-2.544 3.657-4.248 1.176-1.673 2.21-3.636 2.21-5.459 0-2.357-.846-4.288-2.258-5.627C15.659 3.702 13.739 3 11.662 3zM9.82 10.662a1.842 1.842 0 113.684 0 1.842 1.842 0 01-3.684 0zm1.842-3.342a3.342 3.342 0 100 6.684 3.342 3.342 0 000-6.684z" _fill="#1D1D1B"/>'
  }
})
