<template>
    <div class="regulatory-disclosure disclosure-messages" v-if="data">
        <z-tabs @openedItemID="setCurrentTab">
            <template v-slot:label>
                <z-tabs-label id="messages">{{ text.titleTab.messages }}</z-tabs-label>
                <z-tabs-label id="affiliate-lists">{{ text.titleTab.affiliateLists }}</z-tabs-label>
                <z-tabs-label id="inside-information">{{ text.titleTab.insideInformation }}</z-tabs-label>
                <z-tabs-label id="quarterly-reports">{{ text.titleTab.quarterlyReports }}</z-tabs-label>
            </template>
            <template v-slot:content>
                <z-tabs-content id="messages">
                    <z-accordion initial="first">
                        <z-accordion-item
                            :id="'messages-' + item.year"
                            :key="key"
                            v-for="(item, key) in data.messages"
                        >
                            <template v-slot:header>
                                <span>{{ item.year }}</span>
                            </template>
                            <template v-slot:body>
                                <div class="row">
                                    <div
                                        class="col-default-6 col-mobile-12"
                                        :key="index"
                                        v-for="(message, index) in item.items">
                                        <z-filelist>
                                            <z-filelist-item>
                                                <span class="message-date">{{message.date}}</span>
                                                <z-link :href="message.link" theme="black">{{ message.name }}
                                                </z-link>
                                            </z-filelist-item>
                                        </z-filelist>
                                    </div>
                                </div>
                            </template>
                        </z-accordion-item>
                    </z-accordion>
                </z-tabs-content>
                <z-tabs-content id="affiliate-lists">
                    <regulatory-disclosure-sections :data="data['affiliate-lists']"></regulatory-disclosure-sections>
                </z-tabs-content>
                <z-tabs-content id="inside-information">
                    <regulatory-disclosure-sections :data="data['inside-information']"></regulatory-disclosure-sections>
                </z-tabs-content>
                <z-tabs-content id="quarterly-reports">
                    <regulatory-disclosure-sections :data="data['quarterly-reports']"></regulatory-disclosure-sections>
                </z-tabs-content>
            </template>
        </z-tabs>
    </div>
</template>

<script>
import {localize} from '@/utils/i18n'
import RegulatoryDisclosureSections from './components/RegulatoryDisclosureSections'

export default {
    name: 'regulatory-disclosure',
    components: {
        RegulatoryDisclosureSections
    },
    props: {
        data: {
            type: [Object, Array],
            default: () => {
                return null
            }
        }
    },
    data () {
        return {
            text: {
                titleTab: {
                    messages: localize({
                        'ru': 'Сообщения, подлежащие раскрытию',
                        'en': 'Communications to be disclosed'
                    }),
                    affiliateLists: localize({
                        'ru': 'Списки аффилированных лиц',
                        'en': 'The list of affiliated persons '
                    }),
                    insideInformation: localize({
                        'ru': 'Инсайдерская информация',
                        'en': 'Insider information'
                    }),
                    quarterlyReports: localize({
                        'ru': 'Отчеты эмитента',
                        'en': 'Quarterly reports'
                    })
                }
            },
            currentTab: 'messages'
        }
    },
    methods: {
        setCurrentTab (val) {
            this.currentTab = val
        }
    }
}
</script>

<style scoped>
.message-date {
    font-size: 0.8125em;
    color: #757678;
    padding-bottom: 10px;
}
</style>
