/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/docx': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.5.75a.75.75 0 00-.75.75v21.184c0 .414.336.75.75.75h16.579a.75.75 0 00.75-.75v-15.3a.75.75 0 00-.221-.531L14.687.968a.75.75 0 00-.53-.218H3.5zm.75 21.184V2.25h7.974v3.958c0 1.718 1.4 3.103 3.118 3.103h3.987v12.623H4.25zm9.474-15.726V2.25h.125l5.48 5.447v.114h-3.987a1.611 1.611 0 01-1.618-1.603zm-8.957 9v4.266h1.105c.52 0 .927-.16 1.222-.478.296-.32.445-.76.445-1.321v-.692c-.006-.55-.157-.984-.454-1.3-.295-.317-.692-.475-1.19-.475H4.767zm.862 3.55v-2.832h.313c.268.008.457.09.569.249.11.156.167.433.167.832v.741c-.006.371-.068.633-.185.785-.117.15-.32.226-.607.226H5.63zm5.427.3c.271-.317.407-.761.407-1.333v-.73c-.004-.584-.143-1.037-.416-1.36-.274-.323-.648-.486-1.122-.486-.483 0-.86.166-1.131.496-.272.328-.407.785-.407 1.37v.73c.004.565.142 1.004.416 1.319.273.312.65.468 1.128.468.48 0 .855-.158 1.125-.474zm-.621-2.907c.109.188.164.474.164.859v.741c-.002.363-.058.632-.167.806-.11.172-.277.258-.501.258-.233 0-.405-.088-.516-.264-.111-.178-.167-.453-.167-.826v-.76c.004-.368.06-.643.17-.822.11-.182.278-.273.507-.273.23 0 .4.094.51.281zm4.428 3.006c.251-.25.388-.618.41-1.104h-.862c-.011.289-.06.489-.146.6-.086.11-.242.164-.469.164-.23 0-.393-.079-.49-.237-.093-.158-.14-.45-.14-.873v-.806c.006-.369.057-.633.152-.793.096-.163.26-.244.49-.244.226 0 .382.058.466.173.086.113.132.32.14.621h.864c-.033-.486-.17-.86-.413-1.119-.24-.26-.592-.39-1.057-.39-.473 0-.842.16-1.108.478-.265.316-.398.768-.398 1.353v.718c0 .588.128 1.041.384 1.36.258.316.628.474 1.11.474.459 0 .815-.125 1.067-.375zm3.15-3.949l-.574 1.465-.574-1.465h-.988l1.005 2.115-1.025 2.15h.999l.583-1.49.586 1.49h.999l-1.026-2.15L19 15.208h-.987z" _fill="#1D1D1B"/>'
  }
})
