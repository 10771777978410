<template>
    <nav
        class="navigation-bottom"
        aria-label="Bottom navigation"
    >
        <div class="row">
            <div class="col-default-7 hide-me--mobile">
                <ul class="navigation-bottom__main">
                    <li
                        v-for="(item, index) in data.main"
                        :key="index"
                    >
                        <a
                            :href="item.link"
                            :title="item.name"
                            v-html="item.name"
                        ></a>
                    </li>
                </ul>
            </div>
            <div class="col-default-3 hide-me--mobile">
                <ul class="navigation-bottom__second">
                    <li
                        v-for="(item, index) in data.second"
                        :key="index"
                    >
                        <a
                            :href="item.link"
                            :title="item.name"
                            v-html="item.name"
                        ></a>
                    </li>
                </ul>
            </div>
            <div class="col-default-2 col-mobile-12">
                <div class="navigation-bottom__external-wrapper">
                    <ul class="navigation-bottom__external" v-if="data['external-links']">
                        <li
                            v-for="(item, index) in data['external-links']"
                            :key="index"
                        >
                            <a
                                :href="item.link"
                                :title="item.name"
                                v-html="item.name"
                                target="_blank"
                            ></a>
                        </li>
                    </ul>
                    <social
                        :links="social"
                        showCaption
                        showLabels
                        direction="vertical"
                    />
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'navigation-bottom',
    data () {
        return {
            data: this.$root.app.components.navigation.bottom,
            social: this.$root.app.components.navigation.bottom['social-media']
        }
    }
}

</script>

<style lang="scss" src="./index.scss"></style>
