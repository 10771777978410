/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'quote': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" d="M36.583 14.535l-.041-.28-.044.01a7.5 7.5 0 10-7.331 9.068c.371 0 .728-.056 1.083-.108-.115.387-.233.78-.423 1.133-.19.514-.487.959-.782 1.407-.247.485-.682.813-1.002 1.228-.335.404-.791.672-1.153 1.007-.355.35-.82.525-1.19.772-.387.221-.723.466-1.083.583l-.899.37-.79.328.807 3.232.995-.24c.318-.08.707-.173 1.148-.285.452-.083.934-.312 1.47-.52.529-.238 1.144-.397 1.714-.778.573-.364 1.235-.667 1.818-1.154.565-.501 1.247-.936 1.75-1.573.55-.597 1.093-1.223 1.515-1.937.488-.68.82-1.426 1.17-2.165a18.45 18.45 0 00.78-2.226c.395-1.47.572-2.867.64-4.062a20.755 20.755 0 00-.047-2.912c-.023-.3-.058-.6-.105-.898zm-18.333 0l-.042-.28-.043.01a7.5 7.5 0 10-7.332 9.068c.372 0 .729-.056 1.084-.108-.115.387-.234.78-.424 1.133-.19.514-.486.959-.781 1.407-.247.485-.682.813-1.002 1.228-.335.404-.792.672-1.153 1.007-.355.35-.82.525-1.19.772-.387.221-.724.466-1.084.583l-.898.37c-.502.205-.788.325-.788.325l.806 3.232.995-.24c.319-.08.707-.174 1.149-.285.451-.084.933-.312 1.47-.52.528-.239 1.143-.397 1.713-.779.573-.363 1.235-.666 1.818-1.153.565-.502 1.247-.937 1.75-1.573.55-.597 1.094-1.224 1.515-1.937.489-.68.82-1.427 1.17-2.165.317-.738.572-1.493.78-2.227.395-1.47.572-2.866.64-4.061a20.757 20.757 0 00-.046-2.912c-.025-.3-.06-.598-.107-.895z" _fill="#000"/>'
  }
})
