/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M20.53 6.47a.75.75 0 010 1.06l-11 11a.75.75 0 01-1.06 0l-5-5a.75.75 0 111.06-1.06L9 16.94 19.47 6.47a.75.75 0 011.06 0z" _fill="#1D1D1B"/>'
  }
})
