<template>
    <div class="z-pagination" v-if="data">
        <div class="z-pagination__wrapper">
            <z-button
                v-if="showMore && !(data.totalPages === data.currentPage)"
                :class="['z-pagination__button', `u-bottom-margin--${showMore.marginBottom}`]"
                kind="bordered"
                :size="showMore.size"
                @click="changePage(nextPage, 'load-more')"
            >
                {{ showMore.text ? showMore.text : 'Загрузить еще' }}
            </z-button>
            <nav aria-label="pagination navigation" class="z-pagination__nav" v-if="showNav">
                <ul class="z-pagination__list">
                    <li
                        v-if="showPrev"
                    >
                        <button
                            type="button"
                            aria-label="Предыдущая страница"
                            :class="[
                                'z-pagination__item',
                                'z-pagination__item-icon',
                                { 'is-disabled' : data.currentPage === 1 }
                            ]"
                            @click="changePage(prevPage)"
                            key="prev"
                        >
                            <z-icon
                                class="z-pagination__icon z-pagination__icon--prev"
                                name="long-arrow"
                                width="32"
                                height="24"
                                dir="down"
                            />
                        </button>
                    </li>
                    <template v-if="hasFirst">
                        <li>
                            <button
                                type="button"
                                aria-label="страница 1"
                                class="z-pagination__item z-pagination__item-background"
                                @click="changePage(1)"
                                key="first"
                            >
                                <span class="z-pagination__number z-pagination__number--single">
                                    1
                                </span>
                            </button>
                        </li>
                        <li>
                            <span
                                aria-label="devider"
                                class="z-pagination__divider"
                                v-if="isDividerShown('start')"
                            >
                            ...
                            </span>
                        </li>
                    </template>
                    <li
                        v-for="(item, index) in pages"
                        :key="`${item}-${index}`"
                    >
                        <button
                            type="button"
                            :class="[
                                'z-pagination__item',
                                'z-pagination__item-background',
                                { 'is-active' : item === data.currentPage }
                            ]"
                            @click="changePage(item)"
                        >
                            <span
                                :aria-label="`страница ${item}`"
                                :class="['z-pagination__number', getNumberClass(item)]"
                            >
                                {{ item }}
                            </span>
                        </button>
                    </li>
                    <template v-if="hasLast">
                        <li v-if="isDividerShown('end')">
                            <span
                                aria-label="devider"
                                class="z-pagination__divider"
                            >
                            ...
                            </span>
                        </li>
                        <li>
                            <button
                                type="button"
                                class="z-pagination__item z-pagination__item-background"
                                @click="changePage(data.totalPages)"
                                key="last"
                            >
                                <span
                                    :class="['z-pagination__number', getNumberClass(data.totalPages)]"
                                >
                                    {{ data.totalPages }}
                                </span>
                            </button>
                        </li>
                    </template>
                    <li v-if="showNext">
                        <button
                            type="button"
                            aria-label="Следующая страница"
                            :class="[
                                'z-pagination__item',
                                'z-pagination__item-icon',
                                { 'is-disabled' : data.currentPage === data.totalPages }
                            ]"
                            @click="changePage(nextPage)"
                            key="next"
                        >
                            <z-icon
                                class="z-pagination__icon"
                                name="long-arrow"
                                width="32"
                                height="24"
                                dir="up"
                            />
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
import { debounce } from 'throttle-debounce'
/* eslint-disable vue/no-side-effects-in-computed-properties */
export default {
    name: 'z-pagination',
    props: {
        data: Object,
        showNav: Boolean,
        showPrev: Boolean,
        showNext: Boolean,
        showMore: Object | String
    },
    data () {
        return {
            start: null,
            end: null
        }
    },
    computed: {
        pages () {
            let pages = []
            let start = (this.data.currentPage - this.data.pageRange) > 0 ? this.data.currentPage - this.data.pageRange : 1
            let end = (this.data.currentPage + this.data.pageRange) <= this.data.totalPages ? this.data.currentPage + this.data.pageRange : this.data.totalPages

            if (start === 1 && end + 1 <= this.data.totalPages && this.data.currentPage === 1) {
                end += 1
            }

            if (end === this.data.totalPages && start - 1 >= 1 && this.data.currentPage !== this.data.totalPages - 1) {
                start -= 1
            }

            for (let i = start; i <= end; i++) {
                pages.push(i)
            }

            this.start = start
            this.end = end

            return pages
        },
        rangeStart () {
            let start = (this.data.currentPage - this.data.pageRange) > 0 ? this.data.currentPage - this.data.pageRange : 1

            return (start > 0) ? start : 1
        },
        rangeEnd () {
            let end = this.data.currentPage + this.data.pageRange

            return (end < this.data.totalPages) ? end : this.data.totalPages
        },
        prevPage () {
            return this.data.currentPage - 1
        },
        nextPage () {
            return this.data.currentPage + 1
        },
        hasFirst () {
            if (!this.start) return false
            return this.start !== 1
        },
        hasLast () {
            if (!this.end) return false
            return this.end !== this.data.totalPages
        }
    },
    methods: {
        getNumberClass (item) {
            let number = 'z-pagination__number--single'

            if (String(item).length === 2) {
                number = 'z-pagination__number--double'
            }

            if (String(item).length >= 3) {
                number = 'z-pagination__number--triple'
            }

            return number
        },
        changePage: debounce(0, function (page, type = 'change-page') {
            if (page > 0 && page <= this.data.totalPages) {
                this.$emit('change-page', { page: page, type: type })
            }
        }),
        isDividerShown (type) {
            let result = false
            if (type === 'start') {
                result = (this.pages[0] - 1) !== 1
            }
            if (type === 'end') {
                result = (this.pages[this.pages.length - 1] + 1) !== this.data.totalPages
            }

            return result
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
