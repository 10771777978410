// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import { TippyComponent } from 'vue-tippy'
import App from './App'
import store from './store/'

import '@/utils/filter' // global
import '@/utils/modernizr-custom' // global
import '@/utils/polyfills/object-fit' // global
import '@/utils/correctExtensions' // global
import { VueAxios } from '@/utils/request'
import { AppDeviceEnquire } from '@/utils/mixin'
import { addAnalytics, removeAnalytics } from '@/utils/analytics'
// import { showNotyfications } from '@/utils/notifications'

/* Components */
import NavigationTop from './components/NavigationTop'
import AgePopup from './components/AgePopup'
import NavigationMain from './components/NavigationMain'
import NavigationMobile from './components/NavigationMobile'
import NavigationBottom from './components/NavigationBottom'
import Social from './components/Social'
import AnchorMenu from './components/AnchorMenu'
import SiteSelector from './components/SiteSelector'
import NavigationLeft from './components/NavigationLeft'
import PageTools from './components/PageTools'
import CookiePopup from './components/CookiePopup'
import Remind from './components/Remind'
import DateBlock from './components/DateBlock'
import ContactsMap from './components/ContactsMap'
import ScrollTopBtn from './components/ScrollTopBtn'
import EurolandIframe from './components/EurolandIframe'
import QualityComplaintForm from './components/QualityComplaintForm'
import InformationForm from './components/InformationForm'
import WelcomeForm from './components/WelcomeForm'
import SubscriptionForm from './components/SubscriptionForm'
import NewsFilter from './components/NewsFilter'
import SelfBrandsPortfolio from './components/SelfBrandsPortfolio'
import ImportBrandsPortfolio from './components/ImportBrandsPortfolio'
import PageChanger from './components/PageChanger'

/* Components Views */
import InvestorsPage from './views/InvestorsPage'
import DetailPage from './views/DetailPage'
import EventsCalendar from './views/EventsCalendar'
import GovernanceCard from './views/GovernanceCard'
import ContactsForm from './views/ContactsForm'
import StockData from './views/StockData'
import NewsEvents from './views/NewsEvents'
import LastEvent from './views/LastEvent'
import RegulatoryDisclosure from './views/RegulatoryDisclosure'
import TradingViewWidget from './views/TradingViewWidget'
import TradingViewTicker from './views/TradingViewTicker'
import TradingViewChart from './views/TradingViewChart'
import InvestorMaterials from './views/InvestorMaterials'
import WineMasiModal from './views/WineMasiModal'
import InvestorsNews from './views/InvestorsNews'

/* Components */
Vue.component('age-popup', AgePopup)
Vue.component('tippy-tooltip', TippyComponent)
Vue.component('navigation-top', NavigationTop)
Vue.component('navigation-main', NavigationMain)
Vue.component('navigation-mobile', NavigationMobile)
Vue.component('navigation-bottom', NavigationBottom)
Vue.component('social', Social)
Vue.component('anchor-menu', AnchorMenu)
Vue.component('site-selector', SiteSelector)
Vue.component('navigation-left', NavigationLeft)
Vue.component('page-tools', PageTools)
Vue.component('cookie-popup', CookiePopup)
Vue.component('remind', Remind)
Vue.component('date-block', DateBlock)
Vue.component('trading-view-ticker', TradingViewTicker)
Vue.component('trading-view-chart', TradingViewChart)
Vue.component('contacts-map', ContactsMap)
Vue.component('scroll-top-btn', ScrollTopBtn)
Vue.component('euroland-iframe', EurolandIframe)
Vue.component('quality-complaint-form', QualityComplaintForm)
Vue.component('information-form', InformationForm)
Vue.component('welcome-form', WelcomeForm)
Vue.component('subscription-form', SubscriptionForm)
Vue.component('news-filter', NewsFilter)
Vue.component('self-brands-portfolio', SelfBrandsPortfolio)
Vue.component('import-brands-portfolio', ImportBrandsPortfolio)
Vue.component('page-changer', PageChanger)

/* Views */
Vue.component('investors-page', InvestorsPage)
Vue.component('detail-page', DetailPage)
Vue.component('events-calendar', EventsCalendar)
Vue.component('regulatory-disclosure', RegulatoryDisclosure)
Vue.component('governance-card', GovernanceCard)
Vue.component('contacts-form', ContactsForm)
Vue.component('stock-data', StockData)
Vue.component('news-events', NewsEvents)
Vue.component('last-event', LastEvent)
Vue.component('trading-view-widget', TradingViewWidget)
Vue.component('investor-materials', InvestorMaterials)
Vue.component('wine-mesi-modal', WineMasiModal)
Vue.component('investors-news', InvestorsNews)

// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = require.context(
    './components',
    true,
    /Z[\w-]+\/index\.js$/
)

requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName)
    const componentName = fileName.split('/')[1]
    Vue.component(componentName, componentConfig.default || componentConfig)
})

if (process.env.NODE_ENV !== 'production') {
    // window.onerror = function (msg, url, lineNo, columnNo, error) {
    //     showNotyfications(`${msg}<br>${url}#${lineNo}`, {
    //         type: 'error'
    //     })
    // }

    // Vue.config.errorHandler = function (err, vm, info) {
    //     console.error(`Error: ${err.toString()}\nInfo: ${info}\nVm component: ${vm.$options._componentTag}`)
    //     showNotyfications(`Error: ${err.toString()}<br>Info: ${info}<br>Vm component: ${vm.$options._componentTag}`, {
    //         type: 'error'
    //     })
    // }

    // Vue.config.warnHandler = function (msg, vm, trace) {
    //     console.warn(`Warn: ${msg.toString()}\nInfo: ${trace}\nVm component: ${vm.$options._componentTag}`)
    //     showNotyfications(`Warn: ${msg.toString()}<br>Info: ${trace}<br>Vm component: ${vm.$options._componentTag}`, {
    //         type: 'alert'
    //     })
    // }

    Vue.config.productionTip = false
}

Object.defineProperty(Vue.prototype, '$bus', {
    get () {
        return this.$root.bus
    }
})

Vue.config.productionTip = false

Vue.use(VueAxios)

/* eslint-disable no-new */
new Vue({
    el: '#app',
    mixins: [AppDeviceEnquire],
    store,
    data: {
        lang: window.App.page.lang,
        bus: new Vue({}),
        app: window.App
    },
    components: {
        App
    },
    mounted () {
        this.$el.classList.add('is-loaded')
        addAnalytics()
        window.analyticsAdded = true
    },
    beforeDestroy () {
        removeAnalytics()
        window.analyticsAdded = false
    }
})
