<template>
    <section class="ip-numbers">
        <div class="container">
            <div class="row ip-numbers__row ip-numbers__row">
                <div class="col-default-6 col-tablet-12 ip-numbers__col">
                    <slot name="key-figures" />
                </div>
                <div class="col-default-6 col-tablet-12 ip-numbers__col">
                    <slot name="stock-data" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'ip-numbers',
    data () {
        return {}
    }
}
</script>

<style lang="scss">

.ip-numbers {
    background: $token-colors-blue-dark;
    background: linear-gradient(0deg, #114167, #114167), #175789;

    @media (min-width: $breakpoint-tablet) {
        &__col {
            padding: $token-spacers-m;
        }

        &__row {
            margin-left: -$token-spacers-m;
            margin-right: -$token-spacers-m;
        }
    }
}
</style>
