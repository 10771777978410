/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cast': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3 4.25a.75.75 0 00-.75.75v3a.75.75 0 101.5 0V5.75h16.5v12.5H14a.75.75 0 000 1.5h7a.75.75 0 00.75-.75V5a.75.75 0 00-.75-.75H3zm.083 6.005a.75.75 0 10-.166 1.49 8.25 8.25 0 017.288 7.288.75.75 0 001.49-.166 9.75 9.75 0 00-8.612-8.612zm.067 4.06a.75.75 0 00-.3 1.47A4.25 4.25 0 016.165 19.1a.75.75 0 001.47-.3 5.75 5.75 0 00-4.485-4.485zM3 18.2a.75.75 0 000 1.5h.01a.75.75 0 000-1.5H3z" _fill="#1D1D1B"/>'
  }
})
