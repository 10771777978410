/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/mp3': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.5.75a.75.75 0 00-.75.75v21.184c0 .414.336.75.75.75h16.579a.75.75 0 00.75-.75v-15.3a.75.75 0 00-.221-.531L14.687.968a.75.75 0 00-.53-.218H3.5zm.75 21.184V2.25h7.974v3.958c0 1.718 1.4 3.103 3.118 3.103h3.987v12.623H4.25zm9.474-15.726V2.25h.125l5.48 5.447v.114h-3.987a1.611 1.611 0 01-1.618-1.603zM8.11 18.293l-.806-3.085H6.18v4.266h.862v-1.155l-.08-1.778.853 2.933h.586l.853-2.933-.08 1.778v1.155h.865v-4.266H8.914l-.803 3.085zm3.783 1.18v-1.5h.575c.439 0 .783-.12 1.03-.363.249-.242.373-.572.373-.99 0-.418-.126-.758-.378-1.02-.25-.261-.586-.392-1.008-.392h-1.453v4.266h.861zm.592-2.217h-.592v-1.33h.6a.44.44 0 01.37.193c.092.125.138.294.138.507 0 .205-.045.361-.135.469a.469.469 0 01-.38.16zm3.338-.293h-.401v.668h.407c.326.002.49.213.49.633a.658.658 0 01-.127.422.404.404 0 01-.337.158.37.37 0 01-.304-.155.608.608 0 01-.12-.384h-.83c0 .367.114.664.34.89.229.225.526.337.891.337.393 0 .709-.114.95-.343.241-.228.362-.532.362-.91 0-.235-.05-.438-.149-.61a.904.904 0 00-.448-.387 1.02 1.02 0 00.395-.378c.102-.166.153-.345.153-.539 0-.377-.112-.673-.334-.89-.223-.217-.53-.325-.923-.325-.225 0-.43.05-.615.149a1.09 1.09 0 00-.431.416 1.172 1.172 0 00-.152.598h.829c0-.137.036-.25.108-.34a.353.353 0 01.284-.135c.123 0 .221.048.293.144.075.093.112.225.112.395 0 .178-.037.32-.109.428-.07.105-.181.158-.334.158z" _fill="#1D1D1B"/>'
  }
})
