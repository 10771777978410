/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/zip': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.5.75a.75.75 0 00-.75.75v21.184c0 .414.336.75.75.75h16.579a.75.75 0 00.75-.75v-15.3a.75.75 0 00-.221-.531L14.687.968a.75.75 0 00-.53-.218H3.5zm.75 21.184V2.25h7.974v3.958c0 1.718 1.4 3.103 3.118 3.103h3.987v12.623H4.25zm9.474-15.726V2.25h.125l5.48 5.447v.114h-3.987a1.611 1.611 0 01-1.618-1.603zm-3.5 12.55H8.464l1.726-3.043v-.507h-2.75v.718h1.757l-1.74 3.03v.518h2.765v-.715zm.868.716h.861v-4.266h-.861v4.266zm2.746 0v-1.5h.574c.44 0 .783-.121 1.031-.364.248-.242.373-.572.373-.99 0-.418-.127-.758-.379-1.02-.25-.261-.585-.392-1.007-.392h-1.453v4.266h.86zm.592-2.218h-.592v-1.33h.6a.44.44 0 01.37.193c.091.125.137.294.137.507 0 .205-.045.361-.134.469a.47.47 0 01-.381.16z" _fill="#1D1D1B"/>'
  }
})
