/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'burger-menu': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M22 5.75a.75.75 0 01-.75.75H2.75a.75.75 0 010-1.5h18.5a.75.75 0 01.75.75zm0 6a.75.75 0 01-.75.75H2.75a.75.75 0 010-1.5h18.5a.75.75 0 01.75.75zm-.75 6.75a.75.75 0 000-1.5H2.75a.75.75 0 000 1.5h18.5z" _fill="#1D1D1B"/>'
  }
})
