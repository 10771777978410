/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social/wifi': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_6000_10506)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3 4a1 1 0 011-1 17.364 17.364 0 0117.364 17.364 1 1 0 01-2 0A15.364 15.364 0 004 5a1 1 0 01-1-1zm1 3.91a1 1 0 000 2 10.454 10.454 0 0110.455 10.454 1 1 0 102 0A12.455 12.455 0 004 7.909zm0 4.908a1 1 0 100 2 5.545 5.545 0 015.546 5.546 1 1 0 102 0A7.545 7.545 0 004 12.818zm1.636 7.136a1.227 1.227 0 11-2.454 0 1.227 1.227 0 012.454 0z" _fill="#81899D"/></g><defs><clipPath id="clip0_6000_10506"><path pid="1" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
