/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/rtf': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.5.75a.75.75 0 00-.75.75v21.184c0 .414.336.75.75.75h16.579a.75.75 0 00.75-.75v-15.3a.75.75 0 00-.221-.531L14.687.968a.75.75 0 00-.53-.218H3.5zm.75 21.184V2.25h7.974v3.958c0 1.718 1.4 3.103 3.118 3.103h3.987v12.623H4.25zm9.474-15.726V2.25h.125l5.48 5.447v.114h-3.987a1.611 1.611 0 01-1.618-1.603zm-5.311 9H7.045v4.266h.858v-1.565h.434l.644 1.565h.917v-.041l-.751-1.751a1.094 1.094 0 00.526-.593c.062-.164.093-.361.093-.592 0-.281-.053-.518-.16-.709a1.037 1.037 0 00-.464-.434 1.659 1.659 0 00-.73-.146zm-.51 1.99h.486a.512.512 0 00.29-.077.463.463 0 00.173-.22.88.88 0 00.059-.334c0-.134-.018-.25-.053-.345a.426.426 0 00-.161-.223.47.47 0 00-.284-.079h-.51v1.277zm4.308 2.276V15.92h1.06v-.712H10.318v.712h1.04v3.554h.853zm2.717-2.453V15.92h1.5v-.712h-2.359v4.266h.859V17.73h1.336v-.709h-1.336z" _fill="#1D1D1B"/>'
  }
})
