/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'audio-duplicate-name': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.75 4a.75.75 0 00-1.5 0v16a.75.75 0 001.5 0V4zm-3 3a.75.75 0 00-1.5 0v10a.75.75 0 001.5 0V7zm-6 1a.75.75 0 00-1.5 0v8a.75.75 0 001.5 0V8zm12 1a.75.75 0 00-1.5 0v6a.75.75 0 001.5 0V9zm-9 1a.75.75 0 00-1.5 0v4a.75.75 0 001.5 0v-4zm-6 1a.75.75 0 00-1.5 0v2a.75.75 0 001.5 0v-2zm18 0a.75.75 0 00-1.5 0v2a.75.75 0 001.5 0v-2z" _fill="#1D1D1B"/>'
  }
})
