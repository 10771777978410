<template>
    <transition
        @before-enter="appearAnimationBefore"
        @enter="appearAnimationEnter"
        @leave="appearAnimationLeave"
    >
        <div
            class="cookie-popup"
            v-if="visible"
        >
            <div class="container cookie-popup__body">
                <div class="cookie-popup__text">
                    <h5 v-html="text.title"></h5>
                    <div><vue-raw :raw="text.description" /></div>
                </div>
                <transition>
                    <div v-if="visibleSettings" class="cookie-popup__settings">
                        <div class="row">
                            <div class="col-default-3 col-tablet-5 col-mobile-12">
                                <div class="settings__title">
                                    <h5 @click.stop="showInfo('policy')">{{ text.cookies.cookies_policy.title }}</h5>
                                    <z-switch id="cookie-1" :checked="checkedCookies.cookies_policy" disabled></z-switch>
                                </div>
                                <p
                                    :class="showText.policy ? `settings__text--show` : `settings__text`"
                                    v-html="text.cookies.cookies_policy.description"
                                ></p>
                            </div>
                            <div class="col-default-3 col-tablet-5 col-tablet-offset-1 col-mobile-12 col-mobile-offset-0">
                                <div class="settings__title">
                                    <h5 @click.stop="showInfo('performance')">{{ text.cookies.performance.title }}</h5>
                                    <z-switch id="cookie-performance" @change="checkedCookies.performance = !checkedCookies.performance" :checked="checkedCookies.performance"></z-switch>
                                </div>
                                <p :class="showText.performance ? `settings__text--show` : `settings__text`" v-html="text.cookies.performance.description"></p>
                            </div>
                            <div class="col-default-3 col-tablet-5 col-mobile-12">
                                <div class="settings__title">
                                    <h5 @click.stop="showInfo('functional')">{{ text.cookies.functional.title }}</h5>
                                    <z-switch id="cookie-functional" @change="checkedCookies.functional = !checkedCookies.functional" :checked="checkedCookies.functional"></z-switch>
                                </div>
                                <p
                                    :class="showText.functional ? `settings__text--show` : `settings__text`"
                                   v-html="text.cookies.functional.description"
                                ></p>
                            </div>
                            <div class="col-default-3 col-tablet-5 col-tablet-offset-1 col-mobile-12 col-mobile-offset-0">
                                <div class="settings__title">
                                    <h5 @click.stop="showInfo('marketing')">{{ text.cookies.marketing.title }}</h5>
                                    <z-switch id="cookie-marketing" @change="checkedCookies.marketing = !checkedCookies.marketing" :checked="checkedCookies.marketing"></z-switch>
                                </div>
                                <p
                                    :class="showText.marketing ? `settings__text--show` : `settings__text`"
                                    v-html="text.cookies.marketing.description"
                                ></p>
                            </div>
                        </div>
                    </div>
                </transition>
                <div class="cookie-popup__buttons">
                    <template v-if="!visibleSettings">
                        <div class="cookie-popup__button">
                            <z-button
                                @click="submit"
                                kind="primary"
                                key="confirm"
                            >{{ text.button.confirm }}</z-button>
                        </div>
                        <div class="cookie-popup__button">
                            <z-button
                                @click="showSettings"
                                kind="secondary"
                                key="settings"
                            >{{ text.button.settings }}</z-button>
                        </div>
                    </template>
                    <template v-else>
                        <div class="cookie-popup__button">
                            <z-button
                                @click="submit"
                                kind="primary"
                                key="confirmAll"
                            >{{ text.button.confirmAll}}</z-button>
                        </div>
                        <div class="cookie-popup__button">
                            <z-button
                                @click="submitSettings"
                                kind="secondary"
                                key="confirmSettings"
                            >{{ text.button.confirmSettings}}</z-button>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import Velocity from 'velocity-animate'
import { localize } from '@/utils/i18n'
import { deviceEnquire } from '@/utils/device'
const cookie = require('cookie_js')

export default {
    name: 'cookie-popup',
    data () {
        return {
            duration: 250,
            visible: false,
            visibleSettings: false,
            checkedCookies: {
                cookies_policy: true,
                performance: true,
                functional: true,
                marketing: true
            },
            device: 'desktop',
            showText: {
                policy: false,
                performance: false,
                functional: false,
                marketing: false
            },
            text: {
                title: localize({
                    'ru': 'Использование файлов cookie',
                    'en': 'Cookie policy'
                }),
                description: localize({
                    'ru': 'Этот веб-сайт использует файлы cookie. Некоторые обеспечивают работу сайта, а&nbsp;другие предназначены для повышения удобства пользователей. Нажмите &laquo;Принять&raquo;, чтобы принять наши файлы cookie. Вы&nbsp;можете изменить настройки файлов cookie, нажав &laquo;Изменить настройки&raquo;, или на&nbsp;странице <z-link href="/ru/statements/">&laquo;Политика использования файлов cookie&raquo;</z-link>.',
                    'en': 'This website uses cookies. Some are necessary for the operation, and some are optional and designed to&nbsp;improve your experience. Click &rsquo;Accept&rsquo; to&nbsp;accept the cookies. You can change the cookie settings by&nbsp;clicking &rsquo;Change settings&rsquo; or&nbsp;on&nbsp;the <z-link href="/en/statements/">Cookie Policy page</z-link>.'
                }),
                cookies: {
                    cookies_policy: {
                        title: localize({
                            'ru': 'Обязательные cookies',
                            'en': 'Strictly necessary cookies'
                        }),
                        description: localize({
                            'ru': 'Эти файлы необходимы для правильной работы сайта и&nbsp;функционала.',
                            'en': 'These cookies are essential for the website and its features to work properly.'
                        })
                    },
                    performance: {
                        title: localize({
                            'ru': 'Эксплуатационные cookies',
                            'en': 'Performance cookies'
                        }),
                        description: localize({
                            'ru': 'Эти файлы позволяют повысить удобство использования сайта, сохраняя информацию о настройках пользователя.',
                            'en': 'These cookies collect information about how you use the website and help&nbsp;us to&nbsp;adapt the content according to&nbsp;your needs, improving its usability.'
                        })
                    },
                    functional: {
                        title: localize({
                            'ru': 'Функциональные cookies',
                            'en': 'Functionality cookies'
                        }),
                        description: localize({
                            'ru': 'Эти файлы собирают информацию о том, как вы используете сайт, и помогают нам улучшать его в соответствии с вашими потребностями.',
                            'en': 'These cookies enable&nbsp;us to&nbsp;improve the comfort and usability of&nbsp;the website and to&nbsp;provide various features.'
                        })
                    },
                    marketing: {
                        title: localize({
                            'ru': 'Маркетинговые cookies',
                            'en': 'Marketing cookies'
                        }),
                        description: localize({
                            'ru': 'Эти файлы используются для показа рекламной информации, которая может быть вам интересна, в частности, на основе посещенных вами сайтов.',
                            'en': 'These cookies are used to&nbsp;send advertising and promotional information that is&nbsp;relevant to&nbsp;you, e.g. based on&nbsp;the web pages you visited.'
                        })
                    }
                },
                button: {
                    confirm: localize({
                        'ru': 'Принять',
                        'en': 'Accept'
                    }),
                    confirmAll: localize({
                        'ru': 'Разрешить все и продолжить',
                        'en': 'Allow all and continue'
                    }),
                    settings: localize({
                        'ru': 'Изменить настройки',
                        'en': 'Change settings'
                    }),
                    confirmSettings: localize({
                        'ru': 'Подтвердить мой выбор',
                        'en': 'Confirm my choices'
                    })
                }
            }
        }
    },
    mounted () {
        this.visible = !cookie.get('cookies_policy')
        deviceEnquire((device) => {
            this.device = device
        })
        this.getExistedCookies()

        this.$root.$bus.$on('open-cookies', this.checkCookies)
    },
    beforeDestroy () {
        this.$root.$bus.$off('open-cookies', this.checkCookies)
    },
    methods: {
        getExistedCookies () {
            let cookies = cookie.get(['cookies_policy', 'performance', 'functional', 'marketing'])

            this.checkedCookies = cookies

            for (let cockieItem in this.checkedCookies) {
                if (!this.checkedCookies[cockieItem]) {
                    this.checkedCookies[cockieItem] = 'true'
                }
            }
        },
        showInfo (text) {
            if (this.device === 'v-tablet' || this.device === 'mobile') {
                this.showText[text] = !this.showText[text]
            } else {
                return ''
            }
        },
        checkCookies () {
            let cookies = cookie.get(['performance', 'functional', 'marketing'])
            Object.keys(cookies).forEach(key => {
                if (cookies[key] === 'true' && !this.checkedCookies.includes(key)) {
                    this.checkedCookies.push(cookies[key])
                }
            })
            this.visible = true
            this.visibleSettings = true
        },
        submit () {
            cookie.set({
                cookies_policy: true,
                performance: true,
                functional: true,
                marketing: true
            }, {
                domain: location.hostname === 'localhost' ? '' : '.' + location.host,
                path: '/',
                expires: 365
            })

            // eslint-disable-next-line no-return-assign
            this.$nextTick(this.setVisibleFalse)
        },
        setVisibleFalse () {
            this.visible = false
        },
        showSettings () {
            this.visibleSettings = true
        },
        submitSettings () {
            cookie.set(this.checkedCookies, {
                domain: '.' + location.host,
                path: '/',
                expires: 365
            })

            // eslint-disable-next-line no-return-assign
            this.$nextTick(this.setVisibleFalse)
        },
        appearAnimationBefore (el) {
            el.style.opacity = 0
        },
        appearAnimationEnter (el, done) {
            Velocity(el, { opacity: 1 }, { duration: this.duration, delay: this.duration }, { complete: done })
        },
        appearAnimationLeave (el, done) {
            Velocity(el, { opacity: 0 }, { duration: this.duration })
            Velocity(el, { display: 'none' }, { complete: done })
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
