<template>
    <article class="governance-card">
        <div class="governance-card__inner">
            <div
                class="governance-card__pic"
                :style="`background-image: url(${imgUrl})`"
            ></div>
            <div class="governance-card__description">
                <h4 class="governance-card__name">{{ fullName }}</h4>
                <div class="governance-card__post" v-html="post"></div>
                <div class="governance-card__date" v-if="year">{{ text.year }}: {{ year }}</div>
                <div class="governance-card__additional" v-html="additional"></div>
            </div>
        </div>
    </article>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'governance-card',
    data () {
        return {
            placeholderImagePath: '/images/corporate-governance/imageholder.svg',
            text: {
                year: localize({
                    'ru': 'Год рождения',
                    'en': 'Year of birth'
                })
            }
        }
    },
    props: {
        fullName: {
            type: String,
            default: ''
        },
        photoUrl: {
            type: String
        },
        post: {
            type: String,
            default: ''
        },
        year: {
            type: String,
            default: ''
        },
        additional: {
            type: String,
            default: ''
        }
    },
    computed: {
        imgUrl () {
            let imgPath = (this.photoUrl ? this.photoUrl : this.placeholderImagePath)
            return imgPath
        }
    }
}
</script>

<style lang="scss">
    .corporate-governance-title-wrapper {
        margin-top: 14px;
        margin-bottom: 58px;
    }
    .governance-card {
        display: block;
        margin-bottom: 30px;

        &__inner {
            display: flex;
        }

        &__pic {
            flex-shrink: 0;
            margin-right: 1.65em;
            width: 80px;
            height: 80px;
            background-position: center;
            background-size: cover;
            border-radius: 40px;
        }

        &__name {
            margin-bottom: 8px;
            color: $token-colors-kaviar-black;
        }

        &__post {
            font-family: $roboto;
            line-height: 1.222;
        }

        &__date, &__additional {
            font-family: $roboto;
            color: $token-colors-grey-dark;
            line-height: 1.222;
        }

        &__date {
            margin-top: 18px;
        }

        &__additional {
            margin-top: 8px;
        }
    }
</style>
