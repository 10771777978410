<template>
    <div class="page-changer">
        <a
            :href="prev || 'javascript:void(0)'"
            :class="[
                'page-changer__link page-changer__link--prev',
                { 'is-disabled': !prev }
            ]"
        >
            <z-icon
                name="long-arrow"
                dir="down"
                width="64px"
                height="64px"
                color="#124066"
                class="page-changer__link-icon"
            ></z-icon>
            <span
                class="page-changer__link-text hide-me--v-tablet"
                v-html="text.prev"
            ></span>
        </a>
        <div class="page-changer__button-wrapper">
            <z-button
                v-if="backLink"
                class="page-changer__button u-bold"
                tag="a"
                kind="secondary"
                :href="backLink"
            >
                <span v-html="text.backLink"/>
            </z-button>
        </div>

        <a
            :href="next || 'javascript:void(0)'"
            :class="
                ['page-changer__link page-changer__link--next',
                { 'is-disabled': !next }
            ]"

        >
            <span
                class="page-changer__link-text hide-me--v-tablet"
                v-html="text.next"
            ></span>
            <z-icon
                name="long-arrow"
                width="64px"
                height="64px"
                color="#124066"
                class="page-changer__link-icon"
            ></z-icon>
        </a>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'page-changer',
    props: {
        backLink: String,
        prev: String,
        next: String
    },
    data () {
        return {
            text: {
                prev: localize({
                    ru: 'Предыдущая',
                    en: 'Previous'
                }),
                next: localize({
                    ru: 'Следующая',
                    en: 'Next'
                }),
                backLink: localize({
                    ru: 'Вернуться к&nbsp;списку',
                    en: 'Back to&nbsp;list'
                })
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
