<template>
    <div class="ip-investors-wrapper">
        <section class="ip-investors">
            <div class="container">
                <div class="row ip-investors__row">
                    <div class="col-default-6 col-tablet-7 col-v-tablet-12">
                        <div class="ip-investors__left">
                            <z-caption tag="h2" color="white" class="header-1" v-html="text.maintitle">
                            </z-caption>
                            <z-caption tag="h3" weight="400" color="white" class="header-3">
                                <p v-html="text.caption"></p>
                            </z-caption>
                            <div class="ip-investors__left-btn-wrapper">
                                <z-button
                                    inverted
                                    kind="secondary"
                                    class="ip-investors__business-btn js-analytics"
                                    tag="a"
                                    :href="getLink('/about/business/')"
                                    :data-analytics-label="`ClickBusinessInvestors${capitalizeLang}`"
                                    data-analytics-action="click"
                                    data-analytics-category="banner"
                                >
                                    <span v-html="text.title.business"></span>
                                </z-button>
                                <z-button
                                    inverted
                                    kind="secondary"
                                    class="ip-investors__brands-btn js-analytics"
                                    tag="a"
                                    :href="getLink('/brands/')"
                                    :data-analytics-label="`ClickBrandsInvestors${capitalizeLang}`"
                                    data-analytics-action="click"
                                    data-analytics-category="banner"
                                >
                                    <span v-html="text.title.brands"></span>
                                </z-button>
                                <z-button
                                    v-if="lang === 'ru'"
                                    inverted
                                    kind="primary"
                                    class="ip-investors__questions-btn js-analytics"
                                    tag="a"
                                    :href="getLink('/investors/shareholder-center/stock-split-faqs/')"
                                    :data-analytics-label="`ClickBrandsInvestors${capitalizeLang}`"
                                    data-analytics-action="click"
                                    data-analytics-category="banner"
                                >
                                    <span v-html="text.title.questions"></span>
                                </z-button>
                            </div>
                        </div>
                    </div>
                    <div class="col-default-3 col-default-offset-3 col-tablet-4 col-tablet-offset-1 col-v-tablet-12 col-v-tablet-offset-0" v-if="news">
                        <div class="ip-investors__right">
                            <div class="glass-box">
                                <div class="glass-box__decor"></div>
                                <div class="glass-box__content">
                                    <z-caption tag="h4" color="white" class="glass-box__title">
                                        <span v-html="text.title.news"></span>
                                    </z-caption>
                                    <p class="glass-box__text" v-html="news.name"></p>
                                    <z-caption tag="span" color="white" class="glass-box__date">
                                        <span>{{ news['active-from'] | momentFilter('DD MMMM YYYY') }}</span>
                                    </z-caption>
                                </div>
                                <z-link
                                    class="glass-box__btn js-analytics"
                                    :href="news.url"
                                    :data-analytics-label="`ClickNewsInvestors${capitalizeLang}`"
                                    data-analytics-action="click"
                                    data-analytics-category="banner"
                                ></z-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { getLink } from '@/utils/get-link'
import { localize } from '@/utils/i18n'

export default {
    name: 'ip-investors',
    props: {
        news: {
            type: Object
        }
    },
    data () {
        return {
            text: {
                maintitle: localize({
                    'ru': 'Инвесторам',
                    'en': 'Investors'
                }),
                caption: localize({
                    'ru': 'Novabev Group&nbsp;&mdash; российский вертикально-интегрированный производитель премиальных спиртных напитков, а&nbsp;также ведущий независимый импортер крепких спиртных напитков и&nbsp;вина с&nbsp;хорошо диверсифицированным премиальным портфелем импортных брендов.',
                    'en': 'Novabev Group is&nbsp;a&nbsp;Russian alcohol company, producer and distributor of&nbsp;premium vodka, wine and distilled beverages, and one of&nbsp;the main importers of&nbsp;premium spirits and wines in&nbsp;the country.'
                }),
                title: {
                    business: localize({
                        'ru': 'Бизнес',
                        'en': 'Business'
                    }),
                    brands: localize({
                        'ru': 'Бренды',
                        'en': 'Brands'
                    }),
                    questions: localize({
                        'ru': 'FAQ по&nbsp;увеличению&nbsp;УК',
                        'en': ''
                    }),
                    news: localize({
                        'ru': 'Novabev Group',
                        'en': 'Novabev Group'
                    })
                },
                news: {
                    title: localize({
                        'ru': 'Финансовые результаты Novabev Group за&nbsp;первую половину 2022 года',
                        'en': 'Financial Results of&nbsp;Novabev Group for H1 2022'
                    }),
                    date: localize({
                        'ru': '25 августа',
                        'en': 'August 25'
                    }),
                    link: localize({
                        'ru': '/investors/news/finansovye-rezultaty-beluga-group-za-pervuyu-polovinu-2022-goda/',
                        'en': '/en/investors/news/financial-results-of-beluga-group-for-h1-2022/'
                    })
                },
                annualReport: {
                    link: localize({
                        ru: 'http://2022annualreport.belugagroup.ru/',
                        en: 'http://en2022annualreport.belugagroup.ru/'
                    }),
                    text: localize({
                        ru: 'Отчет о деятельности 2022',
                        en: 'Annual report 2022'
                    })
                }
            },
            lang: this.$root.lang
        }
    },
    computed: {
        capitalizeLang: function () {
            return this.$options.filters.capitalizeString(this.$root.lang)
        }
    },
    methods: {
        getLink: getLink
    }
}
</script>

<style lang="scss">

.ip-investors-wrapper {
    background-image: url('/images/investors/header.png');
    background-size: cover;
    background-position: center;
    margin-top: -180px;
    padding-top: 180px;
}

.ip-investors {
    &__business-btn {
        margin-right: 20px;
    }

    &__brands-btn {
        margin-right: 20px;
    }

    &__questions-btn {
        @media (max-width: $breakpoint-mobile){
            margin-top: 20px;
        }
    }

    &__annual-btn {
            @media (max-width: 455px){
            margin-top: 20px;
        }
    }

    .z-button--inverted.z-button--kind-secondary {
        background-color: transparent;
    }

    &__left {
        padding-bottom: $token-spacers-l;

        @include breakpoint (v-tablet) {
            padding-bottom: $token-spacers-m;
        }

        @include breakpoint (mobile) {
            padding-bottom: 32px;
        }
    }

    &__right {
        display: flex;
        align-items: flex-end;
        height: 100%;
    }
}

.glass-box {
    position: relative;
    width: 100%;
    padding-bottom: 60px;

    @include breakpoint (v-tablet) {
        padding-bottom: 0;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        // background-color: #7993ad;
        background: linear-gradient(180deg, rgba(153, 187, 222, 0.6) 0%, rgba(153, 187, 222, 0) 100%);
        backdrop-filter: blur(32px);
    }

    &__title {
        text-transform: uppercase!important;
    }

    &__content {
        position: relative;
        z-index: 2;
        padding: 20px;
        margin-bottom: 32px;
        max-width: 290px;

        @include breakpoint (v-tablet) {
            margin-bottom: 0;
        }

        @include breakpoint (mobile) {
            margin-bottom: 24px;
        }
    }
    &__text {
        margin-bottom: 10px;
        color: white;
    }

    &__btn {
        position: absolute;
        display: flex;
        align-items: center;
        height: 60px;
        width: 180px;
        margin-left: -2px;
        bottom:0;
        left: 0;
        z-index: 2;
        background-color: transparent;
        border: 2px solid transparent;
        border-top-right-radius: 60px;
        border-right-color: white;
        border-top-color: white;
        background-image: url("data:image/svg+xml,%3Csvg class='glass-box__svg' width='105' height='16' viewBox='0 0 105 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M104.707 8.7071C105.098 8.31657 105.098 7.68341 104.707 7.29288L98.3431 0.928924C97.9526 0.538399 97.3195 0.538399 96.9289 0.928924C96.5384 1.31945 96.5384 1.95261 96.9289 2.34314L102.586 7.99999L96.9289 13.6568C96.5384 14.0474 96.5384 14.6805 96.9289 15.0711C97.3195 15.4616 97.9526 15.4616 98.3431 15.0711L104.707 8.7071ZM8.74228e-08 9L104 8.99999L104 6.99999L-8.74228e-08 7L8.74228e-08 9Z' fill='white'/%3E%3C/svg%3E");
        background-position: 30% 50%;
        background-repeat: no-repeat;
        background-size: 60%;
        transition: all $transition;

        &:hover {
            border-right-color: $token-colors-gold;
            border-top-color: $token-colors-gold;
            background-color: rgba(196, 176, 120, 0.1);
        }

        @include breakpoint (v-tablet) {
            right: 0;
            left: auto;
        }

        @include breakpoint (mobile) {
            position: relative;
        }
    }
}
</style>
