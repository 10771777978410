<template>
    <div class="stock-data">
        <div class="stock-data-chart">
            <!-- <z-chart
                :class="{
                    'is-loaded': chartHistoricalLoaded
                }"
                v-if="optionsHistorical"
                @mounted="getChart"
                :options="optionsHistorical"
            ></z-chart>
            <div class="stock-data-chart__btns">
                <z-button
                    v-for="filter in filterHistorical"
                    :key="filter.value"
                    inverted
                    size="xs"
                    kind="secondary"
                    :class="{
                        'is-active': activeFilterHistorical === filter.value
                    }"
                    @click="changeChartHistorical(filter.value)"
                >{{filter.label}}</z-button>
            </div> -->
            <trading-view-widget />
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { localize } from '@/utils/i18n'
window.moment = moment

export default {
    name: 'stock-data',
    data () {
        return {
            chartHistoricalLoaded: true,
            chartHistorical: null,
            optionsHistorical: null,
            activeFilterHistorical: '180',
            filterHistorical: [
                {
                    value: '30',
                    label: localize({
                        'ru': '1 месяц',
                        'en': '1 month'
                    })
                },
                {
                    value: '90',
                    label: localize({
                        'ru': '3 месяца',
                        'en': '3 months'
                    })
                },
                {
                    value: '180',
                    label: localize({
                        'ru': '6 месяцев',
                        'en': '6 months'
                    })
                }
            ],
            ticker: null
        }
    },
    computed: {
        capitalizeLang: function () {
            return this.$options.filters.capitalizeString(this.$root.lang)
        }
    },
    methods: {
        getChart (chart) {
            this.chartHistorical = chart
        },
        changeChartHistorical (key) {
            this.activeFilterHistorical = key
            this.buildChartHistorical()
        },
        buildChartHistorical () {
            let xColumns = ['x']
            let dataColumns = ['BELU']
            this.chartHistoricalLoaded = true

            axios.get(`https://tools.eurolandir.com/tools/pricefeed/history.aspx?companycode=ru-syng&format=json&period=${this.activeFilterHistorical}`)
                .then((res) => {
                    const response = res.data
                    response[Object.keys(response)[0]].Data.forEach(obj => {
                        dataColumns.push(parseInt(obj.Open))
                        xColumns.push(obj.Date)
                    })

                    if (this.chartHistorical) {
                        this.chartHistorical.load({
                            columns: [
                                xColumns,
                                dataColumns
                            ]
                        })
                        setTimeout(() => {
                            this.chartHistoricalLoaded = false
                        }, 300)
                    } else {
                        this.optionsHistorical = {
                            size: {
                                height: 200
                            },
                            data: {
                                x: 'x',
                                columns: [
                                    xColumns,
                                    dataColumns
                                ],
                                type: 'area'
                            },
                            tooltip: {
                                format: {
                                    title: (x) => {
                                        return moment(x).format('DD/MM/YYYY')
                                    },
                                    value: (value, ratio, id, index) => {
                                        return `${this.$options.filters.numberFilter(value)} RUB`
                                    }
                                }
                            },
                            grid: {
                                x: {
                                    show: false
                                },
                                y: {
                                    show: false
                                }
                            },
                            padding: {
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0
                            },
                            point: {
                                show: true,
                                r: 0,
                                focus: {
                                    expand: {
                                        enabled: true,
                                        r: 6
                                    }
                                }
                            },
                            zoom: {
                                enabled: true
                            },
                            axis: {
                                y: {
                                    tick: {
                                        // count: 5
                                    }
                                    // min: 3000,
                                    // max: 4000
                                },
                                x: {
                                    type: 'timeseries',
                                    tick: {
                                        count: 6,
                                        // format: '%Y-%m-%d'
                                        format: function (x) {
                                            return moment(x).format('DD MMM')
                                        }
                                    }
                                }
                            },
                            legend: {
                                show: false
                            },
                            area: {
                                linearGradient: {
                                    x: [0, 0],
                                    y: [0, 1],
                                    stops: [
                                        [0, '#6f8ca3', 1],
                                        [1, '#114167', 0.5]
                                    ]
                                }
                            }
                        }
                        setTimeout(() => {
                            this.chartHistoricalLoaded = false
                        }, 300)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    created () {
        this.buildChartHistorical()
        axios.get('https://tools.eurolandir.com/tools/pricefeed/?companycode=ru-syng&format=json')
            .then((res) => {
                this.ticker = {
                    date: moment(res.data['Novabev Group'].Date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY'),
                    symbol: res.data['Novabev Group'].Symbol,
                    company: 'Novabev Group',
                    last: parseInt(res.data['Novabev Group'].Last),
                    percent: `${res.data['Novabev Group'].ChangePercent}%`,
                    percentIsNegative: res.data['Novabev Group'].ChangePercent[0] === '-'
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }
}
</script>

<style lang="scss">
.stock-data {
    .z-chart {
        margin-left: -14px;
        opacity: 1;
        transition: opacity 0.25s;

        &.is-loaded {
            position: relative;
            opacity: 0;
        }
    }

    .z-chart__legend {
        display: none;
    }

    path.bb-shape.bb-shape.bb-line.bb-line-BELU {
        stroke-width: 3px;
    }

    .stock-data-chart__btns {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;

        .z-button {
            &.is-active {
                border-color: #c4b078;
                background-color: rgba(196, 176, 120, 0.1);
            }

            + .z-button {
                margin-left: 16px;
            }
        }
    }

    .bb-axis {
        .tick {
            tspan {
                // opacity: 0;
                fill: #fff;
                fill-opacity: 0.6;
            }
        }
    }

    .bb-circle._expanded_ {
        stroke: #fff !important;
        stroke-width: 1px;
    }

    .tradingview-widget {
        min-height: 350px;
        padding-top: 30%;
    }
}

.stock-data-ticker {
    margin-top: 70px;
    position: relative;
    padding-left: 56px;
    padding-top: 26px;
    padding-bottom: 26px;

    @include breakpoint (tablet) {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
    }

    &__container {
        position: relative;
        @include padding-level(left, M);

        @include breakpoint (tablet) {
            padding-left: 0;
        }

        > span {
            display: block;
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            display: block;
            transform: translateY(-50%);
            right: 100%;

            @include breakpoint (tablet) {
                content: none;
            }
        }

        &:before {
            height: 12em;
            width: 2px;
            background-color: $token-colors-gold;

        }

        &:after {
            height: 6em;
            width: 3em;
            border: 2px solid $token-colors-gold;
            border-top-left-radius: 3em;
            border-bottom-left-radius: 3em;
        }
    }

    &__date {
        font-size: 15px;
        line-height: 1.2;
        color: #F4F4F4;
        margin-bottom: 10px;
        font-family: $roboto;
    }
    &__symbol {
        font-size: 15px;
        line-height: 1;
        text-transform: uppercase;
        color: #C4AF77;
        font-family: $roboto;
    }

    &__company {
        font-family: $roboto;
        font-size: 15px;
        line-height: 1;
        text-transform: uppercase;
        color: #99BBDE;
        margin-bottom: 26px;
    }

    &__last {
        font-size: 64px;
        line-height: 1;
        color: #fff;
        font-weight: 600;
    }

    &__percent {
        color: #fff;
        font-weight: 400;
        font-size: 14px;
        font-family: $roboto;
        line-height: 1;
        position: relative;
        color: #7DFF50;
        padding-left: 1em;

        &:before {
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -0.38em;
            border-left: 0.4em solid transparent;
            border-right: 0.4em solid transparent;
            border-bottom: 0.7em solid #7DFF50;
        }

        &.is-negative {
            color: #EF5350;

            &:before {
                transform: scale(1, -1);
                border-bottom-color: #EF5350;
            }
        }
    }
}
</style>
