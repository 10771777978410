/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/pptx': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.5.75a.75.75 0 00-.75.75v21.184c0 .414.336.75.75.75h16.579a.75.75 0 00.75-.75v-15.3a.75.75 0 00-.221-.531L14.687.968a.75.75 0 00-.53-.218H3.5zm.75 21.184V2.25h7.974v3.958c0 1.718 1.4 3.103 3.118 3.103h3.987v12.623H4.25zm9.474-15.726V2.25h.125l5.48 5.447v.114h-3.987a1.611 1.611 0 01-1.618-1.603zM5.91 19.474v-1.5h.574c.44 0 .783-.121 1.031-.364.248-.242.372-.572.372-.99 0-.418-.125-.758-.377-1.02-.25-.261-.586-.392-1.008-.392H5.049v4.266h.86zm.592-2.218H5.91v-1.33h.6a.44.44 0 01.37.193c.091.125.137.294.137.507 0 .205-.045.361-.134.469a.469.469 0 01-.381.16zm3.004 2.218v-1.5h.574c.44 0 .783-.121 1.031-.364.248-.242.372-.572.372-.99 0-.418-.126-.758-.377-1.02-.25-.261-.586-.392-1.008-.392H8.645v4.266h.861zm.592-2.218h-.592v-1.33h.6a.44.44 0 01.37.193c.091.125.137.294.137.507 0 .205-.044.361-.134.469a.469.469 0 01-.381.16zm3.85-1.33h1.055v-.718h-2.956v.718h1.037v3.548h.865v-3.548zm3.64-.718l-.574 1.465-.574-1.465h-.987l1.005 2.115-1.026 2.15h1l.582-1.49.586 1.49h1l-1.026-2.15 1.002-2.115h-.987z" _fill="#1D1D1B"/>'
  }
})
