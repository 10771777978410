<template>
    <!-- TradingView Widget BEGIN -->
    <div class="tradingview-widget-container" ref="containerTV">
        <div class="tradingview-widget-container__widget"></div>
        <div class="tradingview-widget-copyright" v-if="lang === 'ru'">
            <a href="https://ru.tradingview.com/symbols/MOEX-BELU/" rel="noopener" target="_blank"><span class="blue-text">Котировки акций BELU</span></a> от TradingView
        </div>
        <div class="tradingview-widget-copyright" v-else>
            TradingView <a href="https://ru.tradingview.com/symbols/MOEX-BELU/" rel="noopener" target="_blank"><span class="blue-text">BELU Share Price</span></a>
        </div>
    </div>
    <!-- TradingView Widget END -->
</template>

<script>
export default {
    name: 'trading-view-ticker',
    data () {
        return {
            active: false,
            lang: this.$root.lang
        }
    },
    mounted () {
        this.init()
    },
    methods: {
        init () {
            this
                .attach()
                .then(() => {
                    console.log('attached')
                })
        },
        attach () {
            // eslint-disable-next-line one-var
            let attached = false,
                loaded = false,
                error = false,
                stack = []

            return new Promise((resolve, reject) => {
                if (!attached) {
                    const script = document.createElement('script')
                    script.setAttribute('src', 'https://s3.tradingview.com/external-embedding/embed-widget-symbol-info.js')
                    script.setAttribute('async', '')
                    script.innerHTML = `{
                        "symbol": "MOEX:BELU",
                        "width": "100%",
                        "locale": "${this.lang}",
                        "colorTheme": "light",
                        "customer": "belugagroupru",
                        "isTransparent": false
                    }`
                    this.$refs.containerTV.appendChild(script)

                    script.onload = () => {
                        resolve()
                        loaded = true
                        stack.forEach(cb => {
                            cb[0]()
                        })
                        stack = []
                    }
                    script.onerror = (err) => {
                        reject(err)
                        loaded = true
                        error = true
                        stack.forEach(cb => {
                            cb[1]()
                        })
                        stack = []
                    }
                    attached = true
                }

                if (error) reject(error)

                if (loaded) {
                    resolve()
                } else {
                    stack.push([resolve, reject])
                }
            })
        }
    }
}
</script>
