<template>
    <!-- TradingView Widget BEGIN -->
    <div class="tradingview-widget-container" ref="containerTV">
        <div id="tradingview_a3f5c"></div>
        <div class="tradingview-widget-copyright" v-if="lang === 'ru'">
            <a href="https://ru.tradingview.com/symbols/MOEX-BELU/" rel="noopener" target="_blank"><span class="blue-text">График BELU</span></a> от TradingView
        </div>
        <div class="tradingview-widget-copyright" v-else>
            TradingView <a href="https://ru.tradingview.com/symbols/MOEX-BELU/" rel="noopener" target="_blank"><span class="blue-text">BELU Share Graph</span></a>
        </div>
    </div>
    <!-- TradingView Widget END -->
</template>

<script>
export default {
    name: 'trading-view-chart',
    data () {
        return {
            active: false,
            lang: this.$root.lang
        }
    },
    mounted () {
        this.init()
    },
    methods: {
        init () {
            this
                .attach()
                .then(() => {
                    console.log('attached')
                    const scriptInit = document.createElement('script')
                    scriptInit.innerHTML = `new TradingView.widget({
                        "width": "100%",
                        "height": 610,
                        "symbol": "MOEX:BELU",
                        "interval": "D",
                        "timezone": "Etc/UTC",
                        "theme": "light",
                        "style": "3",
                        "locale": "${this.lang}",
                        "toolbar_bg": "#f1f3f6",
                        "enable_publishing": false,
                        "hide_legend": true,
                        "customer": "belugagroupru",
                        "container_id": "tradingview_a3f5c"
                    });`

                    this.$refs.containerTV.appendChild(scriptInit)
                })
        },
        attach () {
            // eslint-disable-next-line one-var
            let attached = false,
                loaded = false,
                error = false,
                stack = []

            return new Promise((resolve, reject) => {
                if (!attached) {
                    const script = document.createElement('script')
                    script.setAttribute('src', 'https://s3.tradingview.com/tv.js')
                    this.$refs.containerTV.appendChild(script)

                    script.onload = () => {
                        resolve()
                        loaded = true
                        stack.forEach(cb => {
                            cb[0]()
                        })
                        stack = []
                    }

                    script.onerror = (err) => {
                        reject(err)
                        loaded = true
                        error = true
                        stack.forEach(cb => {
                            cb[1]()
                        })
                        stack = []
                    }
                    attached = true
                }

                if (error) reject(error)

                if (loaded) {
                    resolve()
                } else {
                    stack.push([resolve, reject])
                }
            })
        }
    }
}
</script>
