/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/png': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.5.75a.75.75 0 00-.75.75v21.184c0 .414.336.75.75.75h16.579a.75.75 0 00.75-.75v-15.3a.75.75 0 00-.221-.531L14.687.968a.75.75 0 00-.53-.218H3.5zm.75 21.184V2.25h7.974v3.958c0 1.718 1.4 3.103 3.118 3.103h3.987v12.623H4.25zm9.474-15.726V2.25h.125l5.48 5.447v.114h-3.987a1.611 1.611 0 01-1.618-1.603zm-6.48 13.266v-1.5h.574c.44 0 .783-.121 1.031-.364.248-.242.372-.572.372-.99 0-.418-.126-.758-.378-1.02-.25-.261-.586-.392-1.007-.392H6.382v4.266h.862zm.592-2.218h-.592v-1.33h.6a.44.44 0 01.37.193c.091.125.137.294.137.507 0 .205-.045.361-.135.469a.469.469 0 01-.38.16zm4.384 2.218h.86v-4.266h-.857v2.8l-1.263-2.8h-.862v4.266h.862v-2.798l1.26 2.798zm4.14-.076c.243-.092.447-.228.613-.408v-1.76h-1.468v.65h.603v.782l-.076.053a.747.747 0 01-.413.102c-.265 0-.457-.087-.574-.263-.115-.178-.173-.461-.173-.85v-.773c.004-.371.059-.642.164-.812.106-.17.275-.255.507-.255.191 0 .332.05.422.15.09.097.146.282.17.553h.838c-.037-.488-.174-.846-.41-1.075-.235-.228-.583-.343-1.046-.343-.493 0-.868.16-1.125.478-.256.316-.384.769-.384 1.356v.768c.008.58.147 1.022.419 1.327.271.303.652.454 1.142.454.287 0 .551-.045.791-.134z" _fill="#1D1D1B"/>'
  }
})
