/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'external': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.303 5.22a.75.75 0 00-1.06 1.06l5.208 5.209H7.75A4.75 4.75 0 003 16.239v2.488a.75.75 0 001.5 0V16.24a3.25 3.25 0 013.25-3.25h9.7l-5.208 5.208a.75.75 0 101.061 1.06l6.489-6.488a.75.75 0 000-1.06l-6.489-6.49z" _fill="#1D1D1B"/>'
  }
})
