/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'image-1': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.25 3.625c0-1.036.84-1.875 1.875-1.875h15.75c1.035 0 1.875.84 1.875 1.875v15.75c0 1.035-.84 1.875-1.875 1.875H4.125a1.875 1.875 0 01-1.875-1.875V3.625zm1.875-.375a.375.375 0 00-.375.375v12.814l2.754-2.753a3 3 0 014.242 0l.193.193a1.5 1.5 0 002.122 0l2.443-2.443a3 3 0 014.242 0l.504.503V3.625a.375.375 0 00-.375-.375H4.125zM20.25 14.06l-1.564-1.564a1.5 1.5 0 00-2.122 0l-2.443 2.443a3 3 0 01-4.242 0l-.193-.193a1.5 1.5 0 00-2.122 0L3.75 18.561v.814c0 .207.168.375.375.375h15.75a.375.375 0 00.375-.375v-5.314zM8.45 6.626a1.327 1.327 0 10.004 2.655 1.327 1.327 0 00-.003-2.655zM5.626 7.952a2.827 2.827 0 115.655 0 2.827 2.827 0 01-5.655 0z" _fill="#1D1D1B"/>'
  }
})
