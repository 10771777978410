/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'audio': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.072 6.75A4.75 4.75 0 0111.822 2h.308a4.75 4.75 0 014.75 4.75v4.925a4.75 4.75 0 01-4.75 4.75h-.308a4.75 4.75 0 01-4.75-4.75V6.75zm4.75-3.25a3.25 3.25 0 00-3.25 3.25v1.547h1.392a.75.75 0 010 1.5H8.572v1.518h6.808V9.797h-1.392a.75.75 0 010-1.5h1.392V6.75a3.25 3.25 0 00-3.25-3.25h-.308zm3.352 9.315H8.777a3.251 3.251 0 003.045 2.11h.308a3.251 3.251 0 003.044-2.11zM4.706 9.954a.75.75 0 01.75.75v.684a6.52 6.52 0 1013.04 0v-.684a.75.75 0 011.5 0v.684a8.02 8.02 0 01-7.27 7.985v2.003H16a.75.75 0 010 1.5H7.952a.75.75 0 010-1.5h3.274v-2.003a8.02 8.02 0 01-7.27-7.985v-.684a.75.75 0 01.75-.75z" _fill="#000"/>'
  }
})
