/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar-plus': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9 2.25a.75.75 0 01.75.75v1.25h4.5V3a.75.75 0 011.5 0v1.25H18A2.75 2.75 0 0120.75 7v2a.75.75 0 01-1.5 0V7c0-.69-.56-1.25-1.25-1.25h-2.25V7a.75.75 0 01-1.5 0V5.75h-4.5V7a.75.75 0 01-1.5 0V5.75H6c-.69 0-1.25.56-1.25 1.25v3.25H9a.75.75 0 010 1.5H4.75V19c0 .69.56 1.25 1.25 1.25h3a.75.75 0 010 1.5H6A2.75 2.75 0 013.25 19V7A2.75 2.75 0 016 4.25h2.25V3A.75.75 0 019 2.25zm8 10a.75.75 0 01.75.75v3.25H21a.75.75 0 010 1.5h-3.25V21a.75.75 0 01-1.5 0v-3.25H13a.75.75 0 010-1.5h3.25V13a.75.75 0 01.75-.75z" _fill="#1D1D1B"/>'
  }
})
