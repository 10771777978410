<template>
    <figure :class="classObject">
        <slot
            name="content"
            v-if="isCaptionBottom"
        />
        <figcaption
            v-if="!!$slots.caption"
            class="z-figure__caption"
            :class="`z-figure__caption--${captionPosition}`"
        >
            <slot name="caption" />
        </figcaption>
        <slot
            name="content"
            v-if="isCaptionTop"
        />
    </figure>
</template>

<script>
export default {
    name: 'z-figure',
    props: {
        captionPosition: {
            type: String,
            default: 'top',
            validator: prop => ['top', 'bottom', 'over'].includes(prop)
        },
        styled: Boolean
    },
    computed: {
        classObject () {
            return [
                'z-figure',
                {
                    'z-figure--styled': this.styled
                }
            ]
        },
        isCaptionTop () {
            return this.captionPosition === 'top' || this.captionPosition === 'over'
        },
        isCaptionBottom () {
            return this.captionPosition === 'bottom'
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
