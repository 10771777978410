<template>
    <component
        :is="tag"
        class="z-caption"
        :class="classObject"
    >
        <span class="z-caption__text"><slot /></span>
    </component>
</template>

<script>
export default {
    name: 'z-caption',
    props: {
        tag: {
            type: String,
            default: 'p',
            validator: prop => ['span', 'p', 'caption', 'figcaption', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(prop)
        },
        weight: {
            type: String,
            validator: prop => ['100', '200', '300', '400', '500', '600', '700', '800', '900'].includes(prop)
        },
        family: {
            type: String,
            validator: prop => ['futura', 'allegria', 'fact', 'liberteen', 'steelfish'].includes(prop)
        },
        color: {
            type: String,
            default: 'black'
        },
        borderDecor: Boolean,
        italic: Boolean,
        uppercase: Boolean,
        lowercase: Boolean,
        capitalize: Boolean
    },
    computed: {
        classObject () {
            let arr = [
                {
                    'u-italic': this.italic,
                    'u-uppercase': this.uppercase,
                    'u-lowercase': this.lowercase,
                    'u-capitalize': this.capitalize
                }
            ]

            if (this.color) arr.push(`z-caption--color-${this.color}`)
            if (this.weight) arr.push(`z-caption--weight-${this.weight}`)
            if (this.family) arr.push(`u-${this.family}-font`)
            if (this.borderDecor) arr.push(`z-caption--border-decor`)

            return arr
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
