/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'zoom-in': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 3.5a7.25 7.25 0 104.859 12.63l.01-.01a7.23 7.23 0 002.38-5.37 7.25 7.25 0 00-7.249-7.25zm0 16a8.712 8.712 0 005.358-1.831l3.862 3.862a.75.75 0 101.06-1.061l-3.819-3.82A8.75 8.75 0 1012 19.5zm.75-11.75a.75.75 0 00-1.5 0V10H9a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0V11.5H15a.75.75 0 000-1.5h-2.25V7.75z" _fill="#1D1D1B"/>'
  }
})
