/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'download': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.25 13.44V3h1.5v10.44l3.782-3.026.936 1.172-5 4-.468.374-.469-.374-5-4 .938-1.172 3.781 3.026zM20 19.25v1.5H4v-1.5h16z" _fill="#1D1D1B"/>'
  }
})
