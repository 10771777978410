<template>
    <div class="last-event" v-if="data">
        <z-card full-height>
            <template v-slot:content>
                <span class="last-event__type">{{text.type}}</span>
                <span class="last-event__date">{{data[0].date}}</span>
                <z-caption tag="h3" class="last-event__caption">
                    {{data[0].name}}
                    <z-button
                        tag="a"
                        size="s"
                        :href="`${prefixUrl}/investors/events-calendar/`"
                        class="js-analytics"
                        :data-analytics-label="`ClickEventsCalendarInvestors${capitalizeLang}`"
                        data-analytics-action="click"
                        data-analytics-category="content"
                    >{{text.linkCalendar}}</z-button>
                </z-caption>
            </template>
        </z-card>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'last-event',
    props: {
        data: {
            type: Array,
            default: () => {
                return null
            }
        }
    },
    data () {
        return {
            prefixUrl: this.$root.lang === 'en' ? '/en' : '',
            text: {
                type: localize({
                    'ru': 'События',
                    'en': 'Events'
                }),
                linkCalendar: localize({
                    'ru': 'Календарь событий',
                    'en': 'Events Calendar'
                })
            }
        }
    },
    computed: {
        capitalizeLang: function () {
            return this.$options.filters.capitalizeString(this.$root.lang)
        }
    }
}
</script>

<style lang="scss">
    .last-event__caption {
        margin-bottom: 0;
    }

    .last-event__caption .z-caption__text {
        display: flex;
        align-items: flex-end;
    }

    .last-event__caption .z-caption__text .z-button {
        margin-left: 20px;
        flex-shrink: 0;
    }

    .last-event__type {
        display: block;
        font-weight: 300;
        font-size: 15px;
        line-height: 1;
        text-transform: uppercase;
        position: relative;
        display: flex;
        align-items: center;
    }

    .last-event__type:after {
        content: '';
        width: 60%;
        position: relative;
        margin-left: 20px;
        height: 1px;
        background-color: #c4b078;
        display: inline-block;
        margin-top: -5px;
    }

    .last-event__date {
        display: block;
        margin-top: 60px;
    }

    @include breakpoint(mobile) {
        .last-event__caption .z-caption__text {
            flex-direction: column;
            align-items: flex-start;
            margin-top: 8px;
        }

        .last-event__caption .z-caption__text .z-button {
            margin-left: 0;
            margin-top: 12px;
        }

        .last-event__date {
            margin-top: 24px;
        }
    }
</style>
