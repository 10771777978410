/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'instagram': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.02 7.36a4.621 4.621 0 00-4.62 4.62c0 2.571 2.049 4.62 4.62 4.62 2.531 0 4.62-2.049 4.62-4.62 0-2.531-2.089-4.62-4.62-4.62zm0 7.633a3.01 3.01 0 01-3.013-3.013c0-1.647 1.326-2.973 3.013-2.973a2.967 2.967 0 012.973 2.973c0 1.687-1.326 3.013-2.973 3.013zM17.886 7.2a1.08 1.08 0 00-1.085-1.085 1.08 1.08 0 00-1.084 1.085A1.08 1.08 0 0016.8 8.283 1.08 1.08 0 0017.886 7.2zm3.054 1.084c-.08-1.446-.402-2.732-1.447-3.776-1.044-1.045-2.33-1.366-3.776-1.447-1.487-.08-5.947-.08-7.434 0-1.446.08-2.691.402-3.776 1.447C3.462 5.55 3.14 6.837 3.06 8.283c-.08 1.487-.08 5.947 0 7.434.08 1.446.402 2.691 1.447 3.776 1.085 1.045 2.33 1.366 3.776 1.447 1.487.08 5.947.08 7.434 0 1.446-.08 2.732-.402 3.776-1.447 1.045-1.084 1.366-2.33 1.447-3.776.08-1.487.08-5.947 0-7.434zm-1.929 9c-.281.804-.924 1.407-1.687 1.728-1.206.482-4.018.362-5.304.362-1.326 0-4.138.12-5.303-.362a3.046 3.046 0 01-1.728-1.727c-.482-1.166-.362-3.978-.362-5.304 0-1.286-.12-4.098.362-5.304A3.1 3.1 0 016.717 4.99c1.165-.482 3.977-.362 5.303-.362 1.286 0 4.098-.12 5.304.362.763.281 1.366.924 1.687 1.687.482 1.206.362 4.018.362 5.304 0 1.326.12 4.138-.362 5.303z" _fill="#99BBDE"/>'
  }
})
