<template>
    <z-list-item
        class="z-filelist-item"
        :class="{
            'z-filelist-item--with-icon': icon || this.$slots['icon']
        }"
    >
        <div class="z-filelist-item__body">
            <div class="z-filelist-item__icon" v-if="icon || this.$slots['icon']">
                <z-icon
                    v-if="icon"
                    :name="icon"
                    width="24"
                    height="24"
                />
                <slot v-else name="icon" />
            </div>
            <div class="z-filelist-item__slot">
                <slot />
                <div class="z-filelist-item__text" v-if="date || info">
                    <span
                        v-if="date"
                        class="z-filelist-item__date"
                    >{{ buildDate(date) }}</span>
                    <span
                        class="z-filelist-item__info"
                        v-if="info"
                        v-html="info"
                    ></span>
                </div>
            </div>
        </div>
    </z-list-item>
</template>

<script>
export default {
    name: 'z-filelist-item',
    props: {
        icon: {
            type: String,
            default: ''
        },
        info: {
            type: String,
            default: ''
        },
        date: {
            type: [String, Number],
            default: ''
        }
    },
    methods: {
        buildDate (str) {
            return typeof (str) === 'number' ? this.$options.filters.momentFilter(str, 'DD.MM.YYYY') : str
        }
    }
}
</script>
