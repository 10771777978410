/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'long-arrow': {
    width: 43,
    height: 13,
    viewBox: '0 0 43 13',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M37.757 1.197L42.53 5.97a.75.75 0 010 1.06l-4.773 4.773a.75.75 0 01-1.06-1.06l3.492-3.493H0v-1.5h40.19l-3.493-3.493a.75.75 0 111.06-1.06z" _fill="#1D1D1B"/>'
  }
})
