<template>
    <div
        :href="link"
        :class="classObject"
        @click="$emit('click')"
        :is="link ? 'a' : 'div'"
        :target="buildTarget(link)"
    >
        <div class="z-card__body">
            <header
                class="z-card__header"
                v-if="this.$slots['top'] || headerTitle"
            >
                <div class="z-card__header-title" v-if="headerTitle">
                    <span v-html="headerTitle"></span>
                </div>
                <slot name="top" />
            </header>
            <div class="z-card__main">
                <span
                    class="z-card__title"
                    v-if="this.$slots['title']"
                >
                    <slot name="title" />
                </span>
                <div
                    v-if="this.$slots['content']"
                    class="z-card__content"
                >
                    <slot name="content"></slot>
                </div>
            </div>
            <footer
                class="z-card__footer"
                v-if="this.$slots['bottom']"
            >
                <slot name="bottom"></slot>
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    name: 'z-card',
    props: {
        link: String,
        theme: String,
        fullHeight: Boolean,
        headerTitle: String,
        target: Boolean
    },
    methods: {
        buildTarget (href) {
            if (this.target) {
                return '_blank'
            }
            return /^http|^\/\//.test(href) ? '_blank' : false
        }
    },
    computed: {
        classObject () {
            return [
                'z-card',
                {
                    'z-card--link': this.link,
                    'z-card--full-height': this.fullHeight,
                    [`z-card--theme-${this.theme}`]: this.theme
                }
            ]
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
