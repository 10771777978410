/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'share': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.423 5.904a2.173 2.173 0 114.346 0 2.173 2.173 0 01-4.346 0zm2.173-3.673a3.673 3.673 0 00-3.552 4.61l-5.26 2.63a3.673 3.673 0 100 4.558l5.26 2.63a3.673 3.673 0 10.672-1.341l-5.26-2.63a3.678 3.678 0 000-1.876l5.26-2.63a3.673 3.673 0 102.88-5.952zM3.731 11.75a2.173 2.173 0 114.346 0 2.173 2.173 0 01-4.346 0zm11.692 5.846a2.173 2.173 0 114.346 0 2.173 2.173 0 01-4.346 0z" _fill="#1D1D1B"/>'
  }
})
