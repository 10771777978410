<template>
    <div
        class="site-selector"
        :class="{'site-selector--index': isIndex}"
    >
        <z-link
            :href="altDir"
        >{{ text.lang }}</z-link>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'site-selector',
    props: {
        isIndex: Boolean,
        currentLang: String,
        altDir: String
    },
    data () {
        return {
            langPrefix: localize({
                'ru': '/en',
                'en': ''
            }),
            text: {
                lang: localize({
                    'ru': 'En',
                    'en': 'Ru'
                })
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
