/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar-check': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9 2.25a.75.75 0 01.75.75v1.25h4.5V3a.75.75 0 011.5 0v1.25H18A2.75 2.75 0 0120.75 7v5a.75.75 0 01-1.5 0v-.25H4.75V19c0 .69.56 1.25 1.25 1.25h2a.75.75 0 010 1.5H6A2.75 2.75 0 013.25 19V7A2.75 2.75 0 016 4.25h2.25V3A.75.75 0 019 2.25zm-.75 3.5H6c-.69 0-1.25.56-1.25 1.25v3.25h14.5V7c0-.69-.56-1.25-1.25-1.25h-2.25V7a.75.75 0 01-1.5 0V5.75h-4.5V7a.75.75 0 01-1.5 0V5.75zm12.137 8.72a.75.75 0 010 1.06l-6.2 6.2a.75.75 0 01-1.06 0l-2.657-2.657a.75.75 0 111.06-1.06l2.127 2.126 5.67-5.67a.75.75 0 011.06 0z" _fill="#1D1D1B"/>'
  }
})
