/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'telegram': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5 3a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2H5zm10.558 13.962a.69.69 0 01-.6-.072c-1.097-.722-3.032-2.031-3.032-2.031l-1.677 1.461a.497.497 0 01-.44.104.46.46 0 01-.326-.288 614.538 614.538 0 01-1.197-3.308s-1.682-.492-2.965-.846a.422.422 0 01-.32-.382.415.415 0 01.293-.4c1.522-.52 4.217-1.45 6.747-2.323 2.221-.767 4.316-1.49 5.377-1.852a.462.462 0 01.432.07c.119.094.173.24.14.38-.21.91-.592 2.62-.982 4.366-.405 1.811-.819 3.661-1.058 4.701a.597.597 0 01-.392.42zM9.904 15.32l-.91-2.769 6.443-3.798a.163.163 0 01.204.032c.048.058.04.14-.018.189l-5.517 4.593-.202 1.753z" _fill="#99BBDE"/>'
  }
})
