<template>
    <div :class="[
            'scroll-top',
            { 'scroll-top--hidden' : !isShown }
        ]"
    >
        <button class="scroll-top__btn" @click="scrollTop">
            <z-icon
                name="long-arrow"
                width="32"
                height="24"
            />
        </button>
    </div>
</template>

<script>
import Velocity from 'velocity-animate'

export default {
    name: 'scroll-top',
    data () {
        return {
            isShown: false
        }
    },
    methods: {
        scrollTop () {
            Velocity(document.body, 'scroll', { offset: 0 })
        },
        setState (direction, curScroll) {
            if (curScroll < 500) {
                this.isShown = false
            } else {
                this.isShown = direction !== 1
            }
        },
        hideBtn () {
            this.isShown = false
        },
        checkScroll () {
            const doc = document.documentElement

            let prevScroll = window.scrollY || doc.scrollTop
            let curScroll
            let direction = 0
            let prevDirection = 0

            const checkScroll = () => {
                // Find the direction of scroll
                // 0 - initial, 1 - up, 2 - down
                curScroll = window.scrollY || doc.scrollTop

                if (curScroll > prevScroll) {
                    // scrolled up
                    direction = 2
                } else if (curScroll < prevScroll) {
                    // scrolled down
                    direction = 1
                }

                if (direction !== prevDirection) {
                    this.setState(direction, curScroll)
                }

                prevScroll = curScroll
            }

            window.addEventListener('scroll', checkScroll)
        }

    },
    mounted () {
        this.checkScroll()
    },
    beforeDestroy () {
        window.removeEventListener('scroll', 'checkScroll')
    }
}
</script>

<style lang="scss">
.scroll-top {
    bottom: 10%;
    right: 64px;
    position: fixed;
    z-index: 99;
    opacity: 1;
    transition: opacity $transition;

    @include breakpoint (laptop) {
        right: $token-spacers-l;
        bottom: 150px;
    }

    @include breakpoint (tablet) {
        right: 40px;
    }

    @include breakpoint (v-tablet) {
        bottom: $token-spacers-m;
        right: $token-spacers-m;
    }

    @include breakpoint (mobile) {
        display: none;
    }

    &--hidden {
        opacity: 0;
        pointer-events: none;
    }

    &__btn {
        width: 60px;
        height: 60px;
        border: none;
        background: $token-colors-crisp-white;
        border-radius: 50%;
        padding: 0;
        position: relative;
        cursor: pointer;
        outline: none;
        background: $token-colors-crisp-white;
        border: 2px solid $token-colors-grey-light;

        .z-icon {
            transform: rotate(-90deg);
            width: 57% !important;
        }

        @include breakpoint (tablet) {
            width: 40px;
            height: 40px;
        }
    }
}
</style>
