<template>
    <iframe
        :src="src"
        :id="id"
        frameborder="0"
        width="100%"
        scrolling="no"
    ></iframe>
</template>

<script>
export default {
    name: 'euroland-iframe',
    props: {
        src: {
            type: String,
            default: '',
            required: true
        },
        id: {
            type: String,
            default: '',
            required: true
        }
    },
    created () {
        window.addEventListener('load', () => {
            window.EurolandToolIntegrationObject.set(this.id)
        })
    },
    mounted () {
        this.$nextTick(() => {
            if (window.EurolandToolIntegrationObject) {
                window.EurolandToolIntegrationObject.set(this.id)
            }
        })
    }
}
</script>
