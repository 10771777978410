/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'minus': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M20 12.75H4a.75.75 0 010-1.5h16a.75.75 0 010 1.5z" _fill="#1D1D1B"/>'
  }
})
