/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar-minus': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9 1.75a.75.75 0 01.75.75v1.25h4.5V2.5a.75.75 0 011.5 0v1.25H18a2.75 2.75 0 012.75 2.75v2a.75.75 0 01-1.5 0v-2c0-.69-.56-1.25-1.25-1.25h-2.25V6.5a.75.75 0 01-1.5 0V5.25h-4.5V6.5a.75.75 0 01-1.5 0V5.25H6c-.69 0-1.25.56-1.25 1.25v3.25H9a.75.75 0 010 1.5H4.75v7.25c0 .69.56 1.25 1.25 1.25h3a.75.75 0 010 1.5H6a2.75 2.75 0 01-2.75-2.75v-12A2.75 2.75 0 016 3.75h2.25V2.5A.75.75 0 019 1.75zm3.25 14.75a.75.75 0 01.75-.75h8a.75.75 0 010 1.5h-8a.75.75 0 01-.75-.75z" _fill="#1D1D1B"/>'
  }
})
