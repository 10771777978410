<template>
    <div class="self-brands-portfolio">
        <div class="self-brands-portfolio__desktop">
            <div class="self-brands-portfolio__title">
                <a class="self-brands-portfolio__link" :href="data.vodka.link">{{data.vodka.title}}</a>
            </div>
            <div class="self-brands-portfolio__title">
                <a class="self-brands-portfolio__link" :href="data.cognac.link">{{data.cognac.title}}</a>
            </div>
            <div class="self-brands-portfolio__title">
                <a class="self-brands-portfolio__link" :href="data.wine.link">{{data.wine.title}}</a>
            </div>
            <div class="self-brands-portfolio__title">
                <a class="self-brands-portfolio__link" :href="data.gin.link">{{data.gin.title}}</a>
            </div>
            <div class="self-brands-portfolio__title">
                <a class="self-brands-portfolio__link" :href="data.whiskey.link">{{data.whiskey.title}}</a>
            </div>
            <div class="self-brands-portfolio__title">
                <a class="self-brands-portfolio__link" :href="data.other.link">
                    {{data.other.title}}
                </a>
                <p class="self-brands-portfolio__subtitle">{{ data.other.subtitle }}</p>
            </div>

            <div class="self-brands-portfolio__title--border"></div>

            <div class="self-brands-portfolio__category self-brands-portfolio__category--left self-brands-portfolio__category--super">
                <span>{{ category.superPremium }}</span>
            </div>
                <z-image
                    class="self-brands-portfolio__item"
                    :src="data.vodka.img.superPremium"
                    :alt="`${category.superPremium} ${data.vodka.title}`"
                />
                <z-image
                    class="self-brands-portfolio__item"
                    :src="data.cognac.img.superPremium"
                    :alt="`${category.superPremium} ${data.cognac.title}`"
                />
                <z-image
                    class="self-brands-portfolio__item"
                    :src="data.wine.img.superPremium"
                    :alt="`${category.superPremium} ${data.wine.title}`"
                />
                <z-image
                    class="self-brands-portfolio__item"
                    :src="data.gin.img.superPremium"
                    :alt="`${category.superPremium} ${data.gin.title}`"
                />
                <z-image
                    class="self-brands-portfolio__item"
                    :src="data.whiskey.img.superPremium"
                    :alt="`${category.superPremium} ${data.whiskey.title}`"
                />
                <z-image
                    class="self-brands-portfolio__item"
                    :src="data.other.img.superPremium"
                    :alt="`${category.superPremium} ${data.other.title}`"
                />

            <div class="self-brands-portfolio__shelf">
                <z-image
                    class="self-brands-portfolio__shelf--img"
                    src="/images/investors/investment-case/shelf.png"
                    alt="shelf"
                />
            </div>

            <div class="self-brands-portfolio__category self-brands-portfolio__category--left self-brands-portfolio__category--premium">
                <span>{{ category.premium }}</span>
            </div>
            <z-image
                    class="self-brands-portfolio__item"
                    :src="data.vodka.img.premium"
                    :alt="`${category.premium} ${data.vodka.title}`"
                />
                <z-image
                    class="self-brands-portfolio__item"
                    :src="data.cognac.img.premium"
                    :alt="`${category.premium} ${data.cognac.title}`"
                />
                <z-image
                    class="self-brands-portfolio__item"
                    :src="data.wine.img.premium"
                    :alt="`${category.premium} ${data.wine.title}`"
                />
                <z-image
                    class="self-brands-portfolio__item"
                    :src="data.gin.img.premium"
                    :alt="`${category.premium} ${data.gin.title}`"
                />
                <z-image
                    class="self-brands-portfolio__item"
                    :src="data.whiskey.img.premium"
                    :alt="`${category.premium} ${data.whiskey.title}`"
                />
                <z-image
                    class="self-brands-portfolio__item"
                    :src="data.other.img.premium"
                    :alt="`${category.premium} ${data.other.title}`"
                />

            <div class="self-brands-portfolio__shelf">
                <z-image
                    class="self-brands-portfolio__shelf--img"
                    src="/images/investors/investment-case/shelf.png"
                    alt="shelf"
                />
            </div>

            <div class="self-brands-portfolio__category self-brands-portfolio__category--left self-brands-portfolio__category--middle">
                <span>{{ category.middle }}</span>
            </div>
            <z-image
                    class="self-brands-portfolio__item"
                    :src="data.vodka.img.middle"
                    :alt="`${category.middle} ${data.vodka.title}`"
                />
                <z-image
                    class="self-brands-portfolio__item"
                    :src="data.cognac.img.middle"
                    :alt="`${category.middle} ${data.cognac.title}`"
                />
                <z-image
                    class="self-brands-portfolio__item"
                    :src="data.wine.img.middle"
                    :alt="`${category.middle} ${data.wine.title}`"
                />
                <z-image
                    class="self-brands-portfolio__item"
                    :src="data.gin.img.middle"
                    :alt="`${category.middle} ${data.gin.title}`"
                />
                <z-image
                    class="self-brands-portfolio__item"
                    :src="data.whiskey.img.middle"
                    :alt="`${category.middle} ${data.whiskey.title}`"
                />
                <z-image
                    class="self-brands-portfolio__item"
                    :src="data.other.img.middle"
                    :alt="`${category.middle} ${data.other.title}`"
                />

            <div class="self-brands-portfolio__shelf">
                <z-image
                    class="self-brands-portfolio__shelf--img"
                    src="/images/investors/investment-case/shelf.png"
                    alt="shelf"
                />
            </div>

            <div class="self-brands-portfolio__category self-brands-portfolio__category--left self-brands-portfolio__category--econom">
                <span>{{ category.econom }}</span>
            </div>
            <z-image
                    class="self-brands-portfolio__item"
                    :src="data.vodka.img.econom"
                    :alt="`${category.econom} ${data.vodka.title}`"
                />
                <z-image
                    class="self-brands-portfolio__item"
                    :src="data.cognac.img.econom"
                    :alt="`${category.econom} ${data.cognac.title}`"
                />
                <z-image
                    class="self-brands-portfolio__item"
                    :src="data.wine.img.econom"
                    :alt="`${category.econom} ${data.wine.title}`"
                />
                <z-image
                    class="self-brands-portfolio__item"
                    :src="data.gin.img.econom"
                    :alt="`${category.econom} ${data.gin.title}`"
                />
                <z-image
                    class="self-brands-portfolio__item"
                    :src="data.whiskey.img.econom"
                    :alt="`${category.econom} ${data.whiskey.title}`"
                />
                <z-image
                    class="self-brands-portfolio__item"
                    :src="data.other.img.econom"
                    :alt="`${category.econom} ${data.other.title}`"
                />

            <div class="self-brands-portfolio__shelf">
                <z-image
                    class="self-brands-portfolio__shelf--img"
                    src="/images/investors/investment-case/shelf.png"
                    alt="shelf"
                />
            </div>
        </div>

        <div class="self-brands-portfolio__mobile">
            <z-accordion initial="first" multiple>
                <z-accordion-item
                    class="self-brands-portfolio__accordion self-brands-portfolio__accordion--super"
                    id="self-super-premium"
                >
                    <template v-slot:header>
                        <span>{{ category.superPremium }}</span>
                    </template>

                    <template v-slot:body>
                        <ul class="self-brands-portfolio__accordion--list">
                            <li
                                v-for="(item, index) in data"
                                :key="index"
                                class="self-brands-portfolio__accordion--item"
                            >
                                <a
                                    v-if="item.img.superPremium"
                                    class="self-brands-portfolio__link"
                                    :href="item.link"
                                >
                                    {{item.title}}
                                </a>
                                <p v-if="item.subtitle" class="self-brands-portfolio__subtitle">
                                    {{ item.subtitle }}
                                </p>
                                <z-image
                                    v-if="item.img.superPremium"
                                    :src="item.img.superPremium"
                                    :alt="`${category.superPremium} ${item.title}`"
                                />
                            </li>
                        </ul>
                    </template>
                </z-accordion-item>

                <z-accordion-item
                    class="self-brands-portfolio__accordion self-brands-portfolio__accordion--premium"
                    id="self-premium"
                >
                    <template v-slot:header>
                        <span>{{ category.premium }}</span>
                    </template>

                    <template v-slot:body>
                        <ul class="self-brands-portfolio__accordion--list">
                            <li
                                v-for="(item, index) in data"
                                :key="index"
                                class="self-brands-portfolio__accordion--item"
                            >
                                <a
                                v-if="item.img.premium"
                                class="self-brands-portfolio__link"
                                :href="item.link"
                                >
                                    {{item.title}}
                                </a>
                                <p v-if="item.subtitle" class="self-brands-portfolio__subtitle">
                                    {{ item.subtitle }}
                                </p>
                                <z-image
                                    v-if="item.img.premium"
                                    :src="item.img.premium"
                                    :alt="`${category.premium} ${item.title}`"
                                />
                            </li>
                        </ul>
                    </template>
                </z-accordion-item>

                <z-accordion-item
                    class="self-brands-portfolio__accordion self-brands-portfolio__accordion--middle"
                    id="self-middle"
                >
                    <template v-slot:header>
                        <span>{{ category.middle }}</span>
                    </template>

                    <template v-slot:body>
                        <ul class="self-brands-portfolio__accordion--list">
                            <li
                                v-for="(item, index) in data"
                                :key="index"
                                class="self-brands-portfolio__accordion--item"
                            >
                                <a
                                v-if="item.img.middle"
                                class="self-brands-portfolio__link"
                                :href="item.link"
                                >
                                    {{item.title}}
                                </a>
                                <p v-if="item.subtitle" class="self-brands-portfolio__subtitle">
                                    {{ item.subtitle }}
                                </p>
                                <z-image
                                    v-if="item.img.middle"
                                    :src="item.img.middle"
                                    :alt="`${category.middle} ${item.title}`"
                                />
                            </li>
                        </ul>
                    </template>
                </z-accordion-item>

                <z-accordion-item
                    class="self-brands-portfolio__accordion self-brands-portfolio__accordion--econom"
                    id="self-econom"
                >
                    <template v-slot:header>
                        <span>{{ category.econom }}</span>
                    </template>

                    <template v-slot:body>
                        <ul class="self-brands-portfolio__accordion--list">
                            <li
                                v-for="(item, index) in data"
                                :key="index"
                                class="self-brands-portfolio__accordion--item"
                            >
                                <a
                                v-if="item.img.econom"
                                class="self-brands-portfolio__link"
                                :href="item.link"
                                >
                                    {{item.title}}
                                </a>
                                <p v-if="item.subtitle" class="self-brands-portfolio__subtitle">
                                    {{ item.subtitle }}
                                </p>
                                <z-image
                                    v-if="item.img.econom"
                                    :src="item.img.econom"
                                    :alt="`${category.econom} ${item.title}`"
                                />
                            </li>
                        </ul>
                    </template>
                </z-accordion-item>
            </z-accordion>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'self-brands-portfolio',
    data () {
        return {
            category: {
                superPremium: localize({
                    ru: 'Супер-премиум',
                    en: 'Super premium'
                }),
                premium: localize({
                    ru: 'Премиум',
                    en: 'Premium'
                }),
                middle: localize({
                    ru: 'Средний',
                    en: 'Middle'
                }),
                econom: localize({
                    ru: 'Эконом',
                    en: 'Mainstream'
                })
            },
            data: {
                vodka: {
                    title: localize({
                        ru: 'Водка',
                        en: 'Vodka'
                    }),
                    subtitle: '',
                    img: {
                        superPremium: '/images/investors/investment-case/self-super-premium-vodka.png',
                        premium: '/images/investors/investment-case/self-premium-vodka.png',
                        middle: '/images/investors/investment-case/self-middle-vodka.png',
                        econom: '/images/investors/investment-case/self-mainstream-vodka.png'
                    },
                    link: localize({
                        ru: '/brands/vodka/',
                        en: '/en/brands/vodka/'
                    })
                },

                cognac: {
                    title: localize({
                        ru: 'Коньяк / Ликер',
                        en: 'Cognac / Liqueur'
                    }),
                    subtitle: '',
                    img: {
                        superPremium: '',
                        premium: '/images/investors/investment-case/self-premium-cognac.png',
                        middle: '/images/investors/investment-case/self-middle-cognac.png',
                        econom: '/images/investors/investment-case/self-mainstream-cognac.png'
                    },
                    link: localize({
                        ru: '/brands/russian-brandy/',
                        en: '/en/brands/russian-brandy-and-cis/'
                    })
                },

                wine: {
                    title: localize({
                        ru: 'Вино',
                        en: 'Wines'
                    }),
                    subtitle: '',
                    img: {
                        superPremium: '/images/investors/investment-case/self-super-premium-wine.png',
                        premium: '/images/investors/investment-case/self-premium-wine.png',
                        middle: '/images/investors/investment-case/self-middle-wine.png',
                        econom: ''
                    },
                    link: localize({
                        ru: '/brands/wine/',
                        en: '/en/brands/wine/'
                    })
                },

                gin: {
                    title: localize({
                        ru: 'Джин',
                        en: 'Gin'
                    }),
                    subtitle: '',
                    img: {
                        superPremium: '',
                        premium: '/images/investors/investment-case/self-premium-gin.png',
                        middle: '',
                        econom: ''
                    },
                    link: localize({
                        ru: '/brands/gin/',
                        en: '/en/brands/gin/'
                    })
                },

                whiskey: {
                    title: localize({
                        ru: 'Виски',
                        en: 'Whiskey'
                    }),
                    subtitle: '',
                    img: {
                        superPremium: '',
                        premium: '/images/investors/investment-case/self-premium-whiskey.png',
                        middle: '/images/investors/investment-case/self-middle-whiskey.png',
                        econom: '/images/investors/investment-case/self-mainstream-whiskey.png'
                    },
                    link: localize({
                        ru: '/brands/whisky/',
                        en: '/en/brands/whisky/'
                    })
                },

                other: {
                    title: localize({
                        ru: 'Прочие',
                        en: 'Other spirits'
                    }),
                    subtitle: localize({
                        ru: '(настойки, ром, др.)',
                        en: '(infusions, rum, etc.)'
                    }),
                    img: {
                        superPremium: '/images/investors/investment-case/self-super-premium-other.png',
                        premium: '/images/investors/investment-case/self-premium-other.png',
                        middle: '/images/investors/investment-case/self-middle-other.png',
                        econom: ''
                    },
                    link: localize({
                        ru: '/brands/',
                        en: '/en/brands/'
                    })
                }
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
