<template>
    <div class="news-filter" v-if="data">
        <div class="news-filter__wrapper">
            <div class="news-filter__title" v-html="text.title"></div>
            <z-button class="news-filter__clear" kind="text" @click="onClear" v-if="isClearActive">
                <span v-html="text.clear"></span>
                <template v-slot:right>
                    <z-icon name="close" width="12" height="12"></z-icon>
                </template>
            </z-button>
        </div>
        <z-select
            name="year"
            class="news-filter__select"
            :data="yearsOption"
            :placeholder="text.year"
            @change="onChange"
            initial-opened
        ></z-select>
        <z-select-multi
            name="theme"
            class="news-filter__select"
            :data="themeOption"
            :placeholder="text.theme"
            @change="onChange"
            initial-opened
        ></z-select-multi>
        <z-button class="news-filter__apply" @click="reloadPage" v-html="text.apply"></z-button>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import { queryString } from '@/utils/queryString'

export default {
    name: 'news-filter',
    props: {
        data: {
            type: Object,
            required: true
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            text: {
                title: localize({
                    ru: 'Фильтр',
                    en: 'Filter by'
                }),
                clear: localize({
                    ru: 'Очистить фильтр',
                    en: 'Clear filter'
                }),
                apply: localize({
                    ru: 'Применить',
                    en: 'Apply'
                }),
                theme: localize({
                    ru: 'Тема',
                    en: 'Theme'
                }),
                year: localize({
                    ru: 'Год',
                    en: 'Year'
                })
            },
            params: {
                year: '',
                theme: []
            }
        }
    },
    computed: {
        isClearActive () {
            return Boolean(
                this.params?.year ||
                this.params?.theme.length
            )
        },
        yearsOption () {
            if (!this.data.year) return []
            return this.data.year.map(item => ({...item, disabled: false}))
        },
        themeOption () {
            if (!this.data.theme) return []
            return this.data.theme.map(item => ({...item, disabled: false}))
        }
    },
    methods: {
        onChange (data) {
            this.params[data.name] = data.value
            this.send()
        },
        clearParams () {
            this.params = {
                year: '',
                theme: []
            }
        },
        reloadPage() {
            location.reload()
        },
        send () {
            queryString(this.clearEmptyParams(this.params))
        },
        onClear () {
            this.clearParams()
            this.send()
            this.reloadPage()
        },
        clearEmptyParams (obj) {
            let params = Object.assign({}, obj)

            Object.keys(params).forEach(function (key, index) {
                if (Array.isArray(params[key])) {
                    if (!(params[key].filter(val => { return val !== null && val !== undefined && val !== '' && val !== false }).length)) delete params[key]
                } else if (!params[key]) {
                    delete params[key]
                }
            })

            return params
        },
        sanitizeValue (text) {
            return text
                // htmlspecialchars
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"/g, '&quot;')
                .replace(/'/g, '&#039;')
                // addslashes
                .replace(/\\/g, '\\\\')
                // eslint-disable-next-line
                .replace(/\u0008/g, '\\b')
                .replace(/\t/g, '\\t')
                .replace(/\n/g, '\\n')
                .replace(/\f/g, '\\f')
                .replace(/\r/g, '\\r')
                .replace(/'/g, '\\\'')
                .replace(/"/g, '\\"')
        },
        getGetParams (url = window.location) {
            let params = {}

            new URL(url).searchParams.forEach((val, key) => {
                if (key.includes('[]')) {
                    if (!params[key.replace('[]', '')]) params[key.replace('[]', '')] = []
                    if (!params[key.replace('[]', '')].includes(val)) params[key.replace('[]', '')].push(this.sanitizeValue(val))
                } else {
                    params[key] = this.sanitizeValue(val)
                }
            })

            return params
        }
    },
    mounted () {
        this.params = {...this.params, ...this.getGetParams()}
    }
}
</script>

<style lang="scss">
.news-filter {
    @media screen and (max-width: 1239px) {
        margin-bottom: 64px;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;

        .z-button--kind-text {
            color: #353B47;
        }
    }

    &__title {
        color: #000;
        font-weight: 700;
        font-size: 24px;
        font-family: "Future";
    }

    &__select,
    &__apply {
        margin-top: 40px;
    }

    &__clear {
        font-weight: 400 !important;
        font-size: 15px !important;

        path {
            fill: $token-colors-kaviar-black;
        }
    }
    .z-button--kind-primary {
    background-color: #ff9573;
    font-family: "Liberty";
    font-size: 16px;
    text-transform: uppercase;
    }
}

</style>
