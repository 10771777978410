/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.47 4.47a.75.75 0 011.06 0L12 10.94l6.47-6.47a.75.75 0 111.06 1.06L13.06 12l6.47 6.47a.75.75 0 01-1.06 1.06L12 13.06l-6.47 6.47a.75.75 0 01-1.06-1.06L10.94 12 4.47 5.53a.75.75 0 010-1.06z" _fill="#1D1D1B"/>'
  }
})
