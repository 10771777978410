<template>
    <div class="subscription-form">
        <z-button
            :kind="kind"
            class="subscription-form__button"
            @click="openModal"
            size="m"
            v-html="text.button"
        ></z-button>

        <z-modal
            ref="formModal"
            id="slotModal"
            @close-modal="clearForm"
            class="subscription-form__modal"
        >
            <form
                class="subscription-form__form"
                action="submit"
            >
                <z-caption
                    class="subscription-form__title u-bottom-margin--l-important"
                    tag="h2"
                    size="m"
                    v-html="text.title"
                ></z-caption>

                <z-caption
                    class="subscription-form__text"
                    tag="p"
                    v-html="text.description"
                ></z-caption>

                <z-input
                    class="subscription-form__input"
                    @input="onChange"
                    maxlength="1500"
                    type="text"
                    name="first-name"
                    :placeholder="text.placeholder.firstName"
                    color-theme="investors"
                    required
                />

                <z-input
                    class="subscription-form__input"
                    @input="onChange"
                    type="text"
                    name="last-name"
                    :placeholder="text.placeholder.lastName"
                    color-theme="investors"
                    required
                />

                <z-input
                    class="subscription-form__input"
                    @input="onChange"
                    ref="email"
                    type="email"
                    name="email"
                    placeholder="Email*"
                    color-theme="investors"
                    required
                />

                <z-select
                    class="subscription-form__select"
                    @change="onChangeSelect"
                    :data="activityOptions"
                    name="activity"
                    color-theme="investors"
                    required
                />

                <vue-raw :raw="text.contact" class="u-bottom-margin--m"/>

                <z-checkbox
                    class="subscription-form__input u-bottom-margin--m-important"
                    @change="isChecked = !isChecked"
                    size="s"
                    name="checkbox"
                    color-theme="investors"
                    required
                >
                    <vue-raw :raw="text.checkbox" class="information-form__checkbox-caption"/>
                </z-checkbox>

                <z-button
                    @click="sendFormData"
                    class="subscription-form__submit"
                    :class="{ 'subscription-form__submit--disabled': !isValid }"
                    type="primary"
                    v-html="text.subscribe"
                ></z-button>
            </form>
        </z-modal>

        <z-modal class="subscription-form__success-modal" ref="successModal" id="successModal">
            <z-caption class="information-form__modal-title" tag="h3" v-html="text.successful"></z-caption>
            <z-caption class="information-form__modal-text" tag="p" v-html="text.subscribed"></z-caption>
        </z-modal>

    </div>
</template>

<script>
import { showNotyfications } from '@/utils/notifications'
import { subscribeToNews } from '@/api/subscription'
import { localize } from '@/utils/i18n'

export default {
    name: 'subscription-form',
    props: {
        kind: {
            type: String,
            default: 'primary'
        }
    },
    data () {
        return {
            isFormShown: false,
            isChecked: false,
            isLoading: false,
            formData: {
                title: 'Subscription form',
                'first-name': '',
                'last-name': '',
                email: '',
                lang: this.$root.app.page.lang,
                activity: 'Individual investor'
            },
            activityOptions: [
                {
                    id: 'individual_investor',
                    selected: true,
                    disabled: false,
                    text: localize({
                        'ru': 'Частный инвестор',
                        'en': 'Individual investor'
                    })
                },
                {
                    id: 'institutional_investor',
                    selected: false,
                    disabled: false,
                    text: localize({
                        'ru': 'Институциональный инвестор',
                        'en': 'Institutional investor'
                    })
                },
                {
                    id: 'other',
                    selected: false,
                    disabled: false,
                    text: localize({
                        'ru': 'Другое',
                        'en': 'Other'
                    })
                }
            ],
            text: {
                button: localize({
                    'ru': 'Подписаться на&nbsp;Email рассылку',
                    'en': 'Subscribe to&nbsp;Email alerts'
                }),
                title: localize({
                    'ru': 'Подписка на&nbsp;Email рассылку',
                    'en': 'Email Alerts Subscription'
                }),
                description: localize({
                    'ru': 'Вы&nbsp;можете подписаться на&nbsp;автоматическое получение финансовой и&nbsp;операционной информации, а&nbsp;также последних пресс-релизов на&nbsp;ваш Email. Пожалуйста, заполните форму ниже:',
                    'en': 'You can sign to&nbsp;automatically receive financial &amp;&nbsp;operational information as&nbsp;well as&nbsp;latest press-releases by&nbsp;email. Please fill the table below:'
                }),
                placeholder: {
                    firstName: localize({
                        'ru': 'Имя *',
                        'en': 'First Name *'
                    }),
                    lastName: localize({
                        'ru': 'Фамилия *',
                        'en': 'Last Name *'
                    })
                },
                contact: localize({
                    'ru': '<p class="kaviar-black-text">Чтобы отписаться от&nbsp;рассылки просьба написать сообщение на&nbsp;Email: <z-link href="mailto:a.chertkov@novabev.com">a.chertkov@novabev.com</z-link></p>',
                    'en': '<p class="kaviar-black-text">To&nbsp;unsubscribe from a&nbsp;mailing list, please send&nbsp;us a&nbsp;message&nbsp;to: <z-link href="mailto:a.chertkov@novabev.com">a.chertkov@novabev.com</z-link></p>'
                }),
                checkbox: localize({
                    'ru': 'Нажимая на&nbsp;кнопку &laquo;Подписаться&raquo;, вы&nbsp;даёте согласие на&nbsp;<z-link size="xs" href="/investors/cookie-policy/" external>обработку ваших персональных данных</z-link>.',
                    'en': 'By&nbsp;clicking on&nbsp;the Subscribe button you agree with our <z-link size="xs" href="/en/investors/cookie-policy/" external>Cookie Policy</z-link>.'
                }),
                subscribe: localize({
                    'ru': 'Подписаться',
                    'en': 'Subscribe'
                }),
                successful: localize({
                    'ru': 'Сообщение отправлено!',
                    'en': 'Your message has been sent!'
                }),
                subscribed: localize({
                    'ru': 'Ваш Email будет добавлен в&nbsp;список подписчиков.',
                    'en': 'Your email address will be&nbsp;added to&nbsp;our Subscription list.'
                })
            }
        }
    },

    computed: {
        isValid () {
            return (
                this.formData['first-name'] &&
                this.formData['last-name'] &&
                this.formData.email &&
                this.formData.activity &&
                this.isChecked &&
                (this.$refs.email && !this.$refs.email.error)
            )
        }
    },
    mounted () {
        if (window.location.hash === '#subscribe') {
            this.openModal()
        }
    },
    methods: {
        openModal () {
            this.$root.$emit('open-modal', {id: 'slotModal', index: 0})
        },
        openForm () {
            this.isFormShown = true
        },
        clearForm () {
            this.formData = {
                title: 'Subscription form',
                'first-name': '',
                'last-name': '',
                email: '',
                lang: this.formData.lang,
                activity: 'Individual investor'
            }
            this.isChecked = false
        },
        onChange (data) {
            this.formData[data.name] = data.value
        },
        onChangeSelect (obj) {
            this.formData[obj.name] = obj.value
        },
        async sendFormData () {
            if (this.isLoading) {
                return
            }

            if (this.isValid) {
                const data = new FormData()

                for (const key in this.formData) {
                    data.append(key, this.formData[key])
                }

                try {
                    this.isLoading = true
                    const response = await subscribeToNews(data)
                    if (response.status === 'error') {
                        showNotyfications(response.message, { type: 'error' })
                    } else {
                        this.$refs.formModal.closeModal()
                        this.$root.$emit('open-modal', { id: 'successModal', index: 0 })
                    }
                } catch (error) {
                    console.log(error)
                } finally {
                    this.isLoading = false
                }
            } else {
                showNotyfications('Fill in all required fields', { type: 'error' })
            }
        }
    }
}
</script>

<style lang="scss">
.subscription-form {

    &__modal{
        .z-modal__window {
            max-width: 800px;
            background-color: #fff;
        }

        .z-modal__content {
            border: 1px solid $token-colors-gold;
        }

        .z-modal__close {
            top: 3rem;
            right: 3rem;
        }
    }

    &__form{
        padding: 28px;
    }

    &__title {
        text-align: center;
        color: #ffffff;
    }

    &__text {
        margin-bottom: 48px;
    }

    &__input, &__select {
        margin-bottom: 30px !important;
    }

    &__checkbox-caption {
        font-style: 14px !important;
    }

    &__submit {
        // background-color: $token-colors-gold !important;
        margin: 0 auto;
        display: block !important;

        &--disabled {
            background-color: gray !important;
            cursor: default !important;
            pointer-events: none;
        }
    }

    &__success-modal {
         .z-modal__window {
            max-height: 250px;
            max-width: 500px;
            font-size: 18px;
        }

        .z-modal__close {
            width: 16px;
            height: 16px;
            top: 40px;
            right: 40px;

            &:before,
            &:after {
                width: 100%;
            }

            @include breakpoint (mobile) {
                top: 32px;
                right: 32px;
            }
        }
    }
}
</style>
