<template>
    <div class="z-table" :class="buildClass">
        <div class="z-table__container">
            <table>
                <caption v-if="captionText" v-html="captionText"></caption>
                <colgroup v-if="colgroup && colgroup.length">
                    <col
                        v-for="(item, index) in colgroup"
                        :key="index"
                        span="1"
                        :style="`width: ${item};`"
                    >
                </colgroup>
                <thead v-if="columns.length">
                    <tr class="z-table-row z-table-row--head">
                        <th
                            class="z-table-col z-table-col--head"
                            v-for="(item, index) in columns"
                            :key="index"
                        >
                            <vue-raw :raw="item" />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <slot />
                </tbody>
            </table>
        </div>
        <div class="z-table__footer" v-if="hasAddSlotData">
            <slot name="add" />
        </div>
    </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'z-table',
    mixins: [mixinDevice],
    props: {
        columns: {
            type: Array,
            default: () => []
        },
        colgroup: {
            type: Array,
            default: () => []
        },
        captionText: {
            type: String,
            default: ''
        },
        data: {
            type: Array,
            default: () => []
        },
        fixed: Boolean,
        financial: Boolean,
        responsive: Boolean,
        scrollable: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        hasAddSlotData () {
            return this.$slots.add
        },
        buildClass () {
            return {
                'z-table--fixed': this.fixed,
                'z-table--financial': this.financial,
                'z-table--scrollable': this.scrollable,
                'z-table--responsive': this.responsive,
                'z-table--headless': !(this.columns.length)
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
