<template>
    <section class="ip-geography-map">
        <div class="container">
            <div class="row">
                <div class="col-default-6 col-tablet-12">
                    <z-caption tag="h2" border-decor weight="300" family="fact" color="black" uppercase>
                        <span v-html="text.maintitle"></span>
                    </z-caption>
                    <z-caption tag="h3" class="ip-geography-map__subtitle">
                        <span v-html="text.caption"></span>
                    </z-caption>
                    <div class="row hide-me--tablet">
                        <div class="col-default-4">
                            <z-key-indicator
                                order-reversed
                                full-height
                                value="1 351"
                                :name="text.indicators.second">
                            </z-key-indicator>
                        </div>
                        <div class="col-default-4">
                            <z-key-indicator
                                order-reversed
                                full-height
                                value="7"
                                :name="text.indicators.third">
                            </z-key-indicator>
                        </div>
                    </div>
                    <div class="show-me--default hide-me--tablet">
                        <z-button
                            tag="a"
                            :href="getLink('/investors/investment-case/#map')"
                            class="js-analytics"
                            :data-analytics-label="`ClickGeographyMapInvestors${capitalizeLang}`"
                            data-analytics-action="click"
                            data-analytics-category="content"
                        >
                            <span v-html="text.link"></span>
                        </z-button>
                    </div>
                </div>
                <div class="col-default-6 col-tablet-7 col-mobile-12">
                    <div class="ip-geography-map__pic">
                        <img src="/images/investors/map.svg" alt="Geography Map">
                    </div>
                </div>
                <div class="col-default-12 col-tablet-4 col-mobile-12 col-default-offset-1 col-mobile-offset-0 hide-me--default show-me--tablet">
                    <div class="col-tablet-12">
                        <div class="row">
                            <div class="col-default-12 col-mobile-6">
                                <z-key-indicator
                                    order-reversed
                                    full-height
                                    value="1 351"
                                    :name="text.indicators.second">
                                </z-key-indicator>
                            </div>
                            <div class="col-default-12 col-mobile-6">
                                <z-key-indicator
                                    order-reversed
                                    full-height
                                    value="7"
                                    :name="text.indicators.third">
                                </z-key-indicator>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-default-6 hide-me--default show-me--tablet">
                    <z-button
                        tag="a"
                        :href="getLink('/investors/investment-case/#map')"
                        class="js-analytics"
                        :data-analytics-label="`ClickGeographyMapInvestors${capitalizeLang}`"
                        data-analytics-action="click"
                        data-analytics-category="content"
                    >
                        <span v-html="text.link"></span>
                    </z-button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { getLink } from '@/utils/get-link'
import { localize } from '@/utils/i18n'

export default {
    name: 'ip-geography-map',
    data () {
        return {
            text: {
                maintitle: localize({
                    ru: 'География Бизнеса',
                    en: 'Geography of Business '
                }),
                indicators: {
                    first: localize({
                        ru: 'стран экспорта',
                        en: 'countries of export'
                    }),
                    second: localize({
                        ru: '<z-tooltip content="На 31.12.2022"><span>магазина</span></z-tooltip><br> в 10 регионах',
                        en: '<z-tooltip content="As at 31.12.2022"><span>stores</span></z-tooltip>'
                    }),
                    third: localize({
                        ru: 'производственных площадок в России',
                        en: 'production facilities across Russia'
                    })
                },
                caption: localize({
                    ru: 'Уникальное положение и&nbsp;лидерство Novabev Group на&nbsp;российском рынке',
                    en: 'Novabev Group&rsquo;s unique market position and leadership on&nbsp;Russian market'
                }),
                link: localize({
                    ru: 'Подробнее',
                    en: 'More info'
                })
            }
        }
    },
    computed: {
        capitalizeLang: function () {
            return this.$options.filters.capitalizeString(this.$root.lang)
        }
    },
    methods: {
        getLink: getLink
    }
}
</script>

<style lang="scss">
.ip-geography-map {
    background: $token-colors-crisp-white;

    .container {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            display: block;
            right: 51%;
            width: 2px;
            height: 118px;
            background-color: $token-colors-gold;
            top: -28px;

            @include breakpoint (v-tablet) {
                display: none;
            }
        }
    }

    &__subtitle {
        margin-top: $token-spacers-l;

        @include breakpoint (v-tablet) {
            margin-top: $token-spacers-m;
        }

        @include breakpoint (mobile) {
            margin-top: 30px;
        }
    }

    &__subtitle-map--2 {
        display: none;

        @include breakpoint (v-tablet) {
            display: block;
            position: relative;

            &:before {
                content: '';
                display: block;
                position: absolute;
                left: -20px;
                width: 1px;
                height: 48px;
                background-color: $token-colors-gold;
                top: 0;
            }
        }
    }

    &__subtitle-map{
        margin-top: 30px;

        @include breakpoint (mobile) {
            position: relative;
            margin-left: 20px;
            margin-top: -12px;

            &:before {
                content: '';
                display: block;
                position: absolute;
                left: -20px;
                width: 1px;
                height: 48px;
                background-color: $token-colors-gold;
                top: 0;
            }
        }
    }
}
</style>
