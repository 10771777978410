import { axios } from '@/utils/request'

export function saveQualityComplaint(data) {
    return axios({
        url: '/contacts_form/save_quality/',
        method: 'post',
        data
    })
}

export function getQualityValues(parameter) {
    return axios({
        url: '/contacts_form/get_quality_values/',
        method: 'get',
        params: parameter
    })
}
