<template>
    <div class="geog-map-content" ref="containerMap">
        <div id="map_canvas"></div>
    </div>
</template>

<script>
/* eslint-disable no-undef */
export default {
    name: 'contacts-map',
    props: {
        mas: Object,
        contacts: Number
    },
    data () {
        return {
        }
    },
    mounted () {
        this.init()
    },
    methods: {
        initializeContact () {
            var myLatlng = new google.maps.LatLng(55.7522200, 37.6155600)
            var myOptions = {
                zoom: 16,
                center: myLatlng,
                styles: [
                    {
                        'elementType': 'geometry',
                        'stylers': [
                            {
                                'color': '#f5f5f5'
                            }
                        ]
                    },
                    {
                        'elementType': 'labels.icon',
                        'stylers': [
                            {
                                'visibility': 'off'
                            }
                        ]
                    },
                    {
                        'elementType': 'labels.text.fill',
                        'stylers': [
                            {
                                'color': '#616161'
                            }
                        ]
                    },
                    {
                        'elementType': 'labels.text.stroke',
                        'stylers': [
                            {
                                'color': '#f5f5f5'
                            }
                        ]
                    },
                    {
                        'featureType': 'administrative.country',
                        'elementType': 'geometry.stroke',
                        'stylers': [
                            {
                                'color': '#7b7b7b'
                            }
                        ]
                    },
                    {
                        'featureType': 'administrative.land_parcel',
                        'elementType': 'labels.text.fill',
                        'stylers': [
                            {
                                'color': '#bdbdbd'
                            }
                        ]
                    },
                    {
                        'featureType': 'landscape.natural',
                        'elementType': 'geometry.fill',
                        'stylers': [
                            {
                                'color': '#e2e2e2'
                            }
                        ]
                    },
                    {
                        'featureType': 'landscape.natural.landcover',
                        'elementType': 'geometry.fill',
                        'stylers': [
                            {
                                'color': '#f4f4f4'
                            }
                        ]
                    },
                    {
                        'featureType': 'poi',
                        'elementType': 'geometry',
                        'stylers': [
                            {
                                'color': '#eeeeee'
                            }
                        ]
                    },
                    {
                        'featureType': 'poi',
                        'elementType': 'labels.text.fill',
                        'stylers': [
                            {
                                'color': '#757575'
                            }
                        ]
                    },
                    {
                        'featureType': 'poi.park',
                        'elementType': 'geometry',
                        'stylers': [
                            {
                                'color': '#e5e5e5'
                            }
                        ]
                    },
                    {
                        'featureType': 'poi.park',
                        'elementType': 'labels.text.fill',
                        'stylers': [
                            {
                                'color': '#9e9e9e'
                            }
                        ]
                    },
                    {
                        'featureType': 'road',
                        'elementType': 'geometry',
                        'stylers': [
                            {
                                'color': '#ffffff'
                            }
                        ]
                    },
                    {
                        'featureType': 'road.arterial',
                        'elementType': 'labels.text.fill',
                        'stylers': [
                            {
                                'color': '#757575'
                            }
                        ]
                    },
                    {
                        'featureType': 'road.highway',
                        'elementType': 'geometry',
                        'stylers': [
                            {
                                'color': '#dadada'
                            }
                        ]
                    },
                    {
                        'featureType': 'road.highway',
                        'elementType': 'labels.text.fill',
                        'stylers': [
                            {
                                'color': '#616161'
                            }
                        ]
                    },
                    {
                        'featureType': 'road.local',
                        'elementType': 'labels.text.fill',
                        'stylers': [
                            {
                                'color': '#9e9e9e'
                            }
                        ]
                    },
                    {
                        'featureType': 'transit.line',
                        'elementType': 'geometry',
                        'stylers': [
                            {
                                'color': '#e5e5e5'
                            }
                        ]
                    },
                    {
                        'featureType': 'transit.station',
                        'elementType': 'geometry',
                        'stylers': [
                            {
                                'color': '#eeeeee'
                            }
                        ]
                    },
                    {
                        'featureType': 'water',
                        'elementType': 'geometry',
                        'stylers': [
                            {
                                'color': '#c9c9c9'
                            }
                        ]
                    },
                    {
                        'featureType': 'water',
                        'elementType': 'geometry.fill',
                        'stylers': [
                            {
                                'color': '#c5c5c5'
                            }
                        ]
                    },
                    {
                        'featureType': 'water',
                        'elementType': 'labels.text.fill',
                        'stylers': [
                            {
                                'color': '#9e9e9e'
                            }
                        ]
                    }
                ],
                mapTypeId: 'roadmap',
                scrollwheel: false
            }

            // Область показа маркеров
            var markersBounds = new google.maps.LatLngBounds()

            var map = new google.maps.Map(document.getElementById('map_canvas'), myOptions)
            var iconBase = '/local/components/beluga/info.contacts/templates/.default/images/'
            var icons = {
                balloon1: {
                    icon: iconBase + 'balloon1.png'
                },
                balloon2: {
                    icon: iconBase + 'balloon2.png'
                }
            }
            var features = []
            Object.keys(this.mas.CONTACTS).forEach(key => {
                const val = this.mas.CONTACTS[key]
                if (val.PROPERTIES.RU_MAP !== 'undefined') {
                    var coord = val.PROPERTIES.RU_MAP.VALUE.split(',')
                    var lat = coord[0]
                    var lng = coord[1]
                    var value = {
                        position: new google.maps.LatLng(lat, lng),
                        type: 'balloon1',
                        title: val.NAME,
                        contentTxt: val.PREVIEW_TEXT,
                        id: val.ID,
                        lat: lat,
                        lng: lng
                    }
                    features.push(value)
                }
            })
            // Create markers.
            features.forEach(function (feature) {
                var contentString = '<div class="content-marker">' +
                        '<h3>' + feature.title + '</h3>' +
                        '<div class="bodyContent">' +
                        '<p>' + feature.contentTxt + '</p>' +
                        '</div>' +
                        '</div>'

                var infowindow = new google.maps.InfoWindow({
                    content: contentString
                })
                var markerPosition = new google.maps.LatLng(feature.lat, feature.lng)
                // Добавляем координаты маркера в область
                markersBounds.extend(markerPosition)

                var marker = new google.maps.Marker({
                    position: feature.position,
                    icon: icons[feature.type].icon,
                    map: map,
                    title: feature.title
                })

                marker.addListener('click', function () {
                    infowindow.open(map, marker)
                })
            })
            if (this.contacts > 1) {
                map.setCenter(markersBounds.getCenter(), map.fitBounds(markersBounds))
            } else {
                map.setZoom(16)
                map.setCenter(markersBounds.getCenter())
            }
        },
        init () {
            this
                .attach()
                .then(() => {
                    console.log('attached')
                    this.initializeContact()
                })
        },
        attach () {
            // eslint-disable-next-line one-var
            let attached = false,
                loaded = false,
                error = false,
                stack = []

            return new Promise((resolve, reject) => {
                if (!attached) {
                    const script = document.createElement('script')
                    script.setAttribute('src', 'http://maps.google.com/maps/api/js?key=AIzaSyBY8jh3lFncMMt8zLptTfgcGhXGOAQ60yk')
                    script.setAttribute('async', '')
                    this.$refs.containerMap.appendChild(script)

                    script.onload = () => {
                        resolve()
                        loaded = true
                        stack.forEach(cb => {
                            cb[0]()
                        })
                        stack = []
                    }
                    script.onerror = (err) => {
                        reject(err)
                        loaded = true
                        error = true
                        stack.forEach(cb => {
                            cb[1]()
                        })
                        stack = []
                    }
                    attached = true
                }

                if (error) reject(error)

                if (loaded) {
                    resolve()
                } else {
                    stack.push([resolve, reject])
                }
            })
        }
    }
}
</script>
<style lang="scss" src="./index.scss"></style>
