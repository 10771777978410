<template>
    <div
        class="anchor-menu"
        v-if="data.length"
        ref="anchor-menu"
    >
        <div class="anchor-menu__container">
            <ul class="anchor-menu__list" ref="list">
                <li
                    v-for="(item, index) in data"
                    :key="item.id"
                    :class="[
                        'anchor-menu__item',
                        { 'is-active': item.id === activeItem }
                    ]"
                    @click="onClick(item.id)"
                >
                    <span>
                        <template v-if="showCounter">{{ index + 1 }}.&nbsp;</template><span v-html="item.name"></span>
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Velocity from 'velocity-animate'

export default {
    name: 'anchor-menu',
    props: {
        data: {
            type: Array,
            default: () => []
        },
        targets: {
            type: String,
            default: 'js-anchor-container'
        },
        showCounter: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            activeItem: null
        }
    },
    methods: {
        onClick (id) {
            const anchorContainer = document.querySelector(`[data-anchor="${id}"]`)
            const anchorMenu = this.$refs['anchor-menu']

            if (!(anchorContainer && anchorMenu)) return

            let offset = anchorContainer.offsetTop - 32
            Velocity(document.body, 'scroll', {
                offset: offset
            })
        },
        onScroll () {
            this.checkActiveItem()
        },
        checkActiveItem () {
            const targets = Array.from(document.querySelectorAll(`.${this.targets}`))

            for (let i = 0; i < targets.length; i++) {
                const elm = targets[i]
                const offset = 160
                const minHeight = 100
                const curAnchor = elm.getAttribute('data-anchor')

                if (elm.getBoundingClientRect().height < minHeight) {
                    if (elm.getBoundingClientRect().top > offset && elm.getBoundingClientRect().top < elm.getBoundingClientRect().height + offset) {
                        this.setActiveItem(curAnchor)
                    }
                } else {
                    if (elm.getBoundingClientRect().top < offset + minHeight / 2 && elm.getBoundingClientRect().bottom > offset) {
                        this.setActiveItem(curAnchor)
                    }
                }
            }
        },
        setActiveItem (item) {
            this.activeItem = item
        }
    },
    mounted () {
        if (this.data && this.data.length) this.activeItem = this.data[0].id
        window.addEventListener('scroll', this.onScroll)
        // Костыль, так как не работает на страницах якорные ссылки с внешних страниц, т.е. страница не скролилась, высталена задержка для прогрузки картинок иначе скрипт работает некорректно
        setTimeout(() => {
            const hash = window.location.hash
            console.log(hash)
            if (hash) {
                const replacedHash = hash.replace(/^#/g, '')
                console.log(replacedHash)
                this.onClick(replacedHash)
            }
        }, 500)
    },
    created () {
        history.scrollRestoration = 'manual'
    },
    beforeDestoroy () {
        window.removeEventListener('scroll', this.onScroll)
    }
}
</script>

<style lang="scss">
.anchor-menu {
    $parent: &;
    position: sticky;
    top: $token-spacers-xs;

    &__item {
        font-size: 15px;
        line-height: 1.2;
        font-weight: 400;
        font-family: 'Fact';
        color: $token-colors-grey-dark;
        display: block;
        position: relative;
        padding-bottom: 48px;
        cursor: pointer;

        &:before {
            content: '';
            position: absolute;
            bottom: 20px;
            height: 10px;
            width: 12px;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxMiAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMC44MjYyIDUuMDA5NThMNi4wNTMyNiA5Ljc4MjU1QzUuNzYwMzYgMTAuMDc1NCA1LjI4NTQ5IDEwLjA3NTQgNC45OTI2IDkuNzgyNTVMMC4yMTk2MjYgNS4wMDk1OEMtMC4wNzMyNjcyIDQuNzE2NjkgLTAuMDczMjY3MiA0LjI0MTgyIDAuMjE5NjI2IDMuOTQ4OTJDMC41MTI1MiAzLjY1NjAzIDAuOTg3MzkzIDMuNjU2MDMgMS4yODAyOSAzLjk0ODkyTDQuNzcyOTMgNy40NDE1Nkw0Ljc3MjkzIDAuMDAxMDk4NUw2LjI3MjkzIDAuMDAxMDk4NDJMNi4yNzI5MyA3LjQ0MTU2TDkuNzY1NTcgMy45NDg5MkMxMC4wNTg1IDMuNjU2MDMgMTAuNTMzMyAzLjY1NjAzIDEwLjgyNjIgMy45NDg5MkMxMS4xMTkxIDQuMjQxODIgMTEuMTE5MSA0LjcxNjY5IDEwLjgyNjIgNS4wMDk1OFoiIGZpbGw9IiNDRENBQzgiLz4KPC9zdmc+Cg==);
        }

    &:hover,
    &.is-active {
        color: $token-colors-kaviar-black;

        &:before {
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMiAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMC44MjYyIDUuMDA4NDlMNi4wNTMyNiA5Ljc4MTQ2QzUuNzYwMzYgMTAuMDc0MyA1LjI4NTQ5IDEwLjA3NDMgNC45OTI2IDkuNzgxNDZMMC4yMTk2MjYgNS4wMDg0OUMtMC4wNzMyNjcyIDQuNzE1NTkgLTAuMDczMjY3MiA0LjI0MDcyIDAuMjE5NjI2IDMuOTQ3ODNDMC41MTI1MiAzLjY1NDkzIDAuOTg3MzkzIDMuNjU0OTMgMS4yODAyOSAzLjk0NzgzTDQuNzcyOTMgNy40NDA0N0w0Ljc3MjkzIC0xLjI5MzVlLTA3TDYuMjcyOTMgLTIuMDg2MzNlLTA3TDYuMjcyOTMgNy40NDA0N0w5Ljc2NTU3IDMuOTQ3ODNDMTAuMDU4NSAzLjY1NDkzIDEwLjUzMzMgMy42NTQ5MyAxMC44MjYyIDMuOTQ3ODJDMTEuMTE5MSA0LjI0MDcyIDExLjExOTEgNC43MTU1OSAxMC44MjYyIDUuMDA4NDlaIiBmaWxsPSIjQzRBRjc3Ii8+Cjwvc3ZnPgo=);
        }
      }
   }
}
</style>
