<template>
    <div class="date-block">
        <span class="date-block__month">
            {{ date | momentFilter('MMMM') }}
        </span>
        <span class="date-block__wrapper">
            <span class="date-block__date">
                {{ date | momentFilter('D') }}
            </span>
            <span :class="[
                'date-block__year',
                { 'date-block__year--hidden' : !year }
            ]">
                /{{ date | momentFilter('YYYY') }}
            </span>
        </span>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: 'date-block',
    props: {
        date: [String, Number],
        lang: {
            type: String,
            default: 'ru'
        },
        year: {
            type: Boolean,
            default: true
        }
    },
    created () {
        moment.locale(this.lang)
    }
}
</script>

<style lang="scss">
.date-block {
    color: $token-colors-kaviar-black;
    font-size: 15px;

    &__wrapper {
        display: flex;
        align-items: flex-end;
    }

    &__month {
        font-size: 1em;
        line-height: 1.6;
        text-transform: capitalize;
    }

    &__date {
        font-size: 2.1em;
        line-height: 1;
    }

    &__year {
        font-size: 1em;
        line-height: 1.6;

        &--hidden {
            display: none;
        }
    }

    @include breakpoint (v-tablet) {
        display: flex;
        align-items: flex-end;

        &__month {
            margin-right: 0.45em;
        }

        &__date {
            font-size: 1.6em;
            line-height: 1.15;
        }
    }

}
</style>
