/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'external-small': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.215 3.22a.75.75 0 00-1.06 1.06l2.987 2.988H6.75A4.75 4.75 0 002 12.018v.268a.75.75 0 001.5 0v-.268a3.25 3.25 0 013.25-3.25h4.392l-2.987 2.988a.75.75 0 001.06 1.06l4.268-4.268a.75.75 0 000-1.06L9.215 3.22z" _fill="#1D1D1B"/>'
  }
})
