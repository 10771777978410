/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'email': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.25 6c0-.966.784-1.75 1.75-1.75h16c.966 0 1.75.784 1.75 1.75v12A1.75 1.75 0 0120 19.75H4A1.75 1.75 0 012.25 18V6zm2.56-.25l6.306 6.306a1.25 1.25 0 001.768 0l6.305-6.306H4.811zm15.44 1.06l-6.306 6.306a2.75 2.75 0 01-3.889 0L3.75 6.811V18c0 .138.112.25.25.25h16a.25.25 0 00.25-.25V6.81z" _fill="#1D1D1B"/>'
  }
})
