/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'print': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.25 3.25h11.5v4H19A2.75 2.75 0 0121.75 10v7.75h-4v4H6.25v-4h-4V10A2.75 2.75 0 015 7.25h1.25v-4zm1.5 4h8.5v-2.5h-8.5v2.5zm-1.5 9v-2h11.5v2h2.5V10c0-.69-.56-1.25-1.25-1.25H5c-.69 0-1.25.56-1.25 1.25v6.25h2.5zM5.75 12a.75.75 0 01.75-.75H7a.75.75 0 010 1.5h-.5a.75.75 0 01-.75-.75zm2 3.75v4.5h8.5v-4.5h-8.5z" _fill="#1D1D1B"/>'
  }
})
